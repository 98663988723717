
/**
 * Normalization
 * Normalization is local value / global value = number between 0 and 1.
 * Then multiply number between 0 and 1 to 100 to get a 0-100 value
 * @module store/utils/coordinates
 */


const MAX_OUT = 100
const CANVAS_WIDTH = 480
const CANVAS_HEIGHT = 360
const HALF = 2
const map = require('./map.js')

function getTargetAtClientPosition (clientX, clientY, vm) {
  const canvasBounds = vm.runtime.renderer.canvas.getBoundingClientRect()
  const x = clientX - canvasBounds.left
  const y = clientY - canvasBounds.top
  const drawableId = vm.renderer.pick(x, y, false, false)
  const targetId = vm.getTargetIdForDrawableId(drawableId)
  const target = vm.runtime.getTargetById(targetId)
  return target
}

function clientPointToGamescreenPoint (point) {
  const gamescreen = document.querySelector('.gamescreen')
  if (!gamescreen){
    return{
      x:0,
      y:0,
      normalized:{
        x:0,
        y:0
      }
    }
  }
  const gamescreenBounds = gamescreen.getBoundingClientRect()
  gamescreenBounds.width = gamescreen.clientWidth
  gamescreenBounds.height = gamescreen.clientHeight
  const borderWidth = (gamescreen.offsetWidth - gamescreen.clientWidth) / 2
  const borderHeight = (gamescreen.offsetHeight - gamescreen.clientHeight) / 2
  const gamescreenPoint = {
    x: point.x - gamescreenBounds.x - borderWidth,
    y: point.y - gamescreenBounds.y - borderHeight
  }
  const normalized = {
    x: gamescreenPoint.x / gamescreenBounds.width,
    y: gamescreenPoint.y / gamescreenBounds.height
  }
  return {
    x: gamescreenPoint.x,
    y: gamescreenPoint.y,
    normalized
  }
}


function clientPointToScratchPoint (clientPoint, scratchCanvs) {
  const canvasBounds = scratchCanvs.getBoundingClientRect()
  const canvasX = clientPoint.x - canvasBounds.left
  const canvasY = clientPoint.y - canvasBounds.top
  const x = map(
    canvasX,
    0,
    canvasBounds.width,
    -CANVAS_WIDTH / 2,
    CANVAS_WIDTH / 2
  )
  const y = map(
    canvasY,
    0,
    canvasBounds.height,
    CANVAS_HEIGHT / 2,
    -CANVAS_HEIGHT / 2
  )

  return {
    x,
    y
  }
}

function getScratchPointOfMouseEvent (mouseEvent, scratchCanvas) {
  return clientPointToScratchPoint(
    {
      x: mouseEvent.clientX,
      y: mouseEvent.clientY
    },
    scratchCanvas
  )
}

function scratchPointToClientPoint (point, scratchCanvas) {
  const {clientWidth} = document.documentElement
  const {clientHeight} = document.documentElement
  const scratchNormalized = {
    x: ((CANVAS_WIDTH / HALF) + point.x) / CANVAS_WIDTH,
    y: ((CANVAS_HEIGHT / HALF) - point.y) / CANVAS_HEIGHT
  }

  const canvasBounds = scratchCanvas.getBoundingClientRect()
  const canvasPoint =
  {
    x: scratchNormalized.x * canvasBounds.width,
    y: scratchNormalized.y * canvasBounds.height
  }
  const clientPoint = {
    x: canvasPoint.x + canvasBounds.left,
    y: canvasPoint.y + canvasBounds.top
  }
  const clientPointNormalized = {
    x: clientPoint.x / clientWidth,
    y: clientPoint.y / clientHeight
  }

  return {
    x: clientPoint.x,
    y: clientPoint.y,
    normalized: {
      x: clientPointNormalized.x,
      y: clientPointNormalized.y
    }
  }
}


/**
 * @typedef {object} Bounds
 * @property {number|undefined} width
 * @property {number|undefined} height
 * @property {number|undefined} top
 * @property {number|undefined} left
 */


/**
 * Get game target bounds in coordinates normalized to 'gamescreen' element
 * @param {object} target
 * @param {HTML} canvas
 * @returns {Bounds}
 */
function getNormalizedTargetBounds (target, canvas) {
  const targetBoundsScratch = target.getBounds()
  const screenTopLeft = scratchPointToClientPoint({
    x: targetBoundsScratch.left,
    y: targetBoundsScratch.top
  }, canvas)
  const screenBottomRight = scratchPointToClientPoint({
    x: targetBoundsScratch.right,
    y: targetBoundsScratch.bottom
  }, canvas)
  const gamescreenTopLeft = clientPointToGamescreenPoint(screenTopLeft)
  const gamescreenBottomRight = clientPointToGamescreenPoint(screenBottomRight)

  const normalizedBounds = {
    left: gamescreenTopLeft.normalized.x * MAX_OUT,
    top: gamescreenTopLeft.normalized.y * MAX_OUT,
    right: gamescreenBottomRight.normalized.x * MAX_OUT,
    bottom: gamescreenBottomRight.normalized.y * MAX_OUT
  }

  normalizedBounds.width = normalizedBounds.right - normalizedBounds.left
  normalizedBounds.height = normalizedBounds.bottom - normalizedBounds.top

  return normalizedBounds
}


/**
 * Get absolute game target bounds in client coordinates
 * @param {HTML} canvas
 * @returns {Bounds}
 */
function getAbsoluteTargetBounds (target, canvas) {
  const targetBoundsScratch = target.getBounds()
  const clientTopLeft = scratchPointToClientPoint({
    x: targetBoundsScratch.left,
    y: targetBoundsScratch.top
  }, canvas)
  const clientBottomRight = scratchPointToClientPoint({
    x: targetBoundsScratch.right,
    y: targetBoundsScratch.bottom
  }, canvas)
  const bounds = {
    top: clientTopLeft.y,
    bottom: clientBottomRight.y,
    left: clientTopLeft.x,
    right: clientBottomRight.x
  }

  bounds.width = bounds.right - bounds.left
  bounds.height = bounds.bottom - bounds.top
  return bounds
}

module.exports = {
  getNormalizedTargetBounds,
  getAbsoluteTargetBounds,
  getTargetAtClientPosition,
  clientPointToScratchPoint,
  getScratchPointOfMouseEvent
}
