module.exports = {
  "id": "vine",
  "title": "vine",
  "exposedVariables": [
    {
      "spriteName": "vine",
      "variableName": "Delay",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 999,
        "step": "0.01"
      },
      "value": "2"
    },
    {
      "spriteName": "vine",
      "variableName": "Wilt Speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "1",
        "step": ".01"
      },
      "value": ".9"
    },
    {
      "spriteName": "vine",
      "variableName": "Lifespan",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 999,
        "step": ".1"
      },
      "value": "3"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/vine/vine.svg"
};