module.exports = {
  "id": "dungeon_floor",
  "title": "Dungeon Floor",
  "image": "provision/parts/dungeon_floor/dungeon_floor.svg",
  "info": [
    {
      "text": "Add a dungeon floor to your game",
      "image": "provision/parts/dungeon_floor/dungeon_floor.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": 1,
      "variableName": "floor size",
      "spriteName": "dungeon floor",
      "input": {
        "type": "slider",
        "min": 1,
        "max": 5,
        "step": 1
      }
    },
    {
      "value": true,
      "variableName": "is wall?",
      "spriteName": "dungeon floor",
      "input": {
        "type": "boolean"
      }
    }
  ],
  "codeImage": "provision/parts/dungeon_floor/dungeon_floor.png"
};