module.exports = {
  "id": "kicker",
  "color": "blue",
  "title": "kicker",
  "icon": "trampoline",
  "category": "rules",
  "exposedVariables": [
    {
      "variableName": "kick strength",
      "spriteName": "$glitch_target",
      "value": "25",
      "override": true,
      "input": {
        "type": "slider",
        "min": "0",
        "max": "100",
        "step": ".1"
      }
    }
  ],
  "info": [
    {
      "text": "When other sprites touch a kicker they get kicked away.",
      "image": "provision/glitches/kicker/kicker.gif"
    }
  ],
  "codeImage": "provision/glitches/kicker/kicker.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/kicker/kicker.svg"
};