/**
 * Button
 * @module elements/button
 */
const html = require('choo/html')

module.exports = function Button (args) {
  const {
    text = null,
    size = 'medium',
    type = 'tight',
    color = 'mediumgrey',
    pickable = false,
    selected = false,
    disabled = false,
    onClick = () => false,
    onMouseUp = () => false,
    onMouseDown = () => false,
    onMouseOver = () => false
  } = args
  const selectedClass = selected ? 'selected' : ''
  const disabledClass = disabled ? 'disabled' : ''
  const pickClass = pickable ? 'pickable' : ''
  const classString =
    `${type} ${size} ${color} ${selectedClass} ${disabledClass} ${pickClass}`
  return html`
    <button
      onclick=${onClick}
      onmousedown=${onMouseDown}
      onmouseup=${onMouseUp}
      onmouseover=${onMouseOver}
      class="${classString}"
      >
      <span class="text">${text}</span>
    </button>
  `
}
