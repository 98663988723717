module.exports = {
  "id": "break in half",
  "color": "blue",
  "title": "break in half",
  "info": [
    {
      "text": "Split into two smaller copies when touched by another sprite.",
      "image": "provision/glitches/break in half/break in half.gif"
    },
    {
      "text": "Set the DESTROYER SPRITE variable to choose which sprite should trigger the split.",
      "image": "provision/glitches/break in half/destroyer_sprite.gif"
    }
  ],
  "icon": "division",
  "category": "rules",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "destroyer sprite",
      "input": {
        "type": "sprite",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "none"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "scoring player",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "1",
            "label": "1"
          },
          {
            "value": "2",
            "label": "2"
          },
          {
            "value": "none",
            "label": "none"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "1"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "point value",
      "input": {
        "type": "slider",
        "options": [],
        "min": "-100",
        "max": "100",
        "step": "1"
      },
      "value": "1"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "times to split",
      "input": {
        "type": "slider",
        "options": [],
        "min": "1",
        "max": "5",
        "step": "1"
      },
      "value": "3"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "size change",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "1",
        "step": "0.05"
      },
      "value": "0.5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "destroy all to win?",
      "input": {
        "type": "boolean"
      },
      "value": "false"
    }

  ],
  "codeImage": "provision/glitches/balloon/balloon.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "isRemix": true
};