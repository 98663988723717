const { log } = console
const { getTargetAtClientPosition } = require('../shared_utils/coordinates.js')
module.exports = function store(state, emitter) {


  emitter.on('stop-changing-variable', () => {
    log('stop-changing-variable')
    state.changingVariable = false
  })

  emitter.on('set-costume', (target, costumeNumber) => {
    log('set-costume', target, costumeNumber)
    if (!state.changingVariable) {
      emitter.emit('push-history', 'change-costume', {targetId: target.id, costumeNumber})
      state.changingVariable = true
    }
    target.setCostume(costumeNumber)
    target.runtime.renderer.draw()
    emitter.emit('render')
  })

  emitter.on('set-graphic-effect', (effect, target, value) => {
    log('set-graphic-effect', target, effect, value)
    if (!state.changingVariable) {
      emitter.emit('push-history', 'change-graphic-effect', {targetId: target.id, effect, value})
      state.changingVariable = true
    }
    target.sprite.clones.forEach(t => {
      t.setEffect(effect, value)
    })
    target.runtime.renderer.draw()
    emitter.emit('render')
  })

  emitter.on('set-visible', (target, visible) => {
    log('set-visible', target, visible)
    if (!state.changingVariable) {
      emitter.emit('push-history', 'set-visible', {targetId: target.id, visible})
      state.changingVariable = true
    }
    if (state.isGamePlaying) {
      target.setVisible(visible)
    } else {
      if (!visible) {
        state.hiddenTargets.push({ id: target.id, ghost: target.effects.ghost })
        target.setEffect('ghost', 85)
      } else {
        const hiddenMe = state.hiddenTargets.find(t => t.id === target.id)
        if (hiddenMe) {
          target.setEffect('ghost', hiddenMe.ghost)
        } else {
          target.setEffect('ghost', 0)
        }
        state.hiddenTargets = state.hiddenTargets.filter(t => t.id !== target.id)
      }
    }
    target.runtime.renderer.draw()
    emitter.emit('render')
  })

  emitter.on('set-layer-order', (target, layerOrder) => {
    log('set-layer-order', target, layerOrder)
    if (!state.changingVariable) {
      emitter.emit('push-history', 'set-layer-order', {targetId: target.id, layerOrder})
      state.changingVariable = true
    }
    const currentLayerOrder = target.getLayerOrder()
    const delta = layerOrder - currentLayerOrder
    const amount = Math.abs(delta)
    if (delta < 0) {
      target.goBackwardLayers(amount)
    } else if (delta > 0) {
      target.goForwardLayers(amount)
    }
    target.runtime.renderer.draw()
    emitter.emit('render')
  })
}
