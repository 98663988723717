const html = require('choo/html')
const GameScreenElement = require('../elements/gamescreen.js')
const Button = require('../elements/button.js')
const InputSlider = require('../elements/inputslider.js')
const logo = require('../../static/graphics/makeyMakeyLogo.png')
module.exports = function MainMenu(state, emit) {
  const resetButton = Button(
    {
      text: 'RESET',
      onClick: () => emit('reset-game')
    }
  )
  const backToBrowswerButton = Button(
    {
      text: 'Back to Browser',
      onClick: () => emit('back-to-browser')
    }
  )
  const importButton = Button(
    {
      text: 'import project',
      onClick: () => emit('open-import-project-dialog')
    }
  )
  const fullscreenButton = Button(
    {
      text: 'Fullscreen Mode',
      onClick: () => emit('set-fullscreen-mode', true)
    }
  )

  const closeButton = Button(
    {
      text: 'Return to Game',
      onClick: () => emit('close-menu')
    }
  )
  function setVolume(value) {
    emit('set-volume', value)
  }
  const volume = parseFloat(state.masterVolume)
  const volumeSlider = InputSlider({
    label: 'Volume',
    value: volume,
    min: 0,
    max: 100,
    step: 1,
    onInput: e => setVolume(e.target.value),
    onChange: e => setVolume(e.target.value)
  })
  const exportButton = Button(
    {
      text: 'Export Project',
      onClick: () => emit('export-project')
    }
  )
  const bendButton = Button(
    {
      text: 'Bend this game',
      onClick: () => emit('set-fullscreen-mode', false)
    }
  )
  let menu
  if (state.isFullscreenOnly) {
    menu = html`
  <div class = "mainmenu">
    ${closeButton}
    ${volumeSlider}
    ${resetButton}
  </div>
  `
  } else if (state.isFullscreen) {
    menu = html`
  <div class = "mainmenu">
    ${closeButton}
    ${volumeSlider}
    ${bendButton}
    ${resetButton}
    </div>
  `
  } else {
    menu = html`
  <div class = "mainmenu">
  ${closeButton}
  ${volumeSlider}
    ${fullscreenButton}
    ${importButton}
    ${exportButton}
    ${resetButton}
    ${backToBrowswerButton}
  </div>
  `
  }
  return GameScreenElement(
    { fullscreen: state.isFullscreen, children: menu }
  )
}
