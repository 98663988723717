/* eslint max-len: ["error", { "code": 700 }] */

const html = require('choo/html')

module.exports = function Carousel (args) {
  const {
    selectedItem = 0,
    disabled,
    items = [],
    onChange = () => false
  } = args

  function NavItem (item, i) {
    let active = true
    if (disabled || i !== selectedItem) {
      active = false
    }
    return html`
      <div
        class="navigation-item ${active ? 'active' : ''}"
        onclick=${() => onChange(i)}
        ></div>
    `
  }

  return html`
    <div class="carousel">
      ${items.slice(selectedItem, selectedItem + 1)}
      <div class="navigation">
        ${items.map((item, i) => NavItem(item, i, selectedItem))}
      </div>
    </div>
  `
}
