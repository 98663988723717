/* eslint-disable max-len */
const allProjects =
  [
    require('../../../provision/projects/space rocks/space rocks.json'),
    require('../../../provision/projects/bouncey face/bouncey face.json'),
    require('../../../provision/projects/dungeon rescue/dungeon Rescue.json'),
    require('../../../provision/projects/fish chomp/fish chomp.json'),
    require('../../../provision/projects/pinball/pinball.json'),
    require('../../../provision/projects/tomato cross/tomato cross.json'),
    require('../../../provision/projects/moon lander/moon lander.json'),
    require('../../../provision/projects/runner/runner.json'),
    require('../../../provision/projects/soccer/soccer.json'),
    require('../../../provision/projects/volleyball/volleyball.json'),
    require('../../../provision/projects/bomb defuser/bomb defuser.json'),
    require('../../../provision/projects/blank project/blank project.json'),
    require('../../../provision/projects/glitch_info_template/glitch_info_template.json'),

  ]
let availableProjects = allProjects
const projectsToRemove = [
  'tomato cross',
  'fish chomp'
]
let params = new URLSearchParams(window.location.search);
const filtered = params.get('filtered')
  if (!(filtered === 'false')){
  availableProjects = availableProjects.filter(p => !projectsToRemove.includes(p.id))
}
module.exports = availableProjects
