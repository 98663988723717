const JSZip = require('jszip')
const { Thread, StackFrame, Variable, Target } = require('./scratch/object-prototypes.js')

function getNewSceneId(results, projectScenes) {
  let id = 'undefined'
  if (results.snapshot) {
    id = results.snapshot.stateData.currentSceneId
  } else if (results.metadata && id === 'undefined') {
    ({ id } = results.metadata)
  } else {
    id = results
  }
  let usedIds = []
  if (projectScenes && projectScenes.length > 0) {
    usedIds = projectScenes.map(s => s.metadata.id)
  }
  while (usedIds.includes(id)) {
    id = `${id}*`
  }
  return id
}



function loadSceneFromSb3(sb3, state, emitter) {
  let sb3DataPromise
  let name
  if (typeof (sb3.arrayBuffer) === 'object') {
    name = sb3.title
    sb3DataPromise = () => Promise.resolve(sb3.arrayBuffer)
  } else {
    sb3DataPromise = sb3.arrayBuffer.bind(sb3)
    name = sb3.name
  }
  let id = getNewSceneId(name, state.project.scenes)
  sb3DataPromise().then(result => {
    const newScene = {
      sb3: result,
      metadata: {
        id,
        startingParts: [],
        startingGlitches: []
      },
      snapshot: null
    }
    state.project.scenes.push(newScene)
    emitter.emit('load-scene', state.project.scenes.length - 1)
  })
}

function loadProjectFromZip(projectZip) {
  const zip = new JSZip()
  return zip.loadAsync(projectZip)
    .then(contents => {
      return getProjectDataFromZip(contents).then(results => {
        const scenePromises = []
        results.scenes.forEach(sceneZip => {
          scenePromises.push(getSceneDataFromZip(sceneZip))
        })
        return Promise.all(scenePromises).then(scenes => {
          return Promise.resolve({
            scenes,
            metadata: results.metadata
          })
        })
      })
    })
}
function deserializeSnapshot(snapshot, vm) {
  const { vmData } = snapshot

  vmData.targets.forEach(t => {
    Object.values(t.variables).forEach(v => {
      Object.setPrototypeOf(v, Variable())
    })
  })

  const originalTargets = vmData.targets.filter(t => t.isOriginal)
  originalTargets.forEach(target => {
    Object.setPrototypeOf(target, Target())
    const matchingTarget = vm.runtime.targets.find(
      t => t.sprite.name === target.sprite
    )
    if (matchingTarget) {
      target.sprite = matchingTarget.sprite
      target.sprite.blocks = target.blocks
      target.runtime = matchingTarget.runtime
      target.blocks.runtime = vm.runtime
      Object.setPrototypeOf(
        target.blocks,
        Object.getPrototypeOf(vm.runtime.targets[0].blocks)
      )
      target.renderer = vm.renderer
      target.sprite.clones = [target]
      Object.setPrototypeOf(
        target,
        Object.getPrototypeOf(vm.runtime.targets[0])
      )
    }
  })
  const clones = vmData.targets.filter(t => !t.isOriginal)
  clones.forEach(clone => {
    Object.setPrototypeOf(clone, Target())
    const original = originalTargets.find(t => t.sprite.name === clone.sprite)
    original.sprite.clones.push(clone)
    clone.blocks = original.blocks
    clone.renderer = original.renderer
    clone.runtime = original.runtime
    clone.sprite = original.sprite
    Object.setPrototypeOf(clone, Object.getPrototypeOf(vm.runtime.targets[0]))
  })
  vmData.executableTargets = vmData.executableTargets.map(
    targetId => vmData.targets.find(t => t.id === targetId)
  )
  //  vmData.threads = vmData.threads.filter(thread => thread.target !== undefined)
  vmData.threads.forEach(thread => {
    thread.target = vmData.targets.find(t => t.id === thread.target)
    thread.blockContainer = thread.target.blocks
    Object.setPrototypeOf(thread, Thread())

    thread.stackFrames.forEach(stackFrame => {
      Object.setPrototypeOf(
        stackFrame,
        StackFrame())

      if (stackFrame.executionContext && stackFrame.executionContext.timer) {
        Object.setPrototypeOf(
          stackFrame.executionContext.timer,
          Object.getPrototypeOf(vm.runtime.ioDevices.clock._projectTimer)
        )
        // TODO: should this work?
        stackFrame.executionContext.timer.nowObj =
          vm.runtime.ioDevices.clock._projectTimer.nowObj
      }
    })
  })
  return {
    stateData: { ...snapshot.stateData },
    vmData
  }
}

function serializeTarget(t) {
  if (t.runtime === null) {
    //target is already serialized
    return t
  }
  const targetSerialized = { ...t }
  if (t.isOriginal) {
    targetSerialized.blocks = { ...t.blocks }
    targetSerialized.blocks.runtime = null
  } else {
    targetSerialized.blocks = {}
  }

  targetSerialized.renderer = null
  targetSerialized.runtime = null
  targetSerialized.sprite = t.sprite.name
  return targetSerialized

}
function serializeSnapshot(snapshot, vm) {
  let snapshotSerialized = { ...snapshot }
  snapshotSerialized.vmData = { ...snapshot.vmData }
  snapshotSerialized.vmData.targets = { ...snapshot.vmData.targets }
  snapshotSerialized.vmData.targets = { ...snapshot.vmData.threads }

  if (snapshotSerialized.vmData.targets[0] && snapshotSerialized.vmData.targets[0].runtime === null) {
    return snapshotSerialized
  }
  snapshotSerialized.vmData.executableTargets = []
  snapshot.vmData.executableTargets.forEach(t => {
    if (typeof (t) === 'string') {
      snapshotSerialized.vmData.executableTargets.push(t)
    }
    else {
      snapshotSerialized.vmData.executableTargets.push(t.id)
    }
  })
  snapshotSerialized.vmData.targets = snapshot.vmData.targets.map(t => serializeTarget(t))
  snapshotSerialized.vmData.threads = snapshot.vmData.threads.map(t => {
    const threadSerialized = { ...t }
    if (typeof (t.target) === "string") {
      return threadSerialized
    }
    threadSerialized.blockContainer = null
    threadSerialized.target = t.target.id
    const stackFramesSerialized = []
    threadSerialized.stackFrames.forEach(stackFrame => {
      const frameSerialized = { ...stackFrame }
      if (frameSerialized.executionContext) {
        frameSerialized.executionContext =
          { ...stackFrame.executionContext }
        if (frameSerialized.executionContext.startedThreads) {
          const startedThreadsFixed = []
          frameSerialized.executionContext.startedThreads.forEach(
            threadToFix => {
              const fixed = { ...threadToFix }
              fixed.blockContainer = null
              fixed.target = threadToFix.target.id
              startedThreadsFixed.push(fixed)
            }
          )
          frameSerialized.executionContext.startedThreads = startedThreadsFixed
        }
      }
      stackFramesSerialized.push(frameSerialized)
    })
    threadSerialized.stackFrames = stackFramesSerialized
    return threadSerialized
  })
  return snapshotSerialized
}
function createSceneZip(scene, snapshot, vm) {
  const zip = new JSZip()
  if (snapshot) {
    const snapshotSerialized = serializeSnapshot(snapshot, vm)
    zip.file(`snapshot.json`, JSON.stringify(snapshotSerialized))
  } else if (scene.snapshot) {
    zip.file(`metadata.json`, JSON.stringify(scene.snapshot))
  } else if (scene.metadata) {
    zip.file(`metadata.json`, JSON.stringify(scene.metadata))
  }
  if (scene.sb3) {
    zip.file(`${scene.metadata.id}.sb3`, scene.sb3)
  }
  return zip.generateAsync({ type: 'blob' }).then(
    blob => ({ blob, sceneId: scene.metadata.id })
  )
}
function getProjectDataFromZip(zip) {
  const promises = []
  const sceneNames = []
  Object.values(zip.files).forEach(file => {
    if (file.name === 'metadata.json') {
      promises[0] = file.async('string')
    } else if (file.name.split('.')[1] === 'gbl') {
      promises.push(file.async('arraybuffer'))
      sceneNames.push(file.name)
    }
  })
  return Promise.all(promises).then(results => {
    const sceneData = results.slice(1)
    const scenes = []
    const metadata = JSON.parse(results[0])
    sceneData.forEach((scene, index) => {
      const newScene = {
        data: scene,
        name: sceneNames[index]
      }
      scenes.push(newScene)
    })
    return {
      metadata,
      scenes
    }
  })
}

function getSceneDataFromZip(zipFile) {
  const promises = []
  const SNAPSHOT = 0
  const SB3 = 1
  const METADATA = 2
  const zip = new JSZip()
  return zip.loadAsync(zipFile.data).then(results => {
    Object.values(results.files).forEach(file => {
      if (file.name === 'snapshot.json') {
        promises[SNAPSHOT] = file.async('string')
      } else if (file.name === 'metadata.json') {
        promises[METADATA] = file.async('string')
      } else if (file.name.split('.')[1] === 'sb3') {
        promises[SB3] = file.async('arraybuffer')
      }
    })
    return Promise.all(promises).then(results => {
      const sb3 = results[SB3]
      let metadata = results[METADATA]
      let snapshot = results[SNAPSHOT]
      if (snapshot) {
        snapshot = JSON.parse(snapshot)
      }
      if (metadata) {
        metadata = JSON.parse(metadata)
      } else {
        if (snapshot) {
          metadata = { id: snapshot.stateData.currentSceneId }
        }
      }
      return {
        sb3,
        snapshot,
        metadata
      }
    })
  })
}
module.exports = {
  createSceneZip,
  loadSceneFromSb3,
  deserializeSnapshot,
  loadProjectFromZip,
  getProjectDataFromZip,
  getSceneDataFromZip,
  getNewSceneId
}