const html = require('choo/html')
const Button = require('../elements/button.js')
const InputText = require('../elements/inputtext.js')

module.exports = function InventoryMenu (state, emit) {
  const { selectedInventoryCategory } = state
  const btns = [
    Button({
      color: selectedInventoryCategory === 'glitch' ? 'lightgrey' : 'darkgrey',
      text: 'GLITCHES',
      size: 'big',
      type: 'tight',
      onClick: () => emit('select-inventory-category', 'glitch')
    }),
    Button({
      color: selectedInventoryCategory === 'part' ? 'lightgrey' : 'darkgrey',
      text: 'PARTS',
      size: 'big',
      type: 'tight',
      onClick: () => emit('select-inventory-category', 'part')
    }),
    Button({
      color: selectedInventoryCategory === 'packs' ? 'lightgrey' : 'darkgrey',
      text: 'PACKS',
      size: 'big',
      type: 'tight',
      onClick: () => emit('select-inventory-category', 'packs')
    })
  ]
  const search = InputText({
    placeholder: 'SEARCH',
    value: state.inventoryFilter,
    onChange: e => emit('change-inventory-filter', e.target.value),
    onKeyUp: e => emit('change-inventory-filter', e.target.value)
  })
  return html`
    <div class="grid-8 inventory-header">
      <div class="column-5 categories">${btns}</div>
      <div class="column-3 search">${search}</div>
    </div>
  `
}
