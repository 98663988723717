module.exports = {
  "id": "go forward",
  "color": "green",
  "title": "go forward",
  "icon": "forward",
  "category": "movement",
  "info": [
    {
      "text": "Move in the direction the sprite is facing",
      "image": "provision/glitches/go forward/go forward.gif"
    }
  ],
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "movement speed",
      "input": {
        "type": "slider",
        "min": -10,
        "max": 10,
        "step": 0.1
      },
      "value": 5
    }
  ],
  "codeImage": "provision/glitches/focus/focus.png",
  "code": null,
  "zip": null,
  "sb3": null
};