const FileSaver = require('file-saver')
const JSZip = require('jszip')
const { log } = console
const { addPartToVM } = require("./utils/add-part")
const uuid = require('./utils/uuid.js')
const { loadSnapshot } = require('./utils/load-snapshot.js')
const {getNewSceneId,loadSceneFromSb3, deserializeSnapshot, createSceneZip} = require('./utils/import-export-helpers.js')


function getSceneDataFromZip(zip) {
  const promises = []
  const SNAPSHOT = 0
  const SB3 = 1
  const METADATA = 2

  Object.values(zip.files).forEach(file => {
    if (file.name === 'snapshot.json') {
      promises[SNAPSHOT] = file.async('string')
    } else if (file.name === 'metadata.json') {
      promises[METADATA] = file.async('string')
    } else if (file.name.split('.')[1] === 'sb3') {
      promises[SB3] = file.async('arraybuffer')
    }
  })
  return Promise.all(promises).then(results => ({
    sb3: results[SB3],
    snapshot: results[SNAPSHOT],
    metadata: results[METADATA]
  }))
}





function parseSceneFromZip(zipBlob, state) {
  const zip = new JSZip()
  let arrayBuffer = null
  if (zipBlob.arrayBuffer) {
    arrayBuffer = zipBlob.arrayBuffer()
  } else {
    arrayBuffer = zipBlob
  }
  return zip.loadAsync(arrayBuffer)
    .then(contents => {
      return getSceneDataFromZip(contents)
    })
    .then(results => {
      if (results.snapshot) {
        results.snapshot = JSON.parse(results.snapshot)
      }
      if (results.metadata) {
        results.metadata = JSON.parse(results.metadata)
      } else {
        results.metadata = {}
      }
      results.metadata.id = getNewSceneId(results, state.project.scenes)
      return results
    })
}



function loadSceneFromZip(zipBlob, state, emitter) {
  return parseSceneFromZip(zipBlob, state, emitter)
    .then(sceneToLoad => {
      if (!sceneToLoad.snapshot){
        return Promise.resolve(sceneToLoad)
      }
      return state.vm.loadProject(sceneToLoad.sb3).then(() => {
        const addPartPromises = []
        sceneToLoad.snapshot.stateData.addedParts.forEach(addedPartEntry => {
          const partToAdd = state.availableParts.find(part => part.id === addedPartEntry.partId)
          if (partToAdd) {
            addPartPromises.push(addPartToVM(partToAdd, state.vm, { name: addedPartEntry.spriteName }))
          }
        })
        return Promise.all(addPartPromises).then(addedParts => {
          const snapshotDeserialized = deserializeSnapshot(sceneToLoad.snapshot, state.vm)
          sceneToLoad.snapshotDeserialized = snapshotDeserialized
          return sceneToLoad
        })
      })
    })
    .catch(err => log(err))
}



module.exports = function store(state, emitter) {
  emitter.on('export-current-scene', () => {
    emitter.emit('pause')
    let snapshot
    if (state.currentHistoryItem === null) {
      emitter.emit('push-history', 'export-current-scene')
      snapshot = state.getHistory()[state.getHistory().length - 1].snapshot
    } else {
      snapshot = state.currentHistoryItem.snapshot
    }
    createSceneZip(state.currentScene, snapshot, state.vm).then(r => {
      FileSaver.saveAs(
        r.blob,
        `${state.project.id}_${state.currentScene.metadata.id}.gbl`
      )
      emitter.emit('render')
    })
  })

  emitter.on('open-import-scene-dialog', () => {
    log('open-import-scene-dialog')
    emitter.emit('pause')
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.sb3,.gbl' // , .sbl
    input.onchange = () => {
      const [file] = input.files
      const { name } = file
      const nameSplit = name.split('.')
      const extension = nameSplit[nameSplit.length - 1].toLowerCase()
      if (extension === 'gbl') {
        loadSceneFromZip(input.files[0], state, emitter).then(results => {
          if (results) {
            if (results.snapshot){
            state.project.scenes.push(results)
            state.currentScene = results
              loadSnapshot(results.snapshotDeserialized, state)
            emitter.emit('render')
          } else {
            state.project.scenes.push(results)
            emitter.emit('load-scene', state.project.scenes.length-1)
          }

          }
        })
      } else if (extension === 'sb3') {
        loadSceneFromSb3(input.files[0], state, emitter)
      } else {
        // eslint-disable-next-line no-alert
        alert('We can only load .sb3, gbl files')
      }
    }
    input.click()
  })

}
