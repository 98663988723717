
module.exports = function store(state, emitter) {
  emitter.on('set-fullscreen-mode', isFullscreen => {
    state.isFullscreen = isFullscreen
    state.gameOverMinimized = false
    state.levelCompleteMinimized = false
    emitter.emit('deselect-all')
    state.isMenuOpen = false
    emitter.emit('render')
  })
}
