const html = require('choo/html')
const Display = require('./elements/display.js')

module.exports = function BrowserView(state, emit) {
  emit('browser-loaded')
  let projects = state.availableProjects
  if (state.query.category) {
    projects = projects.filter(p => p.category === state.query.category)
  }
  const projectDisplays = projects.map(p => Display({
    image: p.thumbnail,
    text: p.title,
    size: 'huge',
    onClick: () => emit('pushState', `/bend/${window.location.search}#${p.id}`)
  }))
  return html`<div id="app">
  <div id="project-display-wrapper">
    ${projectDisplays}
  </div>
  </div>`
}
