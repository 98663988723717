module.exports = {
  "id": "finish line",
  "color": "blue",
  "title": "finish line",
  "icon": "finish-line",
  "category": "rules",
  "description": "Take the player to the next level.",
  "exposedVariables": [
    {
      "variableName": "player sprite",
      "spriteName": "$glitch_target",
      "value": 1,
      "input": {
        "type": "sprite",
        "max": 5,
        "step": 0.1,
        "min": "0.1"
      }
    }
  ],
  "info": [
    {
      "text": "When the player sprite touches the finish line, load the next level.",
      "image": "provision/glitches/finish line/finish line.gif"
    }
  ],
  "codeImage": "provision/glitches/finish line/finish line.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/finish line/finish line.svg"
};