const GameScreen = require('./gamescreen.js')
const Inventory = require('./inventory.js')
const MainMenu = require('./mainmenu.js')
module.exports = function Screen (state, emit) {
  if (state.isMenuOpen) {
    return MainMenu(state, emit)
  }

  if (state.isInventoryOpen) {
    return Inventory(state, emit)
  }

  return GameScreen(state, emit)
}
