module.exports = {
  "id": "bridge",
  "title": "bridge",
  "image": "provision/parts/bridge/bridge.svg",
  "info": [
    {
      "text": "Add a bridge to your game",
      "image": "provision/parts/bridge/bridge.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": 1,
      "variableName": "costume #",
      "spriteName": "bridge",
      "input": {
        "type": "slider",
        "min": 1,
        "max": 4,
        "step": 1
      }
    }
  ],
  "codeImage": "provision/parts/bridge/bridge.png"
};