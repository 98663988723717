/**
 * Icon
 * @module elements/icon
 */
const html = require('choo/html')

module.exports = function Icon (args) {
  const { iconName } = args
  return html`
    <i class="icon icon-${iconName}"></i>
  `
}
