module.exports = 
{
  "id": "bouncey face",
  "title": "bouncey face",
  "thumbnail": "/provision/projects/bouncey face/bouncey face.png",
  "category": "games",
  "toolbar": {
    "glitches": [],
    "parts": []
  },
  "exposedVariables": [
    {
      "spriteName": "paddle",
      "variableName": "left key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "paddle",
      "variableName": "right key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    }
  ],
  "project_file": "bouncey face.gbp"
};