module.exports = {
  "id": "inflate",
  "color": "pink",
  "title": "inflate",
  "info": [
    {
      "text": "Make a sprite inflatable.",
      "image": "provision/glitches/inflate/inflate.gif"
    }
  ],
  "icon": "balloon",
  "category": "powers",
  "exposedVariables": [
    {
      "value": "30",
      "variableName": "inflate amount",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": 0,
        "max": "100",
        "step": "1"
      }
    },
    {
      "value": "space",
      "variableName": "inflate key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "launch strength",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "50",
        "step": "1"
      },
      "value": "15"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "time to inflate",
      "input": {
        "type": "slider",
        "options": [],
        "min": "1",
        "max": "50",
        "step": "1"
      },
      "value": "5"
    }
  ],
  "codeImage": "provision/glitches/inflate/inflate.png",
  "code": null,
  "zip": null,
  "sb3": null
};