const html = require('choo/html')
const PanelElement = require('../elements/panel.js')
const CarouselElement = require('../elements/carousel.js')
const CodePanel = require('../components/codepanel.js')
module.exports = function GlitchContext (state, emit) {
  const glitch = state.availableGlitches.find(
    g => g.id === state.selectedGlitchId
  )
  return [
    PanelElement(
      {
        color: glitch.color,
        children: CarouselElement({
          selectedItem: state.selectedInfoPage,
          items: glitch.info.map(info => html`
            <div class="info">
              <img src="/${info.image}" />
              <p>${info.text}</p>
            </div>
          `),
          onChange: index => emit('select-info-page', index)
        })
      }
    ),
    CodePanel(state, emit)
  ]
}
