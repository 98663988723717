module.exports = {
  "id": "space rock",
  "title": "space rock",
  "exposedVariables": [],
  "info": [
    {
      "text": "Add a space rock to your game",
      "image": "provision/parts/space rock/space rock.gif"
    }
  ],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/space rock/space rock.svg"
};