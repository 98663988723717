

const HoverSprite = require('../elements/highlightsprite.js')
const { getNormalizedTargetBounds} = require('../../shared_utils/coordinates.js')

module.exports = function SpriteHighlight (state) {
  let targetsToHighlight = []

  if (state.hoveringTargetId) {
    const hoverTarget = state.vm.runtime.targets.find(
      t => t.id === state.hoveringTargetId
    )
    if (!hoverTarget) {
      return null
    }
    targetsToHighlight = hoverTarget.sprite.clones
  } else if (state.selectedTargetId) {
    const selectedTarget = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    targetsToHighlight = selectedTarget.sprite.clones
      .filter(clone => clone.id !== state.selectedTargetId)
  }

  return targetsToHighlight.map(clone => {
    const bounds = getNormalizedTargetBounds(clone, state.canvas)
    const { width, height, top, left } = bounds
    return HoverSprite({
      width,
      height,
      top,
      left,
      highlight: true
    })
  })
}


