module.exports = {
  "id": "visibility",
  "title": "visibility",
  "category": "edit",
  "color": "purple",
  "info": [
    {
      "text": "Choose whether sprite is in the foreground or background.",
      "image": "provision/glitches/visibility/visibility.gif"
    }
  ],
  "icon": "stack",
  "exposedVariables": [
 ],
  "codeImage": "provision/glitches/visibility/visibility.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/visibility/visibility.svg"
};