const {isBlockInGlitch} = require('./glitch-helpers.js')
function deleteNonGlitchBlocks (
  target,
  glitchApplications
) {
  let blocksArray = Object.values(target.blocks._blocks)
  blocksArray = blocksArray.filter(
    b => isBlockInGlitch(b.id, glitchApplications) === false
  )
  const myThreads = target.runtime.threads.filter(
    t => t.blockContainer === target.blocks
  )
  blocksArray.forEach(block => {
    const threadsToStop = myThreads.filter(t => t.topBlock === block.id)
    // eslint-disable-next-line no-underscore-dangle
    threadsToStop.forEach(t => target.runtime._stopThread(t))
    target.blocks.deleteBlock(block.id)
  })
}

function copyNonGlitchBlocks (
  source,
  destination,
  destinationVM,
  glitchApplications
) {
  const blockIds = []
  let blocks
  if (source.blocks._blocks) {
    blocks = JSON.parse(JSON.stringify(source.blocks._blocks))
  } else {
    const blocksCopy = JSON.parse(JSON.stringify(source.blocks))
    blocks = destinationVM.sb3.deserializeBlocks(blocksCopy)
  }
  let blocksArray = Object.values(blocks)
  blocksArray = blocksArray.filter(
    b => isBlockInGlitch(b.id, glitchApplications) === false
  )
  blocksArray.forEach(block => {
    destination.blocks.createBlock(block)
    blockIds.push(block.id)
    if (block.opcode === 'event_whenflagclicked') {
      destination.sprite.clones.forEach(c => {
        c.runtime._pushThread(block.id, c)
      })
    }
  })
  return blockIds
}

module.exports = {copyNonGlitchBlocks, deleteNonGlitchBlocks}
