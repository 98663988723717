const html = require('choo/html')

module.exports = function GameScreen (args) {
  const {
    children = null,
    onClick = null,
    onMouseUp = null,
    onMouseDown = null,
    onMouseOver = null,
    onMouseMove = null,
    onMouseLeave = null,
    onMouseEnter = null,
    fullscreen = false
  } = args
  const fullscreenClass = fullscreen ? 'fullscreen' : ''
  return html`
    <div 
      class="gamescreen ${fullscreenClass}"
      onclick=${onClick}
      onmousedown=${onMouseDown}
      onmouseup=${onMouseUp}
      onmousemove =${onMouseMove}
      onmouseover=${onMouseOver}
      onmouseleave=${onMouseLeave}
      onmouseenter=${onMouseEnter}>
      ${children}
    </div>
  `
}
