/* eslint-disable max-lines-per-function */
const { log } = console
const STRAIGHT_ANGLE = 180
const RIGHT_ANGLE = 90
const POSITION_SNAP = 5
const ROTATION_SNAP = 15
const SIZE_SNAP = 20
const {
  getScratchPointOfMouseEvent,
  getAbsoluteTargetBounds
} = require('../shared_utils/coordinates.js')
const html = require('choo/html')
const { findTargetById } = require('../shared_utils/find-target.js')

function roundNearest(numberToRound, roundTo) {
  return Math.round(numberToRound / roundTo) * roundTo
}

function radToDeg(rad) {
  return rad * STRAIGHT_ANGLE / Math.PI
}

module.exports = function store(state, emitter) {
  emitter.on('put-target-back-in-play-area', () => {
    log('put-target-back-in-play-area')
    const target = findTargetById(state.vm, state.selectedTargetId)
    target.setVisible(true)
    state.draggingTargetOutOfPlayArea = false
    emitter.emit('cleanup-drag')
  })
  emitter.on('remove-target-from-play-area', e => {
    log('remove-target-from-play-area', e)
    state.draggingTargetOutOfPlayArea = true
    const target = findTargetById(state.vm, state.selectedTargetId)
    target.setVisible(false)
    const imgSrc = target.sprite.costumes[target.currentCostume]
      .asset.encodeDataURI()
    const el = html`
      <img 
      src = "${imgSrc}" 
      style = "transform-origin: 0% 0%; 
               transform: rotate(${target.direction - RIGHT_ANGLE}deg);"
      />`
    emitter.emit('start-drag', el, e)
  })
  emitter.on('move-selected-target', e => {
    log('move-selected-target')
    const snapToGrid = e.shiftKey
    if (state.draggingTargetOutOfPlayArea) {
      emitter.emit('put-target-back-in-play-area')
    }
    if (state.selectedTargetId) {
      const target = state.vm.runtime.targets.find(
        t => t.id === state.selectedTargetId
      )
      const dragScratchCoords =
        getScratchPointOfMouseEvent(e, state.canvas)

      let targetX = dragScratchCoords.x + state.movingSpriteOffset.x
      let targetY = dragScratchCoords.y + state.movingSpriteOffset.y
      if (snapToGrid) {
        targetX = roundNearest(targetX, POSITION_SNAP)
        targetY = roundNearest(targetY, POSITION_SNAP)
      }
      target.setXY(
        targetX,targetY
      )
      target.runtime.renderer.draw()
      emitter.emit('render')
    }
  })

  emitter.on('start-moving-target', clickEvent => {
    log('startMovingSprite')
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    if (target) {
      emitter.emit('push-history', 'move-target')

      const scratchCoordsClick =
        getScratchPointOfMouseEvent(clickEvent, state.canvas)
      if (!scratchCoordsClick) {
        scratchCoordsClick = {
          x: target.x,
          y: target.y
        }
      }
      state.movingSpriteOffset = {
        x: target.x - scratchCoordsClick.x,
        y: target.y - scratchCoordsClick.y
      }
      state.movingSelectedTarget = true
      emitter.emit('render')
    }
  })

  emitter.on('resize-selected-target', dragEvent => {
    const snapToGrid = dragEvent.shiftKey
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    const currentBounds = getAbsoluteTargetBounds(target, state.canvas)
    const start = {}

    const mouseDelta = {
      x: Math.abs(dragEvent.clientX - state.resizeTargetClickEvent.clientX),
      y: Math.abs(dragEvent.clientY - state.resizeTargetClickEvent.clientY)
    }
    let resizeAxis
    switch (state.resizingHandle) {
      case 'topRight':
        start.x = currentBounds.left + currentBounds.width
        start.y = currentBounds.top
        resizeAxis = (mouseDelta.x > mouseDelta.y) ? 'x' : 'y'
        break
      case 'topLeft':
        start.x = currentBounds.left
        start.y = currentBounds.top
        resizeAxis = (mouseDelta.x > mouseDelta.y) ? '-x' : 'y'
        break
      case 'bottomRight':
        start.x = currentBounds.left + currentBounds.width
        start.y = currentBounds.top + currentBounds.height
        resizeAxis = (mouseDelta.x > mouseDelta.y) ? 'x' : '-y'
        break
      case 'bottomLeft':
        start.x = currentBounds.left
        start.y = currentBounds.top + currentBounds.height
        resizeAxis = (mouseDelta.x > mouseDelta.y) ? '-x' : '-y'
        break
      case 'top':
        resizeAxis = 'y'
        start.x = currentBounds.left + (currentBounds.width / 2)
        start.y = currentBounds.top
        break
      case 'bottom':
        resizeAxis = '-y'
        start.x = currentBounds.left + (currentBounds.width / 2)
        start.y = currentBounds.top + currentBounds.height
        break
      case 'left':
        resizeAxis = '-x'
        start.x = currentBounds.left
        start.y = currentBounds.top + (currentBounds.height / 2)
        break
      case 'right':
        resizeAxis = 'x'
        start.x = currentBounds.left + currentBounds.width
        start.y = currentBounds.top + (currentBounds.height / 2)
        break
      default:
        break
    }

    const dif = {
      x: dragEvent.clientX - start.x,
      y: dragEvent.clientY - start.y
    }

    let scaleAmount
    switch (resizeAxis) {
      case 'x':
        scaleAmount = dif.x / currentBounds.width
        break
      case 'y':
        scaleAmount = -dif.y / currentBounds.height
        break
      case '-x':
        scaleAmount = -dif.x / currentBounds.width
        break
      case '-y':
        scaleAmount = dif.y / currentBounds.height
      default:
        break
    }
    let newSize = target.size + (target.size * scaleAmount)
    if (snapToGrid) {
      newSize = roundNearest(newSize, SIZE_SNAP)
    }
    target.setSize(newSize)
    target.runtime.renderer.draw()
    emitter.emit('render')
  })
  emitter.on('start-resizing-target', (clickEvent, clickRegion) => {
    log('start-resizing-target', clickEvent, clickRegion)
    emitter.emit(
      'push-history',
      'resize-target'
    )

    state.resizeTargetClickEvent = clickEvent
    state.resizingHandle = clickRegion
    state.resizingSelectedTarget = true
    emitter.emit('render')
  })

  emitter.on('end-resize', () => {
    state.resizingSelectedSprite = false
    emitter.emit('render')
  })

  emitter.on('rotate-selected-target', dragEvent => {
    const snapToGrid = dragEvent.shiftKey
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    const dragEventScratchCoords =
      getScratchPointOfMouseEvent(dragEvent, state.canvas)

    const dx = dragEventScratchCoords.x - target.x
    const dy = dragEventScratchCoords.y - target.y
    state.hoverRotation = -radToDeg(Math.atan2(dy, dx))
    let direction =
      state.rotationStartingDirection - radToDeg(Math.atan2(dy, dx))
    if (snapToGrid) {
      direction = roundNearest(direction, ROTATION_SNAP)
    }
    target.setDirection(direction)
    target.runtime.renderer.draw()
    emitter.emit('render')
  })

  emitter.on('start-rotating-target', () => {
    log('start-rotating-target')
    emitter.emit(
      'push-history',
      'rotate-target'
    )
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    state.rotationStartingDirection = target.direction
    state.rotatingSelectedTarget = true
    emitter.emit('render')
  })
}
