/**
 * Panel
 * @module elements/panel
 */
const html = require('choo/html')

module.exports = function Panel (args) {
  const {
    color = 'purple',
    noPadding = false,
    children,
    size = 'large'
  } = args
  let paddingClass = ''
  if (noPadding) {
    paddingClass = 'no-padding'
  }
  return html`
    <div class="panel panel-${color} panel-${size} ${paddingClass}">
      ${children}
    </div>
  `
}
