
const {
  updateAppliedGlitchesLists,
  updateSpriteList
} = require('./arcade-vm-hooks')
const uuid = require('./uuid.js')

const globals = [
  'player 1: score',
  'player 2: score',
  'player 1: lives',
  'player 2: lives',
  'timer',
  'level #'
]

function gameStep(gamebenderVM, glitchApplications) {
  gamebenderVM.runtime.targets.forEach(t => {
    if (!t.isStage) {
      const badGlobals = Object.values(t.variables).filter(v => globals.includes(v.name))
      badGlobals.forEach(v => {
        t.deleteVariable(v.id)
      })
    }
  })
  const stage = gamebenderVM.runtime.getTargetForStage()
  globals.forEach(globalName => {
    const global = Object.values(stage.variables).find(v => v.name === globalName)
    if (!global) {
      stage.lookupOrCreateVariable(uuid(), globalName)
    }
  })
  // TODO: these probably don't need to be in `gameStep`
  updateSpriteList(gamebenderVM)
  updateAppliedGlitchesLists(gamebenderVM, glitchApplications)
  // Step the machine
  gamebenderVM.runtime._step()
  return gamebenderVM
}

module.exports = gameStep
