module.exports = {
  "id": "toss",
  "title": "toss",
  "icon": "toss",
  "color": "pink",
  "description": "Toss other sprites in a random direction when touched",
  "category": "magic",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "toss distance",
      "input": {
        "type": "slider",
        "options": [],
        "min": "5",
        "max": 15,
        "step": "1"
      },
      "value": "10"
    }
  ],
  "info": [
    {
      "text": "Toss other sprites in a random direction when touched",
      "image": "provision/glitches/toss/toss.gif"
    }
  ],
  "codeImage": "provision/glitches/toss/toss.png",
  "code": null,
  "zip": null,
  "sb3": null
};