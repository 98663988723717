const { unmuteVolume } = require("./initial-state")
const { loadSnapshot } = require("./utils/load-snapshot")

const { log } = console


module.exports = function store(state, emitter) {
  emitter.on('toggle-main-menu', () => {
    log('toggle-main-menu')
    if (state.glitchEditorOpen) {
      emitter.emit('close-glitch-editor')
    }
    state.isInventoryOpen = false
    state.isMenuOpen = !state.isMenuOpen
    if (state.isMenuOpen) {
      if (!state.wasGamePlaying) {
        state.wasGamePlaying = state.isGamePlaying
      }
      emitter.emit('pause')
    } else {
      emitter.emit('close-menu')
    }
    emitter.emit('render')
  })

  emitter.on('toggle-mute', () => {
    if (state.masterVolume === 0) {
      state.masterVolume = unmuteVolume
    } else {
      state.unmuteVolume = state.masterVolume
      state.masterVolume = 0
    }
    const node = state.vm.runtime.audioEngine.getInputNode()
    const volPercent = state.masterVolume / 100
    const gain = volPercent * volPercent
    node.gain.setValueAtTime(gain, 0)
    emitter.emit('render')
  })

  emitter.on('set-volume', value => {
    log('set-volume', value)
    state.masterVolume = value
    emitter.emit('render')
  })

  emitter.on('reset-level', () => {
    log('reset-level')
    // if the game is in fullscreen mode, save any variable changes that the player has made to reapply after the reset
    const variableChanges = state.getHistory().filter(h => h.actionType === 'change-variable')
    const cachedValues = {}
    variableChanges.forEach(h => {
      if (h.metadata && h.metadata.targetId && h.metadata.variableId) {
        if (!cachedValues[h.metadata.targetId]) {
          cachedValues[h.metadata.targetId] = {}
        }
        cachedValues[h.metadata.targetId][h.metadata.variableId] = h.metadata.value
      }
    })
    emitter.emit('pause')
    state.levelComplete = false
    loadSnapshot(state.getHistory()[0].snapshot, state)
    emitter.emit('push-history', 'reset-level')
    emitter.emit('deselect-all')
    state.gameOver = false
    Object.entries(cachedValues).forEach(([targetId, variables]) => {
      Object.entries(variables).forEach(([variableId, value]) => {
        const target = state.vm.runtime.targets.find(t => t.id === targetId)
        const variable = target.variables[variableId]
        emitter.emit('set-variable', target, variable, value)
        emitter.emit('stop-changing-variable')
      })
    })
    emitter.emit('play')
    emitter.emit('render')
  })
  emitter.on('reset-game', () => {
    log('reset-game')
    // if the game is in fullscreen mode, save any variable changes that the player has made to reapply after the reset

    emitter.emit('pause')
    emitter.emit('load-scene', 0)
    emitter.emit('deselect-all')
    emitter.emit('reset-level')
    const newHistories = {}

    Object.keys(state.histories).forEach(key => {
      // skip the current scene
      if (key === state.currentSceneId && state.histories[key].length > 0) {
        newHistories[key] = state.histories[key]
      } else 
      if (state.histories[key].length > 0) {
        newHistories[key] = [state.histories[key][0]]
      } else {
        newHistories[key] = []
      }
    });
    state.histories = newHistories
    //loadSnapshot(state.getHistory()[0].snapshot, state)
   
    emitter.emit('push-history', 'reset-game')
    state.gameOver = false

    emitter.emit('play')
    emitter.emit('render')
  })

  emitter.on('back-to-browser', () => {
    log('back-to-browser')
    window.location.assign('/')
  })


  emitter.on('close-menu', () => {
    log('close-menu')
    state.isMenuOpen = false
    if (state.wasGamePlaying) {
      emitter.emit('play')
    }
    emitter.emit('render')
  })
}
