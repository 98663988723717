const html = require('choo/html')
const PanelElement = require('../elements/panel.js')
const CarouselElement = require('../elements/carousel.js')
const CodePanel = require('../components/codepanel.js')
module.exports = function PartContext (state, emit) {
  const part = state.availableParts.find(
    g => g.id === state.selectedPartId
  )
  return [
    PanelElement({
      color: 'purple',
      children: CarouselElement({
        selectedItem: 0,
        items: part.info.map(info => html`
          <div class="info">
            <img src=/${info.image} />
            <p>${info.text}</p>
          </div>
        `)
      })
    }),
    CodePanel(state, emit)
  ]
}
