const FileSaver = require('file-saver')
const JSZip = require('jszip')
const { log } = console
const { addPartToVM } = require("./utils/add-part")
const uuid = require('./utils/uuid.js')
const { Thread, StackFrame, Variable, Target } = require('./utils/scratch/object-prototypes.js')
const { getProjectDataFromZip, 
  getSceneDataFromZip, 
  createSceneZip,
   deserializeSnapshot, 
   loadProjectFromZip, 
   loadSceneFromSb3 } = require('./utils/import-export-helpers.js')

module.exports = function store(state, emitter) {




  function createProjectZipFile(project) {
    const zip = new JSZip()
    emitter.emit('pause')
    if (state.currentHistoryItem === null) {
      emitter.emit('push-history', 'export-current-scene')
    }
    const exportScenePromises = []
    state.project.scenes.forEach((scene) => {
      const history = state.histories[scene.metadata.id]
      let snapshot = scene.snapshot
      if (history && history.length > 0) {
        snapshot = history[history.length - 1].snapshot
      }
      exportScenePromises.push(createSceneZip(scene, snapshot, state.vm))
    })
    const { projectMetadata } = state
    return Promise.all(exportScenePromises).then(results => {
      const metadata = {
        id: projectMetadata.id,
        persistentVariables: projectMetadata.persistentVariables,
        thumbnail: projectMetadata.thumbnail,
        title: projectMetadata.title
      }
      const metadataString = JSON.stringify(metadata)
      zip.file(`metadata.json`, metadataString)
      results.forEach(zipFile => {
        zip.file(`${zipFile.sceneId}.gbl`, zipFile.blob)
      })
      return zip.generateAsync({ type: 'blob' })
    })
  }



  emitter.on('export-project', () => {
    log('export-project')
    createProjectZipFile(state.project).then(r => {
      FileSaver.saveAs(
        r,
        `${state.projectMetadata.id}.gbp`
      )
      emitter.emit('render')
    })
  })
  emitter.on('open-import-project-dialog', () => {
    log('open-import-project-dialog')
    emitter.emit('pause')
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.sb3,.gbp' // , .sbl
    input.onchange = () => {
      const [file] = input.files
      const { name } = file
      const nameSplit = name.split('.')
      const extension = nameSplit[nameSplit.length - 1].toLowerCase()
      if (extension === 'gbp') {
        loadProjectFromZip(input.files[0]).then(results => {
          state.histories = {}
          state.currentHistoryItem = null
          state.project = {}
          state.currentScene = null
          state.project.scenes = results.scenes
          state.projectMetadata = state.projectMetadata
          state.project.scenes.forEach(scene => {
            state.histories[scene.metadata.id] = []
          })
          emitter.emit('render')
        })
      } else if (extension === 'sb3') {
        //TODO : new project and load scene
        const sb3 = input.files[0]
        state.histories = {}
        state.projectMetadata = {
          category:"scratch-import",
          id: sb3.name,
          project_file:null,
          thumbnail:null,
          title: sb3.name
        }
        state.project = {scenes:[]}
        window.location = `#${sb3.name}`
        loadSceneFromSb3(sb3, state, emitter)
      } else {
        // eslint-disable-next-line no-alert
        alert('We can only load .sb3, .gbp files')
      }
    }
    input.click()
  })

}
