module.exports = {
  "id": "headstand",
  "color": "red",
  "title": "headstand",
  "icon": "headstand",
  "category": "magic",
  "description": "Periodically do a headstand.",
  "exposedVariables": [
    {
      "variableName": "headstand delay",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": "10",
        "value": 18,
        "min": "0",
        "step": ".1"
      },
      "value": "3"
    },
    {
      "variableName": "flip speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": 60,
        "value": 5,
        "min": "1",
        "step": ".1"
      },
      "value": "18"
    }
  ],
  "info": [
    {
      "text": "Periodically do a headstand.",
      "image": "provision/glitches/headstand/headstand.gif"
    }
  ],
  "codeImage": "provision/glitches/headstand/headstand.png",
  "code": null,
  "zip": null,
  "sb3": null
};