module.exports = {
  "id": "launchpad",
  "title": "launchpad",
  "image": "provision/parts/launchpad/launchpad.svg",
  "info": [
    {
      "text": "Add a launchpad to your game",
      "image": "provision/parts/launchpad/launchpad.gif"
    }
  ],
  "exposedVariables": [{
    "variableName": "costume",
    "value": "launchpad",
    "input": {
      "type": "option",
      "options": [
        {
          "value": "launchpad",
          "label": "launchpad"
        },
        {
          "value": "landing",
          "label": "landing"
        }
      ]
    }
  }]
};