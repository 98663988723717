function findTargetById (vm, id) {
  return vm.runtime.targets.find(t => t.id === id)
}

function findSpriteByName (vm, name) {
  const target = vm.runtime.targets.find(t => t.sprite.name === name)
  if (target) {
    return target.sprite.clones[0]
  }
  return null
}

module.exports = {findTargetById, findSpriteByName}
