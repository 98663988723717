let THREAD = null
let STACKFRAME = null
let TARGET = null
let VARIABLE = null
function initializePrototypes(vm){
  const newThread = vm.runtime._pushThread('@@@', vm.runtime.targets[0])
  THREAD = Object.getPrototypeOf(newThread)
  STACKFRAME = Object.getPrototypeOf(newThread.stackFrames[0])
  vm.runtime._stopThread(newThread)
  TARGET = Object.getPrototypeOf(vm.runtime.targets[0])
  const prototypeVariable = vm.runtime.targets[0].lookupOrCreateVariable('proto', '_variable_prototype')
  VARIABLE = Object.getPrototypeOf(prototypeVariable)
  vm.runtime.targets[0].deleteVariable(prototypeVariable.id)
}
 

function Thread(){
  return THREAD
}

function StackFrame(){
  return STACKFRAME
}
function Variable(){
  return VARIABLE
}

function Target(){
  return TARGET
}

module.exports = {
  initializePrototypes,
  Thread,
  StackFrame,
  Variable,
  Target
}