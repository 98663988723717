const { saveSnapshot } = require('./utils/save-snapshot.js')
const { loadSnapshot } = require('./utils/load-snapshot.js')
const uuid = require('./utils/uuid.js')
const { log } = console
module.exports = function store (state, emitter) {
  emitter.on('clear-future-stack', () => {
    state.currentHistoryItem = null
    state.future = []
  })

  emitter.on('push-history', (actionType, metadata) => {
    log('push-history', actionType)
    emitter.emit('clear-state')
    emitter.emit('cleanup-drag')
    emitter.emit('clear-future-stack')
    const snapshot = saveSnapshot(state)
    state.getHistory().push(
      {snapshot, 
        actionType,
        id: uuid(),
        metadata
      })
  })


  emitter.on('pop-history', () => {
    log('rewinding')
    emitter.emit('pause')
    if (state.currentHistoryItem === null) {
      emitter.emit('push-history', 'start-browsing-timeline')
      state.currentHistoryItem = state.getHistory().pop()
    }
    state.future.push(state.currentHistoryItem)
    state.currentHistoryItem = state.getHistory().pop()
    const {snapshot} = state.currentHistoryItem
    loadSnapshot(snapshot, state)
    emitter.emit('render')
  })

  emitter.on('pop-future', () => {
    log('forward')
    state.getHistory().push(state.currentHistoryItem)
    state.currentHistoryItem = state.future.pop()
    const {snapshot} = state.currentHistoryItem
    loadSnapshot(snapshot, state)
    emitter.emit('render')
  })
}
