const {log} = console

module.exports = function store (state, emitter) {
  emitter.on('glitch-editor-set-slider-param', (variable, value, param) => {
    log('glitch-editor-set-slider-param', variable, value, param)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = JSON.parse(JSON.stringify(variable))
    newVariable.input[param] = value
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })

  emitter.on('glitch-editor-hide-variable', variable => {
    log('glitch-editor-hide-variable', variable)
    const index = state.glitchEditorVariables.indexOf(variable)
    state.glitchEditorVariables.splice(index, 1)
    emitter.emit('render')
  })

  emitter.on('glitch-editor-expose-variable', () => {
    log('glitch-editor-expose-variable')
    state.glitchEditorVariables.push({
      spriteName: state.glitchEditor.vm.editingTarget.sprite.name,
      variableName: '',
      input: {
        type: 'text',
        options: [],
        min: -999,
        max: 999
      }
    })
    emitter.emit('render')
  })

  emitter.on('glitch-editor-set-option-choice', (variable, option, value) => {
    log('glitch-editor-set-option-choice', variable, option, value)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = JSON.parse(JSON.stringify(variable))
    const optionIndex = variable.input.options.indexOf(option)
    if (value === '') {
      newVariable.input.options.splice(optionIndex, 1)
    } else {
      newVariable.input.options[optionIndex].value = value
      newVariable.input.options[optionIndex].label = value
      if (newVariable.value === variable.input.options[optionIndex].value) {
        newVariable.value = value
      }
    }
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })

  emitter.on('glitch-editor-add-new-option', variable => {
    log('glitch-editor-add-new-option')
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = JSON.parse(JSON.stringify(variable))
    newVariable.input.options.push({value: '', label: ''})
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })

  emitter.on('glitch-editor-pick-variable', (variable, variableName) => {
    log('glitch-editor-pick-variable', variable, variableName)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = {...variable}
    newVariable.variableName = variableName
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })
  emitter.on('glitch-editor-pick-sprite', (variable, spriteName) => {
    log('glitch-editor-pick-variable', variable, spriteName)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = {...variable}
    newVariable.spriteName = spriteName
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })
  emitter.on('glitch-editor-set-variable-value', (variable, value) => {
    log('glitch-editor-set-variable-value', variable, value)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = {...variable}
    newVariable.value = value
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })

  emitter.on('glitch-editor-pick-type', (variable, variableType) => {
    log('glitch-editor-pick-type', variable, variableType)
    const index = state.glitchEditorVariables.indexOf(variable)
    const newVariable = {...variable}
    newVariable.input.type = variableType
    if (variableType === 'option') {
      if (!newVariable.input.options) {
        newVariable.input.options = []
      }
    }
    state.glitchEditorVariables[index] = newVariable
    emitter.emit('render')
  })
}
