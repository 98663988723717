module.exports = {
  "id": "drive",
  "color": "pink",
  "title": "drive",
  "info": [
    {
      "text": "Drive your sprite like a vehicle.",
      "image": "provision/glitches/drive/drive.gif"
    }
  ],
  "icon": "rocket",
  "category": "powers",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "thruster force",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "5",
        "step": ".01"
      },
      "value": "1"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "turn speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "30",
        "step": ".1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "direction offset",
      "input": {
        "type": "slider",
        "options": [],
        "min": "-180",
        "max": "180",
        "step": "1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "friction",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "1",
        "step": ".01"
      },
      "value": ".05"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "thrust key",
      "input": {
        "type": "key",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "up arrow"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "left key",
      "input": {
        "type": "key",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "left arrow"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "right key",
      "input": {
        "type": "key",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "right arrow"
    }
  ],
  "codeImage": "provision/glitches/drive/drive.png",
  "code": null,
  "zip": null,
  "sb3": null
};