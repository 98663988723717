module.exports = {
  "id": "wander",
  "color": "green",
  "title": "wander",
  "icon": "wander",
  "category": "movement",
  "description": "Make a sprite move around at random.",
  "exposedVariables": [
    {
      "variableName": "Wander Speed",
      "spriteName": "$glitch_target",
      "value": 1,
      "input": {
        "type": "slider",
        "max": 5,
        "step": 0.1,
        "min": "0.1"
      }
    },
    {
      "variableName": "Wander Amount",
      "spriteName": "$glitch_target",
      "value": 30,
      "input": {
        "type": "slider",
        "max": 100,
        "min": "1",
        "step": "1"
      }
    },
    {
      "variableName": "Wait Time",
      "spriteName": "$glitch_target",
      "value": 0.2,
      "input": {
        "type": "slider",
        "max": 5,
        "min": "0.1",
        "step": "0.1"
      }
    }
  ],
  "info": [
    {
      "text": "Make a sprite move around at random.",
      "image": "provision/glitches/wander/wander.gif"
    }
  ],
  "codeImage": "provision/glitches/wander/wander.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/wander/wander.svg"
};