/* eslint-disable max-len */
const allGlitches = [
  require('../../../provision/glitches/builtin_physics/builtin_physics.json'),
  require('../../../provision/glitches/kicker/kicker.json'),
  require('../../../provision/glitches/bouncify/bouncify.json'),
  require('../../../provision/glitches/walk/walk.json'),
  require('../../../provision/glitches/inflate/inflate.json'),
  require('../../../provision/glitches/break in half/break in half.json'),
  require('../../../provision/glitches/cartwheel/cartwheel.json'),
  require('../../../provision/glitches/collectible/collectible.json'),
  require('../../../provision/glitches/confetti/confetti.json'),
  require('../../../provision/glitches/cycle/cycle.json'),
  require('../../../provision/glitches/digify/digify.json'),
  require('../../../provision/glitches/dizzy/dizzy.json'),
  require('../../../provision/glitches/elevator/elevator.json'),
  require('../../../provision/glitches/fireworks/fireworks.json'),
  require('../../../provision/glitches/flippify/flippify.json'),
  require('../../../provision/glitches/frontflip/frontflip.json'),
  require('../../../provision/glitches/ghost/ghost.json'),
  require('../../../provision/glitches/glow_when_hit/glow_when_hit.json'),
  require('../../../provision/glitches/gravity/gravity.json'),
  require('../../../provision/glitches/grid_layout/grid_layout.json'),
  require('../../../provision/glitches/headstand/headstand.json'),
  require('../../../provision/glitches/helicopter/helicopter.json'),
  require('../../../provision/glitches/moonwalk/moonwalk.json'),
  require('../../../provision/glitches/patrol/patrol.json'),
  require('../../../provision/glitches/stick to sprite/stick to sprite.json'),
  require('../../../provision/glitches/jump/jump.json'),
  require('../../../provision/glitches/climb/climb.json'),
  require('../../../provision/glitches/portal/portal.json'),
  require('../../../provision/glitches/projectile/projectile.json'),
  require('../../../provision/glitches/pulse/pulse.json'),
  require('../../../provision/glitches/random_walk/random_walk.json'),
  require('../../../provision/glitches/retry/retry.json'),
  require('../../../provision/glitches/screenwrap/screenwrap.json'),
  require('../../../provision/glitches/drive/drive.json'),
  require('../../../provision/glitches/slippery/slippery.json'),
  require('../../../provision/glitches/spawn/spawn.json'),
  require('../../../provision/glitches/teleport/teleport.json'),
  require('../../../provision/glitches/tile_movement/tile_movement.json'),
  require('../../../provision/glitches/traffic/traffic.json'),
  require('../../../provision/glitches/wander/wander.json'),
  require('../../../provision/glitches/unicorn/unicorn.json'),
  require('../../../provision/glitches/visibility/visibility.json'),
  require('../../../provision/glitches/goal/goal.json'),
  require('../../../provision/glitches/grid wander/grid wander.json'),
  require('../../../provision/glitches/shield/shield.json'),
  require('../../../provision/glitches/left-right/left-right.json'),
  require('../../../provision/glitches/effects/effects.json'),
  require('../../../provision/glitches/strobe/strobe.json'),
  require('../../../provision/glitches/toss/toss.json'),
  require('../../../provision/glitches/black hole/black hole.json'),
  require('../../../provision/glitches/sticky/sticky.json'),
  require('../../../provision/glitches/musicify/musicify.json'),
  require('../../../provision/glitches/repel/repel.json'),
  require('../../../provision/glitches/time warp/time warp.json'),
  require('../../../provision/glitches/finish line/finish line.json'),
  require('../../../provision/glitches/accelerate/accelerate.json'),
  require('../../../provision/glitches/point towards/point towards.json'),
  require('../../../provision/glitches/go forward/go forward.json'),
  require('../../../provision/glitches/score/score.json'),
  require('../../../provision/glitches/rotation style/rotation style.json'),
  require('../../../provision/glitches/animate/animate.json'),
  require('../../../provision/glitches/speed up/speed up.json'),
]
const glitchesToRemove = [
  'inflate', 'cartwheel', 'confetti', 'digify', 'fireworks', 'frontflip', 'ghost',
  'headstand', 'helicopter', 'moonwalk', 'pulse', 'random_walk', 
  'slippery', 'tile_movement', 'grid wander',
  'shield', 'toss', 'musicify', 'repel', 'time warp', 'strobe'
]
let availableGlitches = allGlitches
let params = new URLSearchParams(window.location.search);
const filtered = params.get('filtered')
if (!(filtered === 'false')) {
  availableGlitches = availableGlitches.filter(g => !glitchesToRemove.includes(g.id))
}

module.exports = availableGlitches
