module.exports = {
    "id": "mud",
    "title": "mud",
    "image": "provision/parts/mud/mud.svg",
    "info": [
      {
        "text": "Add mud to your game",
        "image": "provision/parts/mud/mud.gif"
      }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/mud/mud.png"
  };