/* eslint-disable multiline-comment-style */
const { log } = console
const {
  fetchArrayBuffer,
  fetchJson,
  projectPath
} = require('./utils/fetch-and-parse.js')
const { loadProjectFromZip, loadSceneFromSb3 } = require('./utils/import-export-helpers.js')
function fetchProject(projectId) {
  const project = { id: projectId }
  const url = `/provision/projects/${projectId}/${projectId}.json`
  return fetchJson(url).then(metadata => {
    project.metadata = metadata
    if (metadata.project_file) {
      return Promise.resolve(metadata)
    }
    const fetchScenePromises = metadata.scenes.map(
      scene => fetchScene(scene, projectId)
    )
    return Promise.all(fetchScenePromises).then(results => {
      project.scenes = results.filter(r => r.sb3 && (r.metadata || r.snapshot))
      return project
    })
  })
}
function fetchScene(sceneDescription, projectId) {
  let metadataPromise = null
  let sb3Promise = null
  let snapshotPromise = null
  const fetchPromises = []
  if (sceneDescription.sb3) {
    const sb3Url = `${projectPath(projectId)}/${sceneDescription.sb3}`
    sb3Promise = fetchArrayBuffer(sb3Url)
    fetchPromises.push(sb3Promise)
  } else {
    fetchPromises.push(Promise.resolve(null))
  }
  if (sceneDescription.metadata) {
    const metadataUrl = `${projectPath(projectId)}/${sceneDescription.metadata}`
    metadataPromise = fetchJson(metadataUrl)
    fetchPromises.push(metadataPromise)
  } else {
    fetchPromises.push(Promise.resolve(null))
  }
  if (sceneDescription.snapshot) {
    const snapshotUrl = `${projectPath(projectId)}/${sceneDescription.snapshot}`
    snapshotPromise = fetchJson(snapshotUrl)
    fetchPromises.push(snapshotPromise)
  } else {
    fetchPromises.push(Promise.resolve(null))
  }
  return Promise.all(fetchPromises).then(results => ({
    sb3: results[0],
    metadata: results[1],
    snapshot: results[2],
    id: sceneDescription.id
  }))
}



module.exports = function fetchAndLoadProject(state, emitter) {
  function createProjectFromScratchId(scratchId) {
    SBDL.downloadProjectFromID(scratchId, {}).then(result => {
      state.projectMetadata = {
        category:"scratch-import",
        id: result.title,
        project_file:null,
        thumbnail:null,
        title: result.title
      }
      state.project = {scenes:[]}
      loadSceneFromSb3(result, state, emitter)
    })
  }
  emitter.on('fetch-project', projectId => {
    log('fetch-project', projectId)
    const titleUpcase = projectId.toUpperCase()
    const pageTitle = `${titleUpcase} - Makey Arcade`
    document.title = pageTitle
    const pageLocation = window.location.href
    if (!state.gtagInitialized) {
      gtag('js', new Date());
      gtag('config', 'G-4Y753CNPQL');
      state.gtagInitialized = true
    }
    gtag('event', 'page_view', {
      page_title: pageTitle,
      page_location: pageLocation
    });
    
    if (state.availableProjects.find(p => p.id === projectId)) {
      fetchProject(projectId).then(project => {
        state.projectMetadata = project.metadata
        
        let exposedVariables = []
        if (project.exposedVariables) {
          exposedVariables = project.exposedVariables
        }
        state.projectMetadata = project
        if (project.project_file) {
          const url = `/provision/projects/${projectId}/${project.project_file}`
          fetchArrayBuffer(url).then(results => {
            loadProjectFromZip(results).then(zipProject => {
              state.project = {}
             // state.project.metadata = project.metadata
              state.project.scenes = zipProject.scenes
              state.project.scenes.forEach(scene => {
                state.histories[scene.metadata.id] = []
              })
              emitter.emit('render')
            })
            return Promise.resolve(true)
          })
        } else {
          state.project = project
          state.projectMetadata = project.metadata
          project.scenes.forEach(scene => {
            state.histories[scene.metadata.id] = []
          })
          emitter.emit('render')
        }
      })
    } else {
      createProjectFromScratchId(projectId)
    }
  })

}