const initialState = require('./store/initial-state.js')
const loadPartsAndGlitches = require('./store/load-parts-and-glitches.js')
const loadProject = require('./store/load-project.js')
const loadScene = require('./store/load-scene.js')
const scratchVM = require('./store/scratch-vm.js')
const playPause = require('./store/play-pause.js')
const documentEventListeners = require('./store/document-event-listeners.js')
const deselect = require('./store/deselect.js')
const drag = require('./store/drag.js')
const history = require('./store/history.js')
const importExportScenes = require('./store/import-export-scenes.js')
const mainMenu = require('./store/main-menu.js')
const importExportProject = require('./store/import-export-project.js')
const inventoryNavigation = require('./store/inventory-navigation.js')
const inventoryPick = require('./store/inventory-pick.js')
const toolbarSort = require('./store/toolbar-sort.js')
const toolbarSelection = require('./store/toolbar-selection.js')
const glitchApplication = require('./store/glitch-application.js')
const highlight = require('./store/highlight.js')
const target = require('./store/target.js')
const selectGlitchStickers = require('./store/select-glitch-stickers.js')
const moveGlitchStickers = require('./store/move-glitch-stickers.js')
const variables = require('./store/variables.js')
const applyGlitchToAll = require('./store/apply-glitch-to-all.js')
const addPartToGame = require('./store/add-part-to-game.js')
const deleteAndDuplicateSprites = require('./store/delete-and-duplicate-sprites.js')
const gameOver = require('./store/game-over.js')
const glitchEditorLoadContent = require('./store/glitch-editor-load-content.js')
const glitchEditorOpen = require('./store/glitch-editor-open.js')
const glitchEditorSave = require('./store/glitch-editor-save.js')
const glitchEditorSelect = require('./store/glitch-editor-select.js')
const glitchEditorVariables = require('./store/glitch-editor-variables.js')
const sceneManager = require('./store/scene-manager.js')
const spriteTransforms = require('./store/sprite-transforms.js')
const browser = require('./store/browser.js')
const playModes = require('./store/play-modes.js')
const devCommands = require('./store/dev-commands.js')
const spriteParameters = require('./store/sprite-parameters.js')
module.exports = function store(givenState, emitter) {
  // add event listener to window to catch resize events and emit 'render'
  window.addEventListener('resize', () => {
    emitter.emit('render')
  })
  
  const initialStateCopy = JSON.parse(JSON.stringify(initialState))

  const state = Object.assign(givenState, initialStateCopy)
  state.getHistory = initialState.getHistory
  loadPartsAndGlitches(state, emitter)
  loadProject(state, emitter)
  loadScene(state, emitter)
  scratchVM(state, emitter)
  playPause(state, emitter)
  documentEventListeners(state, emitter)
  deselect(state, emitter)
  drag(state, emitter)
  history(state, emitter)
  importExportScenes(state, emitter)
  mainMenu(state, emitter)
  importExportProject(state, emitter)
  inventoryNavigation(state, emitter)
  inventoryPick(state, emitter)
  toolbarSelection(state, emitter)
  toolbarSort(state, emitter)
  glitchApplication(state, emitter)
  target(state, emitter)
  highlight(state, emitter)
  selectGlitchStickers(state, emitter)
  moveGlitchStickers(state, emitter)
  variables(state, emitter)
  applyGlitchToAll(state, emitter)
  addPartToGame(state, emitter)
  deleteAndDuplicateSprites(state, emitter)
  gameOver(state, emitter)
  glitchEditorLoadContent(state, emitter)
  glitchEditorOpen(state, emitter)
  glitchEditorSave(state, emitter)
  glitchEditorSelect(state, emitter)
  glitchEditorVariables(state, emitter)
  sceneManager(state, emitter)
  spriteTransforms(state, emitter)
  browser(state, emitter)
  playModes(state, emitter)
  devCommands(state, emitter)
  spriteParameters(state, emitter)
}
