module.exports = {
  "id": "spawn",
  "color": "orange",
  "title": "spawn",
  "icon": "shuffle",
  "category": "clone",
  "description": "Change the level number when one sprite touches another.",
  "exposedVariables": [
    {
      "variableName": "number to spawn",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "100",
        "value": "1",
        "step": "1"
      },
      "value": "0"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "spawn mode",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "random (avoid sprite)",
            "label": "random (avoid sprite)"
          },
          {
            "value": "on sprite",
            "label": "on sprite"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "on sprite"
    },
{
      "variableName": "spawn sprite",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite",
        "min": "0",
        "max": "20",
        "value": "5",
        "step": ".1",
        "options": []
      },
      "value": "none"
    },
    {
      "variableName": "spawn delay",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "10",
        "value": "1",
        "step": ".1"
      },
      "value": "0"
    }
  ],
  "info": [
    {
      "text": "Create a stream of clones to move across the screen.",
      "image": "provision/glitches/traffic/traffic.gif"
    }
  ],
  "codeImage": "provision/glitches/traffic/traffic.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/spawn/spawn.svg",
  "isRemix": true
};