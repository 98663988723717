const html = require('choo/html')

const DraggingElement = require('./components/draggingelement.js')
const Menu = require('./components/menu.js')
const GameControls = require('./components/gamecontrols.js')
const ScreenTitle = require('./components/screentitle.js')
const Toolbar = require('./components/toolbar.js')
const Screen = require('./components/screen.js')
const ContextMenu = require('./components/contextmenu.js')

function LoadingMessage (text) {
  return html`
  <div id="app">
    <div class="grid-12">
      <div class="column-12 huge-letters" style="text-align: center;">
        ${text}
      </div>
    </div>
  </div>`
}
module.exports = function BendingView (state, emit) {
  document.getElementById('branding').style.display = 'none'
  if (!state.vm){
    emit('load-vm')
    return LoadingMessage('Loading VM')
  } 
  if (!state.glitchesLoaded) {
    emit('load-glitches')
    return LoadingMessage('Loading Glitches')
  }
  if (!state.partsLoaded) {
    emit('load-parts')
    return LoadingMessage('Loading Parts')
  }
  if (!state.project) {
    emit('fetch-project', state.params.project_id)
    return LoadingMessage('Fetching Project')
  }
  if (!state.currentScene) {
    emit('load-scene', 0)
    return LoadingMessage('Loading Scene')
  }
  if (state.isFullscreen){
    return html`
    <div id="app">
      <div class="grid-12 main-row">

        <div class="column-9">
          ${Screen(state, emit)}
        </div>
        
        <div class="column-3 context-menu">
          ${ContextMenu(state, emit)}
        </div>
      </div>
    </div>
  `
  }
  return html`
    <div id="app">
      <div class="grid-12 top-row">
        <div class="column-1 hamburger">
          ${Menu(state, emit)}
        </div>
        <div class="column-8 game-controls">
          ${GameControls(state, emit)}
        </div>
        <div class="column-3 context-title">
          ${ScreenTitle(state, emit)}
        </div>
      </div>
      <div class="grid-12 main-row">
        <div class="column-1 menu">
          ${Toolbar(state, emit)}
        </div>
        <div class="column-8">
          ${Screen(state, emit)}
        </div>
        <div class="column-3 context-menu">
          ${ContextMenu(state, emit)}
        </div>
      </div>
      ${DraggingElement(state, emit)}
    </div>
  `
}

