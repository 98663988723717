module.exports = {
  "id": "portal",
  "title": "portal",
  "image": "provision/parts/portal/portal.svg",
  "exposedVariables": [
    {
      "value": 0.25,
      "variableName": "animation delay",
      "spriteName": "portal",
      "input": {
        "type": "slider",
        "min": 0,
        "max": 2,
        "step": 0.01
      },
      "id": "Ixu%V,LeM8.M|5lqF,Ul"
    },
    {
      "spriteName": "portal",
      "variableName": "portal color",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "orange",
            "label": "orange"
          },
          {
            "value": "blue",
            "label": "blue"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "orange"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null
};