module.exports = {
  "id": "ufo",
  "title": "ufo",
  "image": "provision/parts/ufo/ufo.svg",
  "info": [
    {
      "text": "Add a ufo to your game",
      "image": "provision/parts/ufo/ufo.gif"
    }
  ],
  "exposedVariables": [],
  "codeImage": "provision/parts/ufo/ufo.png"
};