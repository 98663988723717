module.exports = {
  "id": "digify",
  "color": "red",
  "icon": "digify",
  "info": [
    {
      "text": "Pixellate a sprite.",
      "image": "provision/glitches/digify/digify.gif"
    }
  ],
  "category": "magic",
  "title": "digify",
  "exposedVariables": [
    {
      "value": "5",
      "variableName": "Digify Speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": 1,
        "max": 10,
        "step": ".1"
      }
    },
    {
      "value": "100",
      "variableName": "Digify Pixellation",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": 1,
        "max": 500,
        "step": "1"
      }
    }
  ],
  "codeImage": "provision/glitches/digify/digify.png",
  "code": null,
  "zip": null,
  "sb3": null
};