module.exports = {
  "id": "effects",
  "title": "effects",
  "category": "edit",
  "color": "purple",
  "info": [
    {
      "text": "Change a sprite's visual effects.",
      "image": "provision/glitches/effects/effects.gif"
    }
  ],
  "icon": "paint",
  "exposedVariables": [
  ],
  "codeImage": "provision/glitches/effects/effects.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/effects/effects.svg"
};