module.exports = {
  "id": "slippery",
  "color": "red",
  "title": "slippery",
  "icon": "slippery",
  "description": "Make a sprite slippery so other sprites sprites slip on it.",
  "category": "magic",
  "exposedVariables": [
    {
      "variableName": "slipperiness",
      "spriteName": "$glitch_target",
      "value": 10,
      "input": {
        "type": "slider",
        "min": "0",
        "max": "30"
      }
    }
  ],
  "info": [
    {
      "text": "Make a sprite slippery so other sprites sprites slip on it.",
      "image": "provision/glitches/slippery/slippery.gif"
    }
  ],
  "codeImage": "provision/glitches/slippery/slippery.png"
};