module.exports = {
  "id": "little_skull",
  "title": "Little skull",
  "image": "provision/parts/little_skull/little_skull.svg",
  "info": [
    {
      "text": "Add a little skull to your game",
      "image": "provision/parts/little_skull/little_skull.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": 0.1,
      "variableName": "animation speed",
      "spriteName": "little skull",
      "input": {
        "type": "slider",
        "min": 0,
        "max": 1,
        "step": 0.01
      }
    }
  ],
  "codeImage": "provision/parts/little_skull/little_skull.png"
};