module.exports = {
    "id": "musicify",
    "color": "blue",
    "title": "musicify",
    "icon": "musicify",
    "description": "Make a sprite play a note whenever it's touched.",
    "category": "magic",
    "exposedVariables": [
      {
        "variableName": "note play length",
        "spriteName": "$glitch_target",
        "value": 0.25,
        "input": {
          "type": "slider",
          "min": "0.25",
          "max": "1",
          "step": "0.05"
        }
      }
    ],
    "info": [
      {
        "text": "Make a sprite play a note whenever it's touched.",
        "image": "provision/glitches/musicify/musicify.gif"
      }
    ],
    "codeImage": "provision/glitches/musicify/musicify.png"
  };