module.exports = {
  "id": "flippify",
  "color": "pink",
  "title": "Flippify",
  "icon": "flippify",
  "description": "Turn any sprite into a pinball flipper",
  "category": "powers",
  "exposedVariables": [
    {
      "variableName": "launch strength",
      "spriteName": "$glitch_target",
      "value": "15",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "50",
        "step": ".1"
      }
    },
    {
      "variableName": "flip speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "1",
        "max": "20",
        "value": 5,
        "step": ".1"
      },
      "value": "10"
    },
    {
      "variableName": "flip distance",
      "spriteName": "$glitch_target",
      "value": 60,
      "input": {
        "type": "slider",
        "min": "10",
        "max": "120",
        "step": "1"
      }
    },
    {
      "variableName": "flip direction",
      "spriteName": "$glitch_target",
      "input": {
        "type": "option",
        "options": [
          {
            "label": "left",
            "value": "left"
          },
          {
            "label": "right",
            "value": "right"
          }
        ]
      },
      "value": "left"
    },
    {
      "variableName": "trigger key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      },
      "value": "left arrow"
    }
  ],
  "info": [
    {
      "text": "Turn any sprite into a pinball flipper",
      "image": "provision/glitches/flippify/flippify.gif"
    }
  ],
  "codeImage": "provision/glitches/flippify/flippify.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/flippify/flippify.svg"
};