module.exports = {
  "id": "tomato",
  "title": "tomato",
  "image": "provision/parts/tomato/tomato.svg",
  "info": [
    {
      "text": "Add a Coffee Mug to your game",
      "image": "provision/parts/tomato/tomato.gif"
    }
  ],
  "exposedVariables": [],
  "codeImage": "provision/parts/tomato/tomato.png"
};