module.exports = {
  "id": "sticky",
  "color": "red",
  "title": "sticky",
  "icon": "sticky",
  "description": "Make a sprite sticky so other sprites move slowly when touching it.",
  "category": "magic",
  "exposedVariables": [
    {
      "variableName": "stickiness",
      "spriteName": "$glitch_target",
      "value": 0.5,
      "input": {
        "type": "slider",
        "min": "0.1",
        "max": "0.9"
      }
    }
  ],
  "info": [
    {
      "text": "Make a sprite sticky so other sprites move slowly when touching it.",
      "image": "provision/glitches/sticky/sticky.gif"
    }
  ],
  "codeImage": "provision/glitches/sticky/sticky.png"
};