module.exports = {
  "id": "repel",
  "color": "orange",
  "title": "repel",
  "icon": "repel",
  "category": "magic",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "repel power",
      "input": {
        "type": "slider",
        "options": [],
        "min": "1",
        "max": 10,
        "step": "1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "repel radius",
      "input": {
        "type": "slider",
        "options": [],
        "min": "25",
        "max": 200,
        "step": "1"
      },
      "value": "75"
    }
  ],
  "info": [
    {
      "text": "Push away other sprites that get too close",
      "image": "provision/glitches/repel/repel.gif"
    }
  ],
  "codeImage": "provision/glitches/repel/repel.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/repel/repel.svg"
};