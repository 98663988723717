module.exports = {
  "id": "gravity",
  "icon": "appledown",
  "color": "green",
  "title": "gravity",
  "category": "movement",
  "info": [
    {
      "text": "Fall into you hit the ground.",
      "image": "provision/glitches/gravity/gravity.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": "1",
      "variableName": "gravity strength",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "25",
        "options": [],
        "step": ".1"
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "gravity direction",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "-1",
            "label": "-1"
          },
          {
            "value": "1",
            "label": "1"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "-1"
    }
  ],
  "codeImage": "provision/glitches/gravity/gravity.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "isRemix": true,
  "image": "provision/parts/gravity/gravity.svg"
};