module.exports = {
  "id": "helicopter",
  "title": "helicopter",
  "icon": "helicopter",
  "color": "pink",
  "description": "Press SPACE to spin.",
  "category": "powers",
  "exposedVariables": [
    {
      "variableName": "helicopter speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": 20,
        "value": 10,
        "step": ".1",
        "min": "-20"
      },
      "value": "5"
    },
    {
      "variableName": "helicopter key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key",
        "value": "space",
        "options": [
        ]
      },
      "value": "space"
    }
  ],
  "info": [
    {
      "text": "Make your target sprite spin when you press a key.",
      "image": "provision/glitches/helicopter/helicopter.gif"
    }
  ],
  "codeImage": "provision/glitches/helicopter/helicopter.png",
  "code": null,
  "zip": null,
  "sb3": null
};