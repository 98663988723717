const { log } = console
module.exports = function store(state, emitter) {
  function deselectAll(e) {
    const parent = e.target.parentNode
    if (!parent || !parent.classList) {
      return
    }
    if (
      parent.classList.contains('top-row')
      || parent.classList.contains('main-row')
      || parent.id === 'app'
      || parent.tagName.toLowerCase() === 'html'
    ) {
      emitter.emit('deselect-all')
    }
  }

  function endDrag(e) {
    emitter.emit('complete-drag-action', e)
    emitter.emit('cleanup-drag')
  }

  function playKeyDown(e) {
    if (!(e.target.tagName.toUpperCase() === 'INPUT')) {
      if (e.key === 'Delete') {
        if (state.selectedGlitchApplicationId) {
          emitter.emit('drop-to-trash')
        } else {
          emitter.emit('delete-selected-sprite')
        }
      }
      else {
        emitter.emit('play-key-down', e)
      }
    }
  }


  function playKeyUp(e) {
    emitter.emit('play-key-up', e)
  }

  emitter.on('play-key-up', event => {
    log('play-key-up', event)
    if (state.isGamePlaying) {
      state.vm.postIOData('keyboard', { key: event.key, isDown: false })
    }
  })

  emitter.on('play-key-down', event => {
    log('play-key-down', event)
    if (state.isGamePlaying) {
      state.vm.postIOData('keyboard', { key: event.key, isDown: true })
    }
  })
  emitter.on('play-mouse-move', e => {
    const rect = state.canvas.getBoundingClientRect()
    const coordinates = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
      canvasWidth: rect.width,
      canvasHeight: rect.height
    }
    state.vm.postIOData('mouse', coordinates)
  })

  emitter.on('play-mouse-down', e => {
    const rect = state.canvas.getBoundingClientRect()
    const data = {
      isDown: true,
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
      canvasWidth: rect.width,
      canvasHeight: rect.height
    }
    state.vm.postIOData('mouse', data)
  })

  emitter.on('play-mouse-up', e => {
    const rect = state.canvas.getBoundingClientRect()
    const data = {
      isDown: false,
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
      canvasWidth: rect.width,
      canvasHeight: rect.height
    }
    state.vm.postIOData('mouse', data)
  })

  emitter.on('add-document-event-listeners', () => {
    document.addEventListener('mousedown', deselectAll)
    document.addEventListener('mouseup', endDrag)
    document.addEventListener('keydown', playKeyDown)
    document.addEventListener('keyup', playKeyUp)
  })

  emitter.on('remove-document-event-listeners', () => {
    document.removeEventListener('mousedown', deselectAll)
    document.removeEventListener('mouseup', endDrag)
    document.removeEventListener('keydown', playKeyDown)
    document.removeEventListener('keyup', playKeyUp)
  })
}
