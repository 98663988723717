const html = require('choo/html')
const InventoryMenu = require('./inventorymenu.js')
const InventoryItems = require('./inventoryitems.js')

module.exports = function Inventory (state, emit) {
  let onMouseUp
  if (state.movingGlitch) {
    onMouseUp = () => emit(
      'remove-glitch-from-toolbar',
      state.selectedGlitchId
    )
  } else if (state.movingPart) {
    onMouseUp = () => emit(
      'remove-part-from-toolbar',
      state.selectedPartId
    )
  } else {
    onMouseUp = () => false
  }
  return html`
    <div 
      class="inventory"
      onmouseup=${onMouseUp}
    >
      ${InventoryMenu(state, emit)}
      ${InventoryItems(state, emit)}
    </div>
  `
}
