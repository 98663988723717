const MAX_TOOLBAR_ITEMS = 8
const ToolbarElement = require('../elements/toolbar.js')
const ButtonIcon = require('../elements/buttonicon.js')
const ButtonImage = require('../elements/buttonimage.js')

module.exports = function GameToolbar (state, emit) {
  const categoryA = state.toolbar.glitches.map(
    itemId => {
      const glitch = state.availableGlitches.find(g => g.id === itemId)
      const disabled = state.disabledToolbarIcons.indexOf(glitch.id) !== -1
                      || state.pickingPart
                      || state.isMenuOpen
      return ButtonIcon({
        disabled,
        color: glitch.color,
        icon: glitch.icon,
        selected: state.selectedGlitchId === glitch.id,
        pickable: true,
        onMouseDown: e => {
          const mini = ButtonIcon({
            color: glitch.color,
            icon: glitch.icon
          })
          emit('start-drag', mini, e)
          // If inventory is closed
          if (state.isInventoryOpen) {
            emit('start-moving-glitch', glitch.id)
          } else {
            emit('start-applying-glitch', glitch.id)
          }
        },
        onMouseUp: () => {
          if (state.pickingGlitch) {
            emit('pick-and-replace-glitch', itemId)
          }
        },
        onMouseOver: () => {
          if (state.movingGlitch) {
            emit('swap-selected-glitch', itemId)
          }
        }
      })
    }
  )
  const categoryB = state.toolbar.parts
    .map(itemId => {
      const part = state.availableParts.find(g => g.id === itemId)
      const disabled = state.disabledToolbarIcons.indexOf(part.id) !== -1
                      || state.pickingGlitch
                      || state.isMenuOpen
      return ButtonImage({
        disabled,
        type: 'circle',
        image: part.image,
        pickable: true,
        selected: state.selectedPartId === part.id,
        onMouseDown: e => {
          const mini = ButtonImage({
            type: 'circle',
            image: part.image
          })
          emit('start-drag', mini, e)
          if (state.isInventoryOpen) {
            emit('start-moving-part', part.id)
          } else {
            emit('start-importing-part', part.id)
          }
        },
        onMouseUp: () => {
          if (state.pickingPart) {
            emit('pick-and-replace-part', itemId)
          }
        },
        onMouseOver: () => {
          if (state.movingPart) {
            emit('swap-selected-part', itemId)
          }
        }
      })
    })
  const itemCount = categoryA.length + categoryB.length
  if (state.pickingGlitch && itemCount < MAX_TOOLBAR_ITEMS) {
    categoryA.push(ButtonIcon({
      color: 'darkgrey',
      onMouseUp: () => emit('pick-glitch')
    }))
  }
  if (state.pickingPart && itemCount < MAX_TOOLBAR_ITEMS) {
    categoryB.push(ButtonIcon({
      type: 'circle',
      color: 'darkgrey',
      onMouseUp: () => emit('pick-part')
    }))
  }
  return ToolbarElement({
    categoryA,
    categoryB,
    categoryC: ButtonIcon({
      disabled: state.isMenuOpen,
      selected: false,
      icon: state.isInventoryOpen ? 'cross' : 'plus',
      onClick: () => emit('toggle-inventory')
    })
  })
}
