const html = require('choo/html')

module.exports = function Toolbar (args) {
  const {
    categoryA,
    categoryB,
    categoryC
  } = args

  let separatorB = null
  let separatorC = null

  if (categoryA.length > 0) {
    separatorB = html`<hr>`
  }
  if (categoryB.length > 0) {
    separatorC = html`<hr>`
  }

  return html`
    <div class="toolbar">
      ${categoryA}
      ${separatorB}
      ${categoryB}
      ${separatorC}
      ${categoryC}
    </div>
  `
}
