module.exports = {
  "id": "spikes",
  "title": "spikes",
  "image": "provision/parts/spikes/spikes.svg",
  "info": [
    {
      "text": "Add spikes to your game",
      "image": "provision/parts/spikes/spikes.gif"
    }
  ],
  "exposedVariables": [],
  "codeImage": "provision/parts/spikes/spikes.png"
};