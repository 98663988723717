/* eslint-disable no-underscore-dangle */
const { log } = console
const { copyVariables } = require('./utils/apply-glitch.js')
const {copyNonGlitchBlocks} = require('./utils/glitch-editor.js')
const ZOOM_X = 600
const BRIEF_INTERVAL = 10
function setupEditor (glitchEditor) {
  glitchEditor.vm.stopAll()
  glitchEditor.workspace.zoom(ZOOM_X, 0, -1)
  if (glitchEditor.vm.editingTarget.isStage) {
    glitchEditor.vm.setEditingTarget(glitchEditor.vm.runtime.targets[1].id)
    glitchEditor.vm.setEditingTarget(glitchEditor.vm.runtime.targets[0].id)
  } else {
    glitchEditor.vm.setEditingTarget(glitchEditor.vm.runtime.targets[0].id)
    glitchEditor.vm.setEditingTarget(glitchEditor.vm.runtime.targets[1].id)
  }
}


module.exports = function store (state, emitter) {
  emitter.on('load-glitch-editor-content', () => {
    log('load-glitch-editor-content')
    let {selectedGlitchId} = state
    if (state.selectedGlitchApplicationId) {
      const glitchApplication = state.glitchApplications.find(
        g => g.id === state.selectedGlitchApplicationId
      )
      selectedGlitchId = glitchApplication.glitchId
    }
    if (selectedGlitchId || state.selectedPartId) {
      let contentToLoad = null
      if (selectedGlitchId) {
        contentToLoad = state.availableGlitches.find(
          g => g.id === selectedGlitchId
        )
      } else if (state.selectedPartId) {
        contentToLoad = state.availableParts.find(
          p => p.id === state.selectedPartId
        )
      }
      state.glitchEditor.vm.loadProject(contentToLoad.code).then(() => {
        setupEditor(state.glitchEditor, contentToLoad.exposedVariables)
        state.glitchEditorVariables = contentToLoad.exposedVariables
        state.glitchEditorContentLoaded = true
        const [iframe] = document.getElementsByClassName('glitch-editor-iframe')
        state.glitchEditor.workspace.cleanUp()

        iframe.style.visibility = 'visible'

        emitter.emit('render')
      })
    } else {
      let selectedTarget = state.vm.runtime.getTargetForStage()
      let [glitchTarget] = state.glitchEditor.vm.runtime.targets
      copyVariables(selectedTarget, glitchTarget)
      if (state.selectedTargetId) {
        [ , glitchTarget ] = state.glitchEditor.vm.runtime.targets
        selectedTarget = state.vm.runtime.targets.find(
          t => t.id === state.selectedTargetId
        )
        copyVariables(selectedTarget, glitchTarget)
      }
      glitchTarget.sprite.name = selectedTarget.sprite.name
      state.glitchEditor.vm.setEditingTarget(glitchTarget.id)
      copyNonGlitchBlocks(
        selectedTarget,
        glitchTarget,
        state.glitchEditor.vm,
        state.glitchApplications
      )
      setupEditor(state.glitchEditor, emitter.emit)
      const part = state.addedParts.find(
        p => p.spriteName === selectedTarget.sprite.name
      )
      if (part) {
        state.glitchEditorVariables = part.exposedVariables
      } else {
        const {exposedVariables} = state.currentScene.metadata
        state.glitchEditorVariables = exposedVariables.filter(
          v => v.spriteName === selectedTarget.sprite.name
        )
      }
      state.glitchEditorContentLoaded = true
      setTimeout(() => {
        const [iframe] = document.getElementsByClassName('glitch-editor-iframe')
        iframe.style.visibility = 'visible'
        state.glitchEditor.workspace.cleanUp()
        emitter.emit('render')
      }, BRIEF_INTERVAL)
    }
  })
}
