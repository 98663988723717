module.exports = {
  "id": "shield",
  "color": "red",
  "title": "shield",
  "icon": "shield",
  "description": "Make a sprite shield so other sprites sprites slip on it.",
  "category": "magic",
  "exposedVariables": [],
  "info": [
    {
      "text": "Make a sprite shield so other sprites sprites slip on it.",
      "image": "provision/glitches/shield/shield.gif"
    }
  ],
  "codeImage": "provision/glitches/shield/shield.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "isRemix": true,
  "image": "provision/parts/shield/shield.svg"
};