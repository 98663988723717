module.exports = {
  "title": "walk",
  "id": "walk",
  "info": [
    {
      "text": "Control a sprite using the arrow keys",
      "image": "provision/glitches/walk/walk.gif"
    },
    {
      "text": "Use the variables panel to use different keys or change the movement speed.",
      "image": "provision/glitches/walk/variables.gif"
    }
  ],
  "category": "powers",
  "color": "pink",
  "icon": "gamepad",
  "exposedVariables": [
    {
      "value": "left arrow",
      "variableName": "left key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      }
    },
    {
      "value": "right arrow",
      "variableName": "right key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      }
    },
    {
      "value": "up arrow",
      "variableName": "up key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      }
    },
    {
      "value": "down arrow",
      "variableName": "down key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key"
      }
    },
    {
      "value": 5,
      "variableName": "walk speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -30,
        "max": 30
      }
    },
    {
      "value": 1,
      "variableName": "friction",
      "spriteName": "$glitch_target",
      "override": true,
      "input": {
        "type": "slider",
        "min": 0,
        "max": 1
      }
    }
  ],
  "codeImage": "provision/glitches/walk/walk.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/walk/walk.svg"
};