const html = require('choo/html')
const ButtonIcon = require('../elements/buttonicon.js')
const ButtonImage = require('../elements/buttonimage.js')
const Button = require('../elements/button.js')
module.exports = function InventoryPacks (state, emit) {
  const packs = []
  let {availablePacks} = state
  if (state.currentScene.metadata.allowedPacks) {
    availablePacks = availablePacks.filter(
      p => state.currentScene.metadata.allowedPacks.includes(p.id)
    )
  }
  availablePacks.forEach(pack => {
    const packGlitches = state.availableGlitches.filter(
      g => pack.glitches.includes(g.id)
    )
    const glitchItems = packGlitches.map(
      glitch => ButtonIcon({
        disabled: state.toolbar.glitches.find(itemId => itemId === glitch.id),
        selected: state.selectedGlitchId === glitch.id,
        size: 'big',
        icon: glitch.icon,
        color: glitch.color,
        onMouseDown: e => {
          const mini = ButtonIcon({
            icon: glitch.icon,
            color: glitch.color
          })
          emit('start-drag', mini, e)
          emit('start-picking-glitch', glitch.id)
        }
      })
    )
    const packParts = state.availableParts.filter(
      p => pack.parts.includes(p.id)
    )
    const partItems = packParts.map(
      part => ButtonImage({
        disabled: state.toolbar.parts.find(itemId => itemId === part.id),
        selected: state.selectedPartId === part.id,
        type: 'circle',
        size: 'big',
        image: part.image,
        onMouseDown: e => {
          const mini = ButtonImage({
            type: 'circle',
            image: part.image
          })
          emit('start-drag', mini, e)
          emit('start-picking-part', part.id)
        }
      })
    )
    const loadButton = Button({
      color: 'lightgrey',
      text: 'LOAD',
      size: 'small',
      type: 'tight',
      onClick: () => emit('load-pack-to-toolbar', pack.id)
    })
    packs.push(html`
      <div class = "column-1"></div>
      <div class="column-6 pack">
          <div class = "header">
            <span class="text large-letters">${pack.title}</span>
            ${loadButton}
          </div>
          <div class = "thumbnail">
            <img src="/${pack.image}">
          </div>
          <div class = "glitches">
            ${glitchItems}
            ${partItems}
          </div>
        </div>
        <div class = "column-1"></div>
      `)
  })
  return html`
    <div class="grid-8 inventory-items">
      ${packs}
    </div>
  `
}
