/**
 * Utility functions to save and load snapshots of scratch-vm data and state
 * and to ensure that saved data references saved objects rather than active
 * objects.
 *
 * See {@link https://github.com/LLK/scratch-vm | the scratch-vm source code}
 * for documentation of the data structures we are copying.
 *
 * @module store/utils/save-snapshot
 */

/**
 * Creates an "instance" of an object's type by creating an object with the same
 * prototype.
 * @param {object} object - an object with a type to create an instance of
 * @returns {object} - an object with the same prototype as the object
 */
function createInstanceOf(object) {
  return Object.create(Object.getPrototypeOf(object))
}

/**
 * Gets a list of the drawing layer order for a set of targets.
 * @param {Array.<Object>} targets - a list of scratch target objects
 * @returns {Array.<string>} - an array of scratch target ids ordered by
 * drawable layer
 */
function getTargetLayers(targets) {
  const targetLayers = []
  targets.forEach(t => {
    targetLayers[t.getLayerOrder()] = t.id
  })
  return targetLayers
}

/**
 * Copies a list of scratch threads to be saved as part of a snapshot
 * of the scratch-vm.
 *
 * @param {object[]} threadsToCopy - an array of scratch threads
 * @param {object[]} savedTargets - an array of scratch targets
 * @returns {object[]} - an array of copies of the original threads with
 * fixed to reference targets in the savedTargets array
 */
function copyThreads(threadsToCopy, savedTargets) {
  /**
   * Takes a scratch stackFrame object and returns a copy of this stackFrame for
   * the vm snapshot.
   * @param {object} stackFrameToCopy - a scratch-vm StackFrame
   * @returns {object}
   */
  function copyStackFrame(stackFrameToCopy) {
    const stackFrameCopy = createInstanceOf(stackFrameToCopy)
    Object.assign(stackFrameCopy, stackFrameToCopy)
    stackFrameCopy.executionContext = { ...stackFrameToCopy.executionContext }
    if (stackFrameCopy.executionContext.startedThreads) {
      const threads = [...stackFrameCopy.executionContext.startedThreads]
      stackFrameCopy.executionContext.startedThreads = threads
    }
    stackFrameCopy.reported = null
    stackFrameCopy.reporting = ''

    return stackFrameCopy
  }

  /**
   * Fixes the references in a list of saved threads to be interally consistent
   * and only point to other threads in the list to make the thread data
   * ready to be saved as part of a vm snapshot.
   * @param {Array.<Object>} savedThreads - a list of saved scratch threads
   * @returns {Array.<Object>} - a list of threads ready to be saved in the
   * vm snapshot.
   */

  function fixThreadReferences(savedThreads) {
    savedThreads.forEach(savedThread => {
      savedThread.stackFrames.forEach(frame => {
        if (frame.executionContext && frame.executionContext.startedThreads) {
          const { startedThreads } = frame.executionContext
          for (let i = 0; i < startedThreads.length; i++) {
            const oldThread = startedThreads[i]
            const newThread = savedThreads.find(
              thread => thread.topBlock === oldThread.topBlock
                && thread.target.id === oldThread.target.id
            )

            /*
             * If we found a matching saved thread, swap it out.  The execution
             * context can refer to threads that no longer exist, so if we don't
             * find it that's OK, we just let it point to the old one.
             */
            if (newThread) {
              frame.executionContext.startedThreads[i] = newThread
            }
          }
        }
      })
    })
    return savedThreads
  }
  /**
   * Fixes the target references in a list of saved threads.
   * @param {object[]} threadsToFix - a list of scratch threads
   * @returns {object[]} a list of these threads containing only references
   * to targets in the savedTargets list
   */
  function fixThreadTargetReferences(threadsToFix) {
    threadsToFix.forEach(savedThread => {
      savedThread.target = savedTargets.find(
        t => t.id === savedThread.target.id
      )
      if (savedThread.target) {
        savedThread.blockContainer = savedThread.target.blocks
      } else {
        savedThread.target = null
      }
    })
    return threadsToFix.filter(t => t.target !== null)
  }

  /**
   * Takes a scratch thread object and returns a copy of this thread for the
   * vm snapshot.
   * This copy will be in an intermediary state with references to unsaved
   * threads that will need to be replaced in the saved data.
   * @param {object} threadToCopy - a scratch-vm thread
   * @returns {object} - a copy of this thread
   */
  function copyThread(threadToCopy) {
    const savedThread = createInstanceOf(threadToCopy)
    Object.assign(savedThread, threadToCopy)

    // Stack is an array of block id strings, so we can just copy the values
    savedThread.stack = [...threadToCopy.stack]

    /*
     * Save a copy of the stackFrames for the thread.  These stackFrames may
     * contain references to threads which will need to be fixed later in the
     * saving process.
     */
    savedThread.stackFrames = threadToCopy.stackFrames.map(
      stackFrame => copyStackFrame(stackFrame)
    )

    const WAITING_FOR_PROMISE_TO_RESOLVE = 1
    const RESOLVED = 0
    if (savedThread.status === WAITING_FOR_PROMISE_TO_RESOLVE) {
      savedThread.status = RESOLVED
    }
    return savedThread
  }

  const savedThreads = threadsToCopy.map(
    thread => copyThread(thread)
  )
  let fixedThreads = fixThreadReferences(savedThreads)
  fixedThreads = fixThreadTargetReferences(fixedThreads, savedTargets)
  return fixedThreads
}


/**
 * Copies a list of scratch targets to be saved as part of a snapshot
 * of the scratch-vm.
 *
 * @param {object[]} targetsToCopy - an array of scratch targets
 * @returns {object[]} - an array of copies of these targets
 */

function copyTargets(targetsToCopy) {
  /**
   * Fixes the references in a list of saved targets to be interally consistent
   * and ready to be saved as part of a vm snapshot.
   *
   * @param {object[]} savedTargets - an array of scratch targets
   * containing references to clones and sprites in the active-vm
   * @returns {object[]} - an array of these targets with references to
   * outside targets and sprites replaced with references to targets and
   * sprites in the savedTargets list.
   */

  function fixTargetReferences(savedTargets) {
    const savedOriginals = savedTargets.filter(t => t.isOriginal)
    savedOriginals.forEach(target => {
      for (let i = 0; i < target.sprite.clones.length; i++) {
        const cloneId = target.sprite.clones[i].id
        target.sprite.clones[i] = savedTargets.find(t => t.id === cloneId)
        target.sprite.clones[i].sprite = target.sprite
        target.sprite.clones[i].blocks = target.blocks
      }
    })
    return savedTargets
  }

  /**
   * Creates a copy of a target's blockContainer object.
   *
   * @param {object} blockContainer - a sprite's blockContainer object
   * @returns {object} - a copy of the blockContainer with the cache cleared
   */

  function copyBlockContainer(blockContainer) {
    const newBlockContainer = createInstanceOf(blockContainer)
    newBlockContainer.runtime = blockContainer.runtime
    newBlockContainer.forceNoGlow = blockContainer.forceNoGlow

    // _scripts is an array of hat block IDs.
    newBlockContainer._scripts = [...blockContainer._scripts]

    newBlockContainer._cache = {
      inputs: {},
      procedureDefinitions: {},
      procedureParamNames: {},
      scripts: {},
      _executeCached: {},
      _monitored: null
    }

    // The blocks themselves are just data so can be safely json-cloned
    newBlockContainer._blocks = JSON.parse(
      JSON.stringify(blockContainer._blocks)
    )
    return newBlockContainer
  }


  /**
   * Creates a copy of a scratch sprite as part of the process of saving a
   * snapshot of the scratch-vm.
   *
   * The resulting copy will have a clone list that references targets from
   * the vm we are in the process of saving rather than the saved targets.
   * @param {object} spriteToCopy - a scratch sprite to copy
   * @returns {object} - a copy of the sprite
   */

  function copySprite(spriteToCopy) {
    const savedSprite = createInstanceOf(spriteToCopy)
    savedSprite.clones = [...spriteToCopy.clones]

    /*
     * This is an array of ojects that contain costume data.  It should be fine
     * to copy the array of references because the asset objects don't change.
     */
    savedSprite.costumes = [...spriteToCopy.costumes]

    savedSprite.name = spriteToCopy.name
    savedSprite.runtime = spriteToCopy.runtime

    /*
     * TODO:  think more about audio. This "should work" and "seems to work" but
     * if underanalyzed.
     */
    savedSprite.soundBank = spriteToCopy.soundBank
    savedSprite.sounds = [...spriteToCopy.sounds]

    return savedSprite
  }

  /**
   * Creates a copy of a scratch target as part of the process of saving a
   * snapshot of the scratch-vm.  The resulting copy will be in an intermediary
   * state with internal references to objects in the vm we are copying rather
   * than the snapshot.
   *
   * @param {object} targetToCopy - a scratch target object
   * @returns {object} - a copy of the target
   */

  function copyTarget(targetToCopy) {
    const copy = createInstanceOf(targetToCopy)
    Object.assign(copy, targetToCopy)

    copy.effects = { ...copy.effects }
    copy.comments = { ...copy.comments }
    copy.soundEffects = { ...copy.soundEffects }
    copy._edgeActivatedHatValues = { ...copy._edgeActivatedHatValues }

    copy._customState = JSON.parse(JSON.stringify(targetToCopy._customState))

    /*
     * We clear the 'Scratch.looks' data because it can contain a
     * reference to a drawableId in the scratch renderer and we don't
     * want to capture and save renderer state.
     * This decision means we will sometimes lose speech
     * bubbles when we save/load.
     */
    copy._customState['Scratch.looks'] = {
      drawableId: null,
      onSpriteRight: true,
      skinId: null,
      text: '',
      type: 'say',
      usageId: null
    }

    /*
     * Make a clone of the variables object with the same data that keeps the
     * Variable prototype for each variable
     */
    copy.variables = {}
    const original = targetToCopy.sprite.clones[0]
    Object.values(targetToCopy.variables).forEach(v => {
      let copyVar = true
      if (v.value === 0 || v.value === '0'){
        copyVar = false
      }
      if (!targetToCopy === original) {
        const originalVariable = original.variables.find(ov => ov.id === v.id)
        if (originalVariable.value === v.value) {
          copyVar = false
        }
      }
      if (copyVar) {
        copy.variables[v.id] = createInstanceOf(v)
        const variableData = JSON.parse(JSON.stringify(v))
        if (!isNaN(variableData.value) && variableData.type !== 'list') {
          variableData.value = parseFloat(parseFloat(variableData.value).toFixed(2))
        }
        Object.assign(copy.variables[v.id], variableData)
      }
    })


    /*
     * If the target is not a clone we copy it's sprite and blocks objects.
     * Because all clones share a sprite and blocks we will need to fix their
     * references after we know we've copied all of the originals.
     */
    if (copy.isOriginal) {
      copy.blocks = copyBlockContainer(targetToCopy.blocks)
      copy.sprite = copySprite(targetToCopy.sprite)
      copy.sprite.blocks = copy.blocks
    } else {
      copy.blocks = {}
      copy.sprite = {}
    }
    copy.x = parseFloat(parseFloat(copy.x).toFixed(2))
    copy.y = parseFloat(parseFloat(copy.y).toFixed(2))

    return copy
  }

  const savedTargets = targetsToCopy.map(target => copyTarget(target))
  const fixedTargets = fixTargetReferences(savedTargets)
  return fixedTargets
}
// TODO think harder about what state belongs here
function serializeState(state) {
  return JSON.parse(JSON.stringify({
    applyingGlitch: state.applyingGlitch,
    currentVariablePage: state.currentVariablePage,
    disabledToolbarIcons: state.disabledToolbarIcons,
    draggingElement: state.draggingElement,
    draggingGlitchApplication: state.draggingGlitchApplication,
    draggingPosition: state.draggingPosition,
    glitchApplications: state.glitchApplications,
    addedParts: state.addedParts,
    highlightToolbarIcons: state.highlightToolbarIcons,
    hoverRotation: state.hoverRotation,
    hoveringTargetId: state.hoverRotation,
    inventoryFilter: state.inventoryFilter,
    isInventoryOpen: state.isInventoryOpen,
    pickingGlitch: state.pickingGlitch,
    pickingPart: state.pickingPart,
    movingSelectedTarget: state.movingSelectedTarget,
    resizingSelectedTarget: state.resizingSelectedSprite,
    rotatingSelectedTarget: state.rotatingSelectedTarget,
    resizingHandle: state.resizingHandle,
    movingSpriteOffset: state.movingSpriteOffset,
    selectedGlitchApplicationId: state.selectedGlitchApplicationId,
    selectedGlitchId: state.selectedGlitchId,
    selectedInventoryCategory: state.selectedInventoryCategory,
    selectedPartId: state.selectedPartId,
    selectedTargetId: state.selectedTargetId,
    selectedVariableId: state.selectedVariableId,
    toolbar: state.toolbar,
    rotationStartingDirection: state.rotationStartingDirection,
    settingSpriteVariable: false,
    currentSceneId: state.currentScene.metadata.id,
    hiddenTargets: state.hiddenTargets,
    levelCompleteMinimized: state.levelCompleteMinimized,
    gameOverMinimized: state.gameOverMinimized,
    levelComplete: state.levelComplete,
    gameOver: state.gameOver
  }))
}


function saveSnapshot(state) {
  const vmData = {}
  const stateData = serializeState(state)
  const { targets, threads } = state.vm.runtime
  // Most of the data we need to save is in our targets
  vmData.targets = copyTargets(targets)
  vmData.targetLayers = getTargetLayers(targets)
  vmData.executableTargets = state.vm.runtime.executableTargets.map(
    target => vmData.targets.find(t => t.id === target.id)
  )
  vmData.threads = copyThreads(threads, vmData.targets)
  vmData.cloneCounter = state.vm.runtime._cloneCounter
  vmData.currentMSecs = state.vm.runtime.currentMSecs
  vmData.effectsCache = { ...state.effectsCache }
  return {
    vmData,
    stateData
  }
}
module.exports = { saveSnapshot }
