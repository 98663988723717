const { log } = console
const { getTargetAtClientPosition } = require('../shared_utils/coordinates.js')

module.exports = function store(state, emitter) {
  emitter.on('click-on-game', e => {
    log('click-on-game', e)
    state.showLevelsPanel = false
    if (e.target === state.canvas ) {
      let clickTarget = getTargetAtClientPosition(
        e.clientX,
        e.clientY,
        state.vm
      )
      if (state.isFullscreen && clickTarget){
        const v = state.projectMetadata.exposedVariables.find(v => v.spriteName === clickTarget.sprite.name)
        if (!v){
          clickTarget = null
        }
      }
      if (clickTarget) {
        if (state.selectedVariableId) {
          emitter.emit('set-selected-sprite-variable', clickTarget)
        } else {
          emitter.emit('select-target', clickTarget.id)
          emitter.emit('render')
        }
      } else {
        emitter.emit('deselect-target')
      }
    }
  })

  emitter.on('select-target', targetId => {
    log('select-target', targetId)
    state.currentVariablePage = 0

    state.selectedGlitchApplicationId = null
    state.selectedGlitchId = null
    state.selectedPartId = null

    if (targetId) {
      state.selectedTargetId = targetId
      state.currentVariablePage = 0
      state.selectedVariableId = null
      emitter.emit('render')
    } else {
      emitter.emit('deselect-target')
    }
  })

  emitter.on('deselect-target', () => {
    log('deselect-target')
    state.selectedGlitchApplicationId = null
    state.selectedVariableId = null
    state.settingSpriteVariable = false
    state.selectedTargetId = null
    emitter.emit('render')
  })
}
