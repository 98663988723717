const { log } = console
const { findTargetById } = require('../shared_utils/find-target.js')
const GHOST_FOR_HIDDEN_SPRITES = 85
module.exports = function store(state, emitter) {
  emitter.on('toggle-play', () => {
    log('toggle-play', state.isGamePlaying)
    if (state.levelComplete) {
      state.levelCompleteMinimized = true
    }
    if (state.gameOver) {
      state.gameOverMinimized = true
    }
    if (state.isGamePlaying) {
      emitter.emit('pause')
    } else {
      emitter.emit('play')
    }
  })
  emitter.on('play', () => {
    log('play')
    if ((state.levelComplete && !state.levelCompleteMinimized) ||
      (state.gameOver && !state.gameOverMinimized)) {
      return
    }

    if (state.hiddenTargets) {
      state.hiddenTargets.forEach(t => {
        const target = findTargetById(state.vm, t.id)
        if (target) {
          target.setVisible(false)
          target.setEffect('ghost', t.ghost)
        }
      })
    }
    state.hiddenTargets = []
    if (state.glitchEditorOpen) {
      emitter.emit('close-glitch-editor')
    }
    state.wasGamePlaying = false
    state.vm.runtime.updateCurrentMSecs()
    const msDelta = state.vm.runtime.currentMSecs - state.msAtPause
    state.vm.runtime.threads.forEach(t => {
      t.stackFrames.forEach(f => {
        if (f.executionContext && f.executionContext.timer) {
          f.executionContext.timer.startTime += msDelta
        }
      })
    })
    state.vm.runtime.ioDevices.clock._projectTimer.startTime += msDelta

    state.isGamePlaying = true
    state.isInventoryOpen = false
    state.isMenuOpen = false
    //TODO: this setTimeout is sketchy
    if (state.currentHistoryItem === null) {
      setTimeout(() => {
        emitter.emit('push-history', 'play-game')
        emitter.emit('start-vm')
        emitter.emit('render')
      }, 1)
    } else {
      state.getHistory().push(state.currentHistoryItem)
      emitter.emit('clear-future-stack')
      emitter.emit('start-vm')
      emitter.emit('render')
    }


  })
  emitter.on('pause', () => {
    log('pause')
    if (state.isGamePlaying) {
      if (!state.isFullscreen) {
        let hiddenTargets = []
        if (!state.isFullscreen) {
          hiddenTargets = state.vm.runtime.targets.filter(t => !t.visible)
        }
        state.hiddenTargets = hiddenTargets.map(t => ({
          id: t.id,
          ghost: t.effects.ghost
        }))
        hiddenTargets.forEach(t => {
          t.setVisible(true)
          t.ghost = t.effects.ghost
          t.setEffect('ghost', GHOST_FOR_HIDDEN_SPRITES)
        })
      }

        state.vm.renderer.draw()
        state.msAtPause = state.vm.runtime.currentMSecs
        state.isGamePlaying = false
        emitter.emit('stop-vm')
      }
      emitter.emit('render')
    })
}
