const GameScreenElement = require('../elements/gamescreen.js')
const SpriteSelector = require('./spriteselector.js')
const SpriteHighlights = require('./spritehighlights.js')
const GlitchWires = require('./glitch-wires.js')
const GlitchEditor = require('../nanocomponents/glitch-editor.js')
const NextLevelMessage = require('./next-level-message.js')
const html = require('choo/html')
const YouLoseMessage = require('./you-lose-message.js')
const Scoreboards = require('./scoreboards.js')
module.exports = function GameScreen (state, emit) {
  let overlayMessage = null
  if (state.levelComplete) {
    overlayMessage = NextLevelMessage(state, emit)
  } else if (state.gameOver) {
    overlayMessage = YouLoseMessage(state, emit)
  }
  let onMouseMove = null
  let onMouseUp = null
  let onMouseDown = null
  let onMouseLeave = null
  let children = [] 
  const spriteSelector = SpriteSelector(state, emit)
  if (state.isFullscreen) {
    children = [state.canvas, Scoreboards(state, emit), spriteSelector]
    onMouseDown = e => {
      emit('click-on-game', e)
      emit('play-mouse-down', e)
    }
    onMouseUp = e => emit('play-mouse-up', e)
    onMouseMove = e => emit('play-mouse-move', e)
    const gamescreen = GameScreenElement(
      { fullscreen: true, children, onMouseMove, onMouseUp, onMouseLeave, onMouseDown}
    )
    return html`
    ${overlayMessage}
    ${gamescreen}`
  }
 
  const spriteHighlights = SpriteHighlights(state, emit)
  const glitchWires = GlitchWires(state, emit)
  
  if (state.glitchEditorOpen) {
    children = state.cache(GlitchEditor, 'glitch-editor').render()
  } else {
    children = [
      state.canvas,
      Scoreboards(state, emit),
      spriteSelector,
      glitchWires
    ].concat(spriteHighlights)
  }

  if (state.applyingGlitch) {
    onMouseMove = e => emit('highlight-hover-target', e)
    onMouseUp = () => emit('end-applying-glitch')
  } else if (state.movingSelectedTarget) {
    onMouseMove = e => emit('move-selected-target', e)
  } else if (state.resizingSelectedTarget) {
    onMouseMove = e => emit('resize-selected-target', e)
  } else if (state.rotatingSelectedTarget) {
    onMouseMove = e => emit('rotate-selected-target', e)
  } else if (state.settingSpriteVariable || state.draggingSpriteVariable) {
    onMouseMove = e => {
      emit('highlight-hover-target', e)
      emit('set-wire-target', e)
    }
    onMouseDown = e => emit('set-selected-sprite-variable', e)
    //    onMouseUp = onMouseDown
  } else if (state.draggingGlitchApplication) {
    onMouseMove = e => emit('highlight-hover-target', e)
    onMouseUp = e => emit('end-dragging-glitch-application', e)
  } else if (state.importingPart) {
    onMouseUp = e => emit('drop-part-on-game', e)
  } else {
    onMouseDown = e => {
      emit('click-on-game', e)
      emit('play-mouse-down', e)
    }
    onMouseUp = e => emit('play-mouse-up', e)
    onMouseMove = e => emit('play-mouse-move', e)
  }

  onMouseLeave = e => {
    emit('clear-hovering-target')
    if (state.movingSelectedTarget) {
      emit('remove-target-from-play-area', e)
    }
    if (state.draggingGlitchApplication) {
      emit('drag-glitch-off-play-area')
    }
  }
  const gameScreen = GameScreenElement(
    { fullscreen: state.isFullscreen, children, onMouseMove, onMouseUp, onMouseLeave, onMouseDown}
  )

  return html`
  ${overlayMessage}
  ${gameScreen}
  `
}
