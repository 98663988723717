const { log } = console
const {getNewSceneId} = require('./utils/import-export-helpers.js')
module.exports = function store (state, emitter) {
  emitter.on('toggle-display-levels', () => {
        state.showLevelsPanel = !state.showLevelsPanel
        emitter.emit('render')
  })
  emitter.on('show-levels', () => {
    state.showLevelsPanel = true
    emitter.emit('render')
})
  emitter.on('stop-renaming-scene', () => {
    log('stop-renaming-scene')
    state.renamingScene = false
    emitter.emit('render')
  })
  emitter.on('rename-scene', sceneId => {
    log('rename-scene', sceneId)
    state.renamingScene = true
    emitter.emit('render')
  })
  emitter.on('update-scene-name', newName => {
    log('update-scene-name', newName)
    state.currentScene.metadata.id = newName
    emitter.emit('render')
  })
  emitter.on('create-new-scene', sceneId => {
    log('create-new-scene', sceneId)
  })

  emitter.on('load-scene-by-id', sceneId => {
    log('load-scene-by-id', sceneId)
    const scene = state.project.scenes.find(s => s.metadata.id.toLowerCase() === sceneId.toLowerCase())
    if (scene){
      emitter.emit('load-scene', state.project.scenes.indexOf(scene))
    }
    
  })

  emitter.on('start-moving-scene', sceneId => {
    log('start-moving-scene')
    state.draggingSceneId = sceneId
    emitter.emit('render')
  })
  emitter.on('swap-scenes', (id1, id2) => {
    log('swap-scenes', id1, id2)
    const scene1 = state.project.scenes.find(s => s.metadata.id === id1)
    const scene2 = state.project.scenes.find(s => s.metadata.id === id2)
    const index1 = state.project.scenes.indexOf(scene1)
    const index2 = state.project.scenes.indexOf(scene2)
    state.project.scenes[index1] = scene2
    state.project.scenes[index2] = scene1
    emitter.emit('render')
  })
  emitter.on('delete-scene', sceneId => {
    log('delete-scene', sceneId)
    state.project.scenes = state.project.scenes.filter(s => s.metadata.id !== sceneId)
    emitter.emit('render')
  })

  emitter.on('duplicate-scene', sceneId => {
    log('duplicate-scene', sceneId)
    const scene = state.project.scenes.find(s => s.metadata.id === sceneId)
    const newScene = {...scene}
    newScene.metadata = {...scene.metadata}
    newScene.metadata.id = getNewSceneId(newScene, state.project.scenes)
    state.project.scenes.push(newScene)
    emitter.emit('push-history', 'new-scene')
    state.currentScene = newScene
    emitter.emit('render')
  })
}
