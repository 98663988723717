const { vm } = require("./initial-state")
const target = require("./target")
const { addPartToVM } = require("./utils/add-part")
const { copyBlocks } = require("./utils/apply-glitch")
const { updateSpriteList } = require("./utils/arcade-vm-hooks")
const uuid = require('./utils/uuid.js')
const { log } = console


module.exports = function store(state, emitter) {
  // add a listener so that if the user presses shift-r at the same time, we emit the refresh-glitches event
  document.addEventListener('keydown', (e) => {
    if ((e.key === 'r' || e.key == 'R') && e.shiftKey) {
      emitter.emit('refresh-glitches')
    }
  } )

  function refreshGlitchesInCurrentScene() {
    // delete all of the variables in the stage sprite
    const stage = state.vm.runtime.targets.find(t => t.isStage)
    Object.values(stage.variables).forEach(v => {
      stage.deleteVariable(v.id)
    })
    state.variables = {}

    state.sceneIsLoading = true
    emitter.emit('pause')
    const partsPromises = []

    const glitchesToAdd = state.glitchApplications.map(gA => {
      return {
        glitchId: gA.glitchId,
        spriteName: state.vm.runtime.targets.find(t => t.id === gA.targetId).sprite.name
      }
    }
    )
    state.addedParts.forEach(p => {
      const part = state.availableParts.find(p1 => p1.id == p.partId)
      const target = state.vm.runtime.targets.find(t => t.isOriginal && t.sprite.name === p.spriteName)
      const targetVariables = JSON.parse(JSON.stringify(target.variables))
      const { x, y, direction, size, visible, effects } = target
      const name = target.sprite.name
      state.selectedPartId = p.partId
      emitter.emit('delete-sprite', target.id)
      partsPromises.push(addPartToVM(part, state.vm, { coords: { x, y } }).then(result => {
        result.addedPartEntry.spriteName = name
        state.addedParts.push(result.addedPartEntry)
        part.exposedVariables.forEach(v => {
          result.target.lookupOrCreateVariable(uuid(), v.variableName).value = v.value
        })
        result.target.setVisible(visible)
        result.target.setDirection(direction)
        result.target.setSize(size)
        Object.keys(effects).forEach(effect => {
          result.target.setEffect(effect, effects[effect])
        })
        state.vm.renameSprite(result.target.id, name)
        return Promise.resolve({
          part: result,
          variables: targetVariables
        })
      }))
    })
    return Promise.all(partsPromises).then(partsResults => {

      glitchesToAdd.forEach(g => {
        const target = state.vm.runtime.targets.find(t => t.sprite.name === g.spriteName)
        emitter.emit('apply-glitch', g.glitchId, target.id)
      })
      updateSpriteList(state.vm)

      partsResults.forEach(result => {
        Object.values(result.variables).forEach(variable => {
          const partVariable = Object.values(result.part.target.variables).find(v => v.name === variable.name)
          if (partVariable) {
            partVariable.value = variable.value
          } else {
            log("result.part.target", result.part.target)
            log("didn't find", variable.name, "for sprite", result.part.target.sprite.name)
          }
        })
      })
      state.sceneIsLoading = false
      //check if the stage sprite has any variables that have the same name as variables in other sprites and delete those variables
      const stage = state.vm.runtime.targets.find(t => t.isStage)
      const stageVariables = Object.values(stage.variables)
      const sprites = state.vm.runtime.targets.filter(t => !t.isStage)
      sprites.forEach(sprite => {
        const spriteVariables = Object.values(sprite.variables)
        spriteVariables.forEach(spriteVariable => {
          const stageVariable = stageVariables.find(v => v.name === spriteVariable.name)
          if (stageVariable) {
            stage.deleteVariable(stageVariable.id)
          }
        })
      })

      return Promise.resolve()
    })
  }

  emitter.on('refresh-glitches', () => {
    log('refresh-glitches')
    refreshGlitchesInCurrentScene().then(() => {
      emitter.emit('game-step')
      state.histories[state.currentSceneId] = []
      emitter.emit('push-history', 'refresh')
    })
  })

  emitter.on('refresh-project', () => {
    state.isGamePlaying = false
    state.wasGamePlaying = false
    const delay = 3000
    emitter.emit('pause')
    const scenes = state.project.scenes.length
    emitter.emit('refresh-glitches')
    let loads = 0

    function tryToLoadScene(sceneId) {
      if (!state.isSceneLoading) {
        emitter.emit('load-scene', sceneId)
        loads++
        return true
      } else {
        return false
      }
    }
    let sceneToLoad = 1
    const interval = setInterval(() => {
      if (sceneToLoad >= state.project.scenes.length) {
        emitter.emit('load-scene', 0)
        setTimeout(() => emitter.emit('export-project'), delay)
        clearInterval(interval)
      } else {
        let loaded = tryToLoadScene(sceneToLoad)
        if (loaded) {
          sceneToLoad++
          setTimeout(() => emitter.emit('refresh-glitches'), delay)

        }
      }
    }, delay*2)

  })

  function isVariableUsedInTarget(variable, target) {
    const varId = variable.id
    const varName = variable.name
    const blocks = target.sprite.blocks._blocks;
    for (const blockId in blocks) {
      let varOrListField = null;
      if (blocks[blockId].fields.VARIABLE) {
        varOrListField = blocks[blockId].fields.VARIABLE;
      } else if (blocks[blockId].fields.LIST) {
        varOrListField = blocks[blockId].fields.LIST;
      }
      if (varOrListField) {
        const currFieldId = varOrListField.id;
        if (varId === currFieldId) {
          return true
        }
      }
      if (blocks[blockId].fields.PROPERTY &&
        blocks[blockId].fields.PROPERTY.contains(varName)) {
        return true
      }
    }
    return false
  }

  function isGlobalVarUsed(varId, targets) {
    for (let i = 0; i < targets.length; i++) {
      if (isVariableUsedInTarget(varId, targets[i])) {
        return true
      }
    }
    return false
  }



}