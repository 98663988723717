module.exports = {
  "id": "time warp",
  "title": "time warp",
  "icon": "timewarp",
  "color": "pink",
  "description": "Speed up or slow down the selected sprite",
  "category": "magic",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "time warp amount",
      "input": {
        "type": "slider",
        "options": [],
        "min": "-3",
        "max": 3,
        "step": "0.1"
      },
      "value": "1"
    }
  ],
  "info": [
    {
      "text": "Speed up or slow down the selected sprite",
      "image": "provision/glitches/time warp/time warp.gif"
    }
  ],
  "codeImage": "provision/glitches/time warp/time warp.png",
  "code": null,
  "zip": null,
  "sb3": null
};