function isBlockInGlitch (blockId, glitchApplications) {
  for (let i = 0; i < glitchApplications.length; i++) {
    const g = glitchApplications[i]
    if (g.targetBlockIds.includes(blockId)
    || g.otherSpritesBlockIds.includes(blockId)) {
      return true
    }
  }
  return false
}

function getNonGlitchBlocks (target, glitchApplications) {
  const blocksArray = Object.values(target.blocks._blocks)
  return blocksArray.filter(
    b => isBlockInGlitch(b.id, glitchApplications) === false
  )
}

function getGlitchesAppliedToTarget (target, glitchApplications) {
  return glitchApplications.filter(
    g => g.targetId === target.sprite.clones[0].id
  ).map(g => g.glitchId)
}

function startGreenFlags (target) {
  Object.values(target.blocks._blocks).forEach(block => {
    if (block.opcode === 'event_whenflagclicked') {
      target.runtime._pushThread(block.id, target)
    }
  })
}
module.exports = {
  isBlockInGlitch,
  getNonGlitchBlocks,
  getGlitchesAppliedToTarget,
  startGreenFlags
}
