module.exports = {
  "id": "retry",
  "color": "blue",
  "title": "retry",
  "icon": "tombstone",
  "description": "",
  "category": "rules",
  "exposedVariables": [
    {
      "variableName": "respawn sprite",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite"
      },
      "value": "none"
    },
    {
      "variableName": "Starting Velocity X",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "-10",
        "max": "10",
        "step": 0.01
      },
      "value": "0"
    },
    {
      "variableName": "Starting Velocity Y",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "-10",
        "max": "10",
        "step": 0.01
      },
      "value": "0"
    },
    {
      "variableName": "invulnerable?",
      "spriteName": "$glitch_target",
      "input": {
        "type": "boolean"
      },
      "value": "false"
    },
    {
      "variableName": "is hazard?",
      "spriteName": "$other_sprites",
      "input": {
        "type": "boolean"
      },
      "value": "false"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "change lives for player",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "player 1",
            "label": "player 1"
          },
          {
            "value": "player 2",
            "label": "player 2"
          },
          {
            "value": "reset level",
            "label": "reset level"
          },
          {
            "value": "none",
            "label": "none"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "none"
    }
  ],
  "info": [
    {
      "text": "Disappear and reappear at the retry point after touching a 'hazard' sprite.",
      "image": "provision/glitches/retry/retry.gif"
    }
  ],
  "unused_vars": [
    {
      "variableName": "health",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "10",
        "step": "1"
      }
    },
    {
      "variableName": "damage",
      "spriteName": "$other_sprites",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "10",
        "step": "1"
      }
    }
  ],
  "codeImage": "provision/glitches/retry/retry.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/retry/retry.svg"
};