module.exports = {
  "id": "glitch_info_template",
  "title": "glitch_info_template",
  "category": "other",
  "thumbnail": "/provision/projects/glitch_info_template/glitch_info_template.png",
  "toolbar":{
    "glitches":[],
    "parts":[]
  },
  "scenes": [{
    "id": "main",
    "metadata": "main.json",
    "sb3": "glitch_info_template.sb3"
  }],
  "levels": [
    "main"    
  ]
}
;