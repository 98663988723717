const { log } = console
const gameStep = require('./utils/game-step.js')
const { handleMessages } = require('./utils/arcade-vm-hooks')
const uuid = require('./utils/uuid')
const {
  sanitizeSelections,
  sanitizeVariables
} = require('./utils/sanitize-state.js')

const VM_INTERVAL = 33

module.exports = function scratchvm(state, emitter) {


  function addOrRemovePhysics() {
    state.vm.runtime.targets.filter(t => t.isOriginal && !t.isStage).forEach(target => {
      const kickStrengthVar = Object.values(target.variables).find(v => v.name === 'kick strength')
      const kickerGlitch = state.glitchApplications.find(g => (g.glitchId === 'kicker' || g.glitchId === 'flippify') && g.targetId === target.id)

      if (!kickerGlitch && kickStrengthVar) {
        target.sprite.clones.forEach(c => {
          const cloneVar = Object.values(c.variables).find(v => v.name === 'kick strength')
          if (cloneVar) {
            cloneVar.value = 0
          }
        })
      }
      const staticVar = Object.values(target.variables).find(v => v.name === 'is static?')
      let isStatic = 'true'
      if (staticVar) {
        isStatic = staticVar.value
      }
      const physicsGlitch = state.glitchApplications.find(g => g.glitchId === 'builtin_physics' && g.targetId === target.id)
      if (isStatic === 'true' || isStatic === true) {
        if (physicsGlitch) {
          emitter.emit('remove-glitch', physicsGlitch.id)
        }
        const velocityX = target.lookupOrCreateVariable(uuid(), 'velocity x')
        const velocityY = target.lookupOrCreateVariable(uuid(), 'velocity y')

        target.setXY(parseFloat(target.x) + parseFloat(velocityX.value), parseFloat(target.y) + parseFloat(velocityY.value))
        velocityX.value = 0
        velocityY.value = 0
      } else {
        if (!physicsGlitch) {
          emitter.emit('apply-glitch', 'builtin_physics', target.id)
        }
      }
    })
  }
  function playGame() {
    emitter.emit('play')
    window.removeEventListener('focus', playGame)
  }

  emitter.on('start-vm', () => {
    log('start-vm')
    sanitizeSelections(state)

    clearInterval(state.vmInterval)
    state.vm.runtime._cloneCounter = -10000
    state.vmInterval = setInterval(() => {
      if (!document.hasFocus() && state.isGamePlaying) {
        //  window.addEventListener('focus', playGame)
        if (state.isGamePlaying) {
          //       emitter.emit('pause')
        }
      }
      emitter.emit('game-step')
    }, VM_INTERVAL)
    sanitizeVariables(state)
    const node = state.vm.runtime.audioEngine.getInputNode()
    const volPercent = state.masterVolume / 100
    const gain = volPercent * volPercent
    node.gain.setValueAtTime(gain, 0)
    emitter.emit('render')
  })

  emitter.on('stop-vm', () => {
    log('stop-vm')
    if (state.vm && state.vmInterval) {

      clearInterval(state.vmInterval)
      const node = state.vm.runtime.audioEngine.getInputNode()
      node.gain.setValueAtTime(0, 0)
    }
  })

  emitter.on('game-step', () => {

    addOrRemovePhysics()
    setGhostToMax(state, 99)
//    setHitboxCostume(state)
    state.vm = gameStep(state.vm, state.glitchApplications)
    handleMessages(state.vm, emitter)
    sanitizeSelections(state)
    handleAccelerateGlitch(state)
    checkForWin(state, emitter)
    handleGravityDrag(state)
    emitter.emit('render')
    state.previousPositions = {}
    state.vm.runtime.targets.forEach(t => {
      state.previousPositions[t.id] = { x: t.x, y: t.y }
    })
  })
}

function setHitboxCostume(state) {
  state.vm.runtime.targets.filter(t => !t.isStage).forEach(target => {
    let variable = target.lookupOrCreateVariable(uuid(), 'hitbox costume')
    if (      variable.value === 0) {
      variable.value = target.getCurrentCostume().name
    }
  })
}

function setGhostToMax(state, max) {
  state.vm.runtime.targets.filter(t => !t.isStage).forEach(target => {
    if (target.effects.ghost > max) {
      target.setEffect('ghost', max)
    }
  })
}


function handleGravityDrag(state) {
  const gravityApplications = state.glitchApplications.filter(g => g.glitchId === 'gravity')
  gravityApplications.forEach(application => {
    const original = state.vm.runtime.targets.find(
      t => t.id === application.targetId)
    original.sprite.clones.forEach(clone => {
      const currentGroundSpriteVariable = Object.values(clone.variables).find(
        v => v.name === 'current ground sprite')
      const possibleGroundSprites = state.vm.runtime.targets.filter(
        t => t.sprite.name === currentGroundSpriteVariable.value)
      let groundSprite = null
      possibleGroundSprites.forEach(target => {
        const touching = state.vm.runtime.renderer.isTouchingDrawables(clone.drawableID, [target.drawableID])
        if (touching) {
          groundSprite = target
        }
      })
      if (groundSprite && state.previousPositions && state.previousPositions[groundSprite.id]) {
        const previousPosition = state.previousPositions[groundSprite.id]
        const groundDelta = { x: groundSprite.x - previousPosition.x, y: groundSprite.y - previousPosition.y }
        clone.setXY(clone.x + groundDelta.x, clone.y + groundDelta.y)
      }
    })
  })
}


function checkForWin(state, emitter) {
  let winTargets = []
  state.vm.runtime.targets.filter(t => !t.isStage).forEach(target => {
    //check if target has 'collectible' or 'break in half' glitches applied
    const collectibleGlitch = state.glitchApplications.find(g => g.glitchId === 'collectible' && g.targetId === target.sprite.clones[0].id)
    const breakInHalfGlitch = state.glitchApplications.find(g => g.glitchId === 'break in half' && g.targetId === target.sprite.clones[0].id)
    let varName = null
    if (collectibleGlitch) {
      varName = 'collect all to win?'
    } else if (breakInHalfGlitch) {
      varName = 'destroy all to win?'
    }

    if (varName) {
      const winVar = Object.values(target.variables).find(
        v => v.name === varName)
      if (winVar && (winVar.value === 'true' || winVar.value === true)) {
        winTargets.push(target)
      }
    }
  })
  if (winTargets.length > 0) {
    let allTargetsHidden = true
    winTargets.forEach(target => {
      if (target.visible) {
        allTargetsHidden = false
      }
    })
    if (allTargetsHidden){
      emitter.emit('next-level')
    } else {
      state.levelComplete = false
      state.levelCompleteMinimized = false
    }
  }
}

function handleAccelerateGlitch(state) {
  const accelerateApplications = state.glitchApplications.filter(g => g.glitchId === 'accelerate')
  let accelerateTargets = []
  accelerateApplications.forEach(application => {
    const target = state.vm.runtime.targets.find(
      t => t.id === application.targetId)
    accelerateTargets = accelerateTargets.concat(target.sprite.clones)
  })
  const nonStaticTargets = []
  state.vm.runtime.targets.filter(t => !t.isStage).forEach(target => {
    const isStatic = Object.values(target.variables).find(v => v.name === 'is static?')
    if (isStatic && (isStatic.value === 'false' || isStatic.value === false)) {
      nonStaticTargets.push(target)
    }
  })

  accelerateTargets.forEach(target => {
    const candidateTargets = nonStaticTargets.filter(t => t.id !== target.id)
    const strengthVar = Object.values(target.variables).find(v => v.name === 'acceleration strength')
    let strength = 0
    if (strengthVar) {
      strength = strengthVar.value
    }
    const accelerationDirectionVar = Object.values(target.variables).find(v => v.name === 'acceleration direction')
    let accelerationDirection = 'mine'
    if (accelerationDirectionVar) {
      accelerationDirection = accelerationDirectionVar.value
    }
    const touching = whichTargetsTouching(target, candidateTargets)
    touching.forEach(touched => {
      let accelerationVector = { x: 0, y: 0 }
      const xVelocityVar = Object.values(touched.variables).find(v => v.name === 'velocity x')
      const yVelocityVar = Object.values(touched.variables).find(v => v.name === 'velocity y')
      if (accelerationDirection === 'touching') {
        if (xVelocityVar && yVelocityVar) {
          accelerationVector = { x: xVelocityVar.value, y: yVelocityVar.value }
          const magnitude = Math.sqrt(accelerationVector.x * accelerationVector.x + accelerationVector.y * accelerationVector.y)
          accelerationVector.x = accelerationVector.x / magnitude
          accelerationVector.y = accelerationVector.y / magnitude
        }
      } else {
        accelerationVector.x = Math.sin(target.direction * Math.PI / 180)
        accelerationVector.y = Math.cos(target.direction * Math.PI / 180)
      }
      accelerationVector.x = accelerationVector.x * strength
      accelerationVector.y = accelerationVector.y * strength
      if (xVelocityVar && yVelocityVar) {
        xVelocityVar.value = xVelocityVar.value + accelerationVector.x
        yVelocityVar.value = yVelocityVar.value + accelerationVector.y
      }
    })
  })
}

function whichTargetsTouching(testTarget, candidateTargets) {
  const touchingTargets = []
  candidateTargets.forEach(candidate => {
    const touching = testTarget.runtime.renderer.isTouchingDrawables(testTarget.drawableID, [candidate.drawableID])
    if (touching) {
      touchingTargets.push(candidate)
    }
  })
  return touchingTargets
}
