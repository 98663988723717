/**
 * Re-maps a number from one range to another.
 * From: https://www.arduino.cc/reference/en/language/functions/math/map/
 * @module store/utils/map
 *
 * @param {number} x - value to map
 * @param {number} inMin - the lower bound of the value’s current range
 * @param {number} inMax - the lower bound of the value’s current range
 * @param {number} outMin - the lower bound of the value’s target range.
 * @param {number} outMax - the upper bound of the value’s target range.
 * @retursn {number} - the mapped value
 */
module.exports = function map (x, inMin, inMax, outMin, outMax) {
  return ((x - inMin) * (outMax - outMin) / (inMax - inMin)) + outMin
}
