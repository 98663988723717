/**
 * Creates a new scratch-vm
 * @module store/utils/get-vm
 */

const { getAssetUrl, getProjectUrl } = require('./scratch/scratch-website-api.js')
const VideoProvider = require('./scratch/video/video-provider.js').default
const log = { console }

/**
 * @param {object} canvas - an HTML canvas element
 * @returns {object} - a scratch-vm bound to this canvas
 */
module.exports = function getVM (canvas) {
  // Scratch libraries are being loaded as an external to avoid rebundling
  const {
    VirtualMachine,
    ScratchStorage,
    ScratchRender,
    ScratchSVGRenderer,
    ScratchAudio
  } = window


  const vm = new VirtualMachine()
  vm.runtime.captureException = function captureException (e) {
    log('error', e)
  }
  // Storage setup
  const storage = new ScratchStorage()
  const {AssetType} = storage
  // Offline only (uncomment next lines to load from internet)
  storage.addWebStore([AssetType.Project], getProjectUrl)
  storage.addWebStore([
    AssetType.ImageVector,
    AssetType.ImageBitmap,
    AssetType.Sound
  ], getAssetUrl)
  vm.attachStorage(storage)
  // Rendering engines
  const render = new ScratchRender(canvas)
  vm.attachRenderer(render)
  vm.attachV2BitmapAdapter(new ScratchSVGRenderer.BitmapAdapter())
  vm.attachV2SVGAdapter(new ScratchSVGRenderer.SVGRenderer())
  // Start audio engine
  const audio = new ScratchAudio()
  vm.attachAudioEngine(audio)

  vm.setVideoProvider(new VideoProvider())

  // Sounds a good idea
  vm.setCompatibilityMode(true)
  return vm
}

