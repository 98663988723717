const html = require('choo/html')
const uuid = require('../../store/utils/uuid.js')

const displayNames = {
  'player 1: score': 'P1 Score',
  'player 2: score': 'P2 Score',
  'player 1: lives': 'P1 Lives',
  'player 2: lives': 'P2 Lives',
  'timer': 'Timer',
  'level #': 'Level'
}
const { getNormalizedTargetBounds } = require('../../shared_utils/coordinates.js')
module.exports = function Scoreboards(state, emit) {
  const scoreboardSpriteNames = state.addedParts.filter(p => p.partId === 'scoreboard').map(p => p.spriteName)
  const scoreboardSprites = state.vm.runtime.targets.filter(
    t => scoreboardSpriteNames.includes(t.sprite.name) && t.isOriginal)
  const stage = state.vm.runtime.targets.find(t => t.isStage)
  return scoreboardSprites.map(sprite => Scoreboard(sprite, stage, state.canvas))
}


function Scoreboard(sprite, stage, canvas) {
  const displayWhat = Object.values(sprite.variables).find(v => v.name === 'variable to display')
  if (displayWhat) {
    const scoreVariable = stage.lookupOrCreateVariable(uuid(), displayWhat.value)
    let scoreValue = scoreVariable.value
    const bounds = getNormalizedTargetBounds(sprite, canvas)
    const displayName = displayNames[displayWhat.value]
    scoreValue = Math.round(scoreValue)
    const numDigits = scoreValue.toString().length
    if (numDigits > 2 && sprite.currentCostume === 0) {
      sprite.setCostume(1)
      sprite.runtime.renderer.draw()
    } else if (numDigits <= 2 && sprite.currentCostume === 1){
      sprite.setCostume(0)
      sprite.runtime.renderer.draw()
    }
    scoreValue.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    const hue = sprite.effects.color*1.8 + 200
    let lightness = 50
    return html`
    <div 
      class="scoreboard" 
      id="${sprite.id}"
      style="position:absolute; 
        top:${bounds.top}%;
        left:${bounds.left}%;
        pointer-events:none;"
      >
        <div class="scoreboard-title"
        style = "position:relative;
        top: ${-sprite.size*.2}rem;
        left: ${sprite.size*.05}rem; 
        font-size: ${sprite.size*.2}rem;
        color: hsl(${hue}deg 100% ${lightness}%);"
        >${displayName}</div>
        <div class="scoreboard-score"
        style = "position:relative;
        text-align: center;
        top: ${sprite.size*-.01}rem;
        left: ${sprite.size*.12}rem; 
        font-size: ${sprite.size*.57}rem;
        color: hsl(${hue}deg 100% ${lightness}%);"
      >${scoreValue}</div>  
    </div>`
  }
}