module.exports = {
  "id": "animate",
  "color": "green",
  "title": "animate",
  "icon": "toss",
  "category": "magic",
  "info": [
    {
      "text": "Make a sprite bob up and down or rotate",
      "image": "provision/glitches/animate/animate.gif"
    }
  ],
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "up/down amount",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "50",
        "step": "1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "up/down speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "50",
        "step": "1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "rotation amount",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "100",
        "step": "1"
      },
      "value": "10"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "rotation speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "50",
        "step": "1"
      },
      "value": "10"
    }
  ],
  "codeImage": "provision/glitches/animate/animate.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/animate/animate.svg"
};