const { log } = console
const { getTargetAtClientPosition } = require('../shared_utils/coordinates.js')

module.exports = function store (state, emitter) {
  emitter.on('highlight-hover-target', e => {
    log('highlight-hover-target')
    state.draggingGlitchOutOfPlayArea = false
    const hoverTarget = getTargetAtClientPosition(
      e.clientX,
      e.clientY,
      state.vm
    )
    if (hoverTarget) {
      state.hoveringTargetId = hoverTarget.id
    } else {
      state.hoveringTargetId = null
    }
    emitter.emit('render')
  })
  emitter.on('set-wire-target', e => {
    log('set-wire-target')
    state.glitchWireTargetPosition = {x: e.x, y: e.y}
    emitter.emit('render')
  })
}
