const choo = require('choo')
const bendingView = require('./views/bend.js')
const playingView = require('./views/play.js')
const browserView = require('./views/scaffold-project-browser.js')
const store = require('./store.js')
const devtools = require('../choo-devtools-patched')
const app = choo({ hash: true })
const filteredEvents = ['play-mouse-move', 'game-step']
function filterFunction(eventName, data, timing) {
  if (filteredEvents.includes(eventName)) {
    return false
  }
  return true
}
window.devtools = devtools({ filter: filterFunction })
app.use(window.devtools)
app.use(store)


if (window.location.host !== 'arcade.makeymakey.com') {
  app.route('/bend/:project_id', bendingView)
  app.route('/', browserView)
  app.route('/play/:project_id', playingView)
} else {
  app.route('/play/:project_id', playingView)
}


app.mount('#app')
