const { log } = console
const { findTargetById } = require('../shared_utils/find-target.js')
const uuid = require('./utils/uuid.js')

function getGlitchVariables(glitchApplication, fromTarget) {
  return glitchApplication.targetVarNames.map(v => {
    const varName = v.name
    const varType = v.type
    let variable
    if (varType === 'list') {
      variable = fromTarget.lookupOrCreateList(uuid(), varName)
    } else {
      variable = fromTarget.lookupOrCreateVariable(uuid(), varName)
    }
    return { name: varName, value: variable.value, type: varType }
  })
}

module.exports = function store(state, emitter) {
  emitter.on('start-dragging-glitch-application', () => {
    log('start-dragging-glitch-application')
    state.draggingGlitchApplication = true
    state.draggingGlitchOutOfPlayArea = false
    emitter.emit('render')
  })
  emitter.on('copy-glitch-application', (
    fromTargetId,
    toTargetId,
    glitchApplicationId
  ) => {
    log(
      'copy-glitch-application',
      fromTargetId,
      toTargetId,
      glitchApplicationId
    )
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    const fromTarget = findTargetById(state.vm, fromTargetId)
    const toTarget = findTargetById(state.vm, toTargetId)
    const glitchVariables = getGlitchVariables(glitchApplication, fromTarget)
    emitter.emit('apply-glitch', glitchApplication.glitchId, toTarget.id)
    glitchVariables.forEach(glitchVar => {
      toTarget.sprite.clones.forEach(c => {
        let v
        if (glitchVar.type === 'list') {
          v = c.lookupOrCreateList(uuid(), glitchVar.name)
        } else {
          v = c.lookupOrCreateVariable(uuid(), glitchVar.name)
        }
        v.value = glitchVar.value
      })
    })
  })

  emitter.on('end-dragging-glitch-application', e => {
    log('end-dragging-glitch-application', e)
    const keepOriginal = e.shiftKey
    if (state.hoveringTargetId) {
      const glitchApplication = state.glitchApplications.find(
        g => g.id === state.selectedGlitchApplicationId
      )
      const { glitchId } = glitchApplication
      const fromTarget = findTargetById(state.vm, glitchApplication.targetId)
      const fromTargetId = glitchApplication.targetId
      const toTarget = state.vm.runtime.targets.find(
        t => t.id === state.hoveringTargetId
      )
      if (toTarget.sprite.clones[0].id !== fromTargetId) {
        const actionType =
          keepOriginal ? 'copy-glitch-sticker' : 'move-glitch-sticker'
        emitter.emit('push-history', actionType)
        emitter.emit(
          'copy-glitch-application',
          fromTarget.id,
          toTarget.id,
          glitchApplication.id
        )
        if (!keepOriginal) {
          emitter.emit('remove-glitch', glitchApplication.id)
        }
        emitter.emit('select-glitch-on-target', glitchId, toTarget.id)
      }
    }
  })
  emitter.on('drop-to-trash', () => {
    log('drop-to-trash')
    state.draggingGlitchSticker = false
    if (state.selectedGlitchApplicationId) {
      const glitchApplication = state.glitchApplications.find(
        g => g.id === state.selectedGlitchApplicationId
      )
      emitter.emit('push-history', 'remove-glitch-application')
      emitter.emit('remove-glitch', glitchApplication.id, state.selectedTargetId)
      state.selectedGlitchApplicationId = null
    }
  })
}
