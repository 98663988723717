const ChipElement = require('../elements/chip.js')
const ButtonIcon = require('../elements/buttonicon.js')

module.exports = function GameControls (state, emit) {
  
  const chip = ChipElement({
    children: [
      ButtonIcon({
        icon: 'rewind',
        disabled: state.getHistory().length === 0
          || state.isMenuOpen,
        onClick: () => emit('pop-history')
      }),
      ButtonIcon({
        icon: state.isGamePlaying ? 'pause' : 'play',
        onClick: () => emit('toggle-play')
      }),
      ButtonIcon({
        icon: 'fastforward',
        disabled: state.future.length === 0
        || state.isMenuOpen,
        onClick: () => emit('pop-future')
      })
    ]
  })
  return [chip,
    /*
    ,
    ChipElement({
      children: [
    ButtonIcon({
      icon: 'refresh',
      onClick: () => emit('reset-level')
    })]})
    */
  ]
}
