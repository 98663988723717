const { applyGlitch, removeGlitch } = require('./utils/apply-glitch.js')
const { findTargetById, findSpriteByName } = require('../shared_utils/find-target.js')
const { log } = console
const {sanitizeVariables} = require('./utils/sanitize-state.js')
module.exports = function store (state, emitter) {
  emitter.on('reapply-glitch', glitchApplicationId => {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === glitchApplicationId
    )
    emitter.emit('remove-glitch', glitchApplicationId)
    emitter.emit(
      'apply-glitch',
      glitchApplication.glitchId,
      glitchApplication.targetId
    )
  })
  emitter.on('apply-glitch', (glitchId, targetId) => {
    log('apply glitch', glitchId, targetId)
    const glitch = state.availableGlitches.find(
      g => g.id === glitchId
    )
    const target = state.vm.runtime.targets.find(
      t => t.id === targetId
    )
    const [original] = target.sprite.clones
    const previousApplication = state.glitchApplications.find(
      g => g.glitchId === glitchId
      && g.targetId === original.id
    )
    let glitchApplication
    if (previousApplication) {
      glitchApplication = previousApplication
    } else {
      glitchApplication = applyGlitch(glitch, original, state.vm)
      state.glitchApplications.push(glitchApplication)
    }
    sanitizeVariables(state)
  })

  emitter.on('remove-glitch', glitchApplicationId => {
    log('remove-glitch', glitchApplicationId)
    const glitchApplication = state.glitchApplications.find(
      g => g.id === glitchApplicationId
    )
    const target = state.vm.runtime.targets.find(
      t => t.id === glitchApplication.targetId
    )
    removeGlitch(target, state.vm, glitchApplication)
    state.glitchApplications = state.glitchApplications.filter(
      g => g !== glitchApplication
    )
    if (state.selectedGlitchApplicationId === glitchApplicationId){
      state.selectedGlitchApplicationId = null
    }
    emitter.emit('render')
  })

  emitter.on('start-applying-glitch', glitchId => {
    log('start-applying-glitch', glitchId)
    state.applyingGlitch = true
    emitter.emit('select-glitch', glitchId)
    emitter.emit('render')
  })

  emitter.on('end-applying-glitch', () => {
    log('end-applying-glitch')
    if (state.hoveringTargetId) {
      const glitch = state.availableGlitches.find(
        g => g.id === state.selectedGlitchId
      )
      const targetId = state.hoveringTargetId

      emitter.emit('push-history', 'glitch-application')
      emitter.emit('apply-glitch', glitch.id, targetId)
      emitter.emit('select-target', targetId)
      const glitchApplication =
        state.glitchApplications[state.glitchApplications.length - 1]
      emitter.emit('select-glitch-application', glitchApplication.id)
      state.hoveringTargetId = null
      const spriteVariables = glitch.exposedVariables.filter(
        v => v.input.type === 'sprite'
      )
      const target = findTargetById(state.vm, targetId)
      let varToPick = false
      spriteVariables.forEach(v => {
        const variable = Object.values(target.variables)
          .find(targetVar => targetVar.name === v.variableName)
        const matchingTarget = findSpriteByName(state.vm, variable.value)
        if (!matchingTarget) {
          varToPick = variable
        }
      })
      if (varToPick) {
        emitter.emit('start-setting-sprite-variable', varToPick.id)
      }
    }

    emitter.emit('deselect-glitch')
    emitter.emit('render')
  })
}
