const DisplayElement = require('../elements/display.js')
const InputText = require('../elements/inputtext.js')
const InputOption = require('../elements/inputoption.js')
const Panel = require('../elements/panel.js')
module.exports = function ScreenTitle(state, emit) {
  if (state.isFullscreen) {
    let text = state.projectMetadata.title

    if (state.selectedTargetId) {
      const target = state.vm.runtime.targets.find(
        t => t.id === state.selectedTargetId
      )
      text = target.sprite.name
    }
    const options = state.project.scenes.map((level, i) => ({
      label: "Level " + (i + 1),
      value: i
    }))

    const levelSelector = InputOption({
      size: 'big',
      type: 'fullwidth',
      color: 'purple',
      image: state.projectMetadata.thumbnail,
      onChange: e => {
        emit('load-scene', e, true)
        },
      selectedItem: state.project.scenes.indexOf(state.currentScene),
      options
    })
    return Panel({
      children: [levelSelector],
      size: 'tiny',
      noPadding: true,

    })
  }
  let { selectedGlitchId } = state
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    selectedGlitchId = glitchApplication.glitchId
  }
  if (selectedGlitchId) {
    const glitch = state.availableGlitches.find(
      g => g.id === selectedGlitchId
    )
    if (state.glitchEditorOpen
      && glitch.isRemix) {
      const text = InputText({
        value: glitch.title,
        onInput: e => {
          emit(
            'glitch-editor-set-glitch-title',
            e.target.value
          )
        }
      })
      text.style.display = 'block'
      return DisplayElement({
        size: 'big',
        type: 'fullwidth',
        color: glitch.color,
        icon: glitch.icon,
        text
      })
    }

    return DisplayElement({
      size: 'big',
      type: 'fullwidth',
      color: glitch.color,
      icon: glitch.icon,
      text: glitch.title
    })
  }
  if (state.selectedPartId) {
    const part = state.availableParts.find(
      g => g.id === state.selectedPartId
    )
    return DisplayElement({
      size: 'big',
      type: 'fullwidth',
      color: 'purple',
      image: part.image,
      text: part.title
    })
  }
  if (state.isInventoryOpen) {
    return null
  }
  if (state.selectedTargetId) {
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    return DisplayElement({
      size: 'big',
      type: 'fullwidth',
      color: 'purple',
      spriteImage: target.sprite.costumes[0].asset.encodeDataURI(),
      text: target.sprite.name
    })
  }
  return DisplayElement({
    size: 'big',
    type: 'fullwidth',
    color: 'purple',
    image: state.projectMetadata.thumbnail,
    text: state.projectMetadata.title,
    selected: state.showLevelsPanel,
    onClick: () => emit('toggle-display-levels')
  })
}
