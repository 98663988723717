var removeItems = require('remove-array-items').default
var scheduler = require('nanoscheduler')()
var nanologger = require('nanologger')
var _log = nanologger('choo')
var clone = require('clone')

var MAX_HISTORY_LENGTH = 150 // How many items we should keep around

module.exports = log

function log (state, emitter, app, localEmitter) {
  var shouldDebug = window.localStorage.logLevel === 'debug'
  var history = []
  var i = 0
  var shouldWarn = true

  localEmitter.on('debug', function (bool) {
    shouldDebug = bool
  })

  window.choo._history = history
  window.choo.history = showHistory

  Object.defineProperty(window.choo, 'log', { get: showHistory, set: noop })
  Object.defineProperty(window.choo, 'history', { get: showHistory, set: noop })

  emitter.on('*', function (name, data) {
    i += 1
    var entry = new Event(name, data, state)
    history.push(entry)
    scheduler.push(function () {
      var length = history.length
      if (length > MAX_HISTORY_LENGTH) {
        removeItems(history, 0, length - MAX_HISTORY_LENGTH)
      }
    })
  })

  function showHistory () {
    setTimeout(function () {
      console.table(history)
    }, 0)
    var events = i === 1 ? 'event' : 'events'
    var msg = i + ' ' + events + ' recorded, showing the last ' + MAX_HISTORY_LENGTH + '.'
    if (shouldDebug === false) {
      msg += ' Enable state capture by calling `choo.debug`.'
    } else {
      msg += ' Disable state capture by calling `choo.debug = false`.'
    }
    return msg
  }

  function Event (name, data, state) {
    this.name = name
    this.data = data === undefined ? '<no data>' : data
    this.state = shouldDebug
      ? tryClone(state)
      : '<disabled>'
  }

  function tryClone (state) {
    try {
      var _state = clone(state)
      if (!shouldWarn) shouldWarn = true
      return _state
    } catch (ex) {
      if (shouldWarn) {
        _log.warn('Could not clone your app state. Make sure to have a serializable state so it can be cloned')
        shouldWarn = false
      }
      return '<unserializable>'
    }
  }
}

function noop () {}
