const html = require('choo/html')

module.exports = function InputOption (args) {
  const {
    label,
    selectedItem = 0,
    options = [{ label: 'None', value: null }],
    disabled = false,
    selected = false,
    onChange = () => false
  } = args

  const index = Math.max(0, selectedItem)
  const disabledClass = disabled ? 'disabled' : ''
  const selectedClass = selected ? 'selected' : ''
  function change (n) {
    const newIndex = (index + options.length + n) % options.length
    onChange(newIndex)
  }
  return html`
    <div class="input input-option ${disabledClass}">
      <label>${label}</label>
      <div class="input-area ${selectedClass}">
        <span
          class="option-control"
          onclick=${() => change(-1)}
          >
          ${`<`}
        </span>
        ${options[index].label}
        <span
          class="option-control"
          onclick=${() => change(1)}
          >
          ${`>`}
        </span>
      </div>
    </div>
  `
}
