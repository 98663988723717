module.exports = {
    "id": "flying saw",
    "title": "flying saw",
    "image": "provision/parts/flying saw/flying saw.svg",
    "info": [
      {
        "text": "Add a flying saw to your game",
        "image": "provision/parts/flying saw/flying saw.gif"
      }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/flying saw/flying saw.png"
  };