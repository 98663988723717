module.exports = {
  "id": "lava",
  "title": "lava",
  "image": "provision/parts/lava/lava.svg",
  "info": [
    {
      "text": "Add lava to your game",
      "image": "provision/parts/lava/lava.gif"
    }
  ],
  "exposedVariables": [{
    "spriteName": "lava",
      "variableName": "lava animation speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 0.3,
        "step": "0.01"
      },
      "value": "0.1"
  }]
};