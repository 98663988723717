module.exports = {
  "id": "screenwrap",
  "color": "blue",
  "title": "screenwrap",
  "icon": "screenwrap",
  "description": "Wrap around the screen.",
  "category": "rules",
  "exposedVariables": [
    {
      "variableName": "X Minimum",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "-240",
        "max": "240",
        "value": 240,
        "step": "1"
      },
      "value": "-235"
    },
    {
      "variableName": "X Maximum",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "-240",
        "max": "240",
        "value": -240,
        "step": "1"
      },
      "value": "235"
    },
    {
      "variableName": "Y Minimum",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -180,
        "max": 180,
        "value": -180,
        "step": "1"
      },
      "value": "-175"
    },
    {
      "variableName": "Y Maximum",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -180,
        "max": 180,
        "value": 180,
        "step": "1"
      },
      "value": "175"
    }
  ],
  "info": [
    {
      "text": "Wrap around the screen.",
      "image": "provision/glitches/screenwrap/screenwrap.gif"
    }
  ],
  "codeImage": "provision/glitches/screenwrap/screenwrap.png",
  "code": null,
  "zip": null,
  "sb3": null
};