const { log } = console

module.exports = function store (state, emitter) {
  emitter.on('select-info-page', index => {
    log('select-info-page', index)
    state.selectedInfoPage = index
    emitter.emit('render')
  })

  emitter.on('toggle-glitch-category', categoryId => {
    log('toggle-glitch-category', categoryId)
    if (state.selectedGlitchCategories.includes(categoryId)) {
      state.selectedGlitchCategories =
        state.selectedGlitchCategories.filter(id => id !== categoryId)
    } else {
      state.selectedGlitchCategories.push(categoryId)
    }
    state.selectedGlitchCategories = [categoryId]
    emitter.emit('render')
  })
  emitter.on('toggle-inventory', () => {
    log('toggle-inventory')
    state.isMenuOpen = false
    state.isInventoryOpen = !state.isInventoryOpen
    if (state.isInventoryOpen) {
      state.wasGamePlaying = state.isGamePlaying
      emitter.emit('deselect-target')
      emitter.emit('pause')
    } else {
      state.selectedGlitchId = state.toolbar.glitches.find(g => g.id === state.selectedGlitchId)
      state.selectedPartId = state.toolbar.parts.find(p => p.id === state.selectedPartId)
    if (state.wasGamePlaying) {
        emitter.emit('play')
      }
    }
    emitter.emit('render')
  })

  emitter.on('select-inventory-category', category => {
    state.selectedInventoryCategory = category
    emitter.emit('render')
  })
  emitter.on('change-inventory-filter', value => {
    state.inventoryFilter = value
    emitter.emit('render')
  })
}
