const { log } = console

module.exports = function store (state, emitter) {
  emitter.on('swap-selected-glitch', glitchId => {
    log('swap-selected-glitch', glitchId)
    const index = state.toolbar.glitches.findIndex(id => id === glitchId)
    const selectedGlitchIndex = state.toolbar.glitches.findIndex(
      id => id === state.selectedGlitchId
    )
    const hoverGlitchId = state.toolbar.glitches[index]
    state.toolbar.glitches[index] = state.selectedGlitchId
    state.toolbar.glitches[selectedGlitchIndex] = hoverGlitchId
    emitter.emit('render')
  })

  emitter.on('swap-selected-part', partId => {
    log('swap-selected-part', partId)
    const index = state.toolbar.parts.findIndex(id => id === partId)
    const selectedPartIndex = state.toolbar.parts.findIndex(
      id => id === state.selectedPartId
    )
    const hoverPartId = state.toolbar.parts[index]
    state.toolbar.parts[index] = state.selectedPartId
    state.toolbar.parts[selectedPartIndex] = hoverPartId
    emitter.emit('render')
  })

  emitter.on('start-moving-glitch', glitchId => {
    log('start-moving-glitch')
    state.movingGlitch = true
    emitter.emit('select-glitch', glitchId)
  })

  emitter.on('start-moving-part', partId => {
    log('start-moving-part')
    state.movingPart = true
    emitter.emit('select-part', partId)
  })

  emitter.on('remove-glitch-from-toolbar', glitchId => {
    log('remove-glitch-from-toolbar')
    const i = state.toolbar.glitches.findIndex(id => id === glitchId)
    state.toolbar.glitches.splice(i, 1)
    emitter.emit('render')
  })
  emitter.on('remove-part-from-toolbar', partId => {
    log('remove-glitch-from-toolbar')
    const i = state.toolbar.parts.findIndex(id => id === partId)
    state.toolbar.parts.splice(i, 1)
    emitter.emit('render')
  })
}
