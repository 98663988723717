module.exports = {
  "id": "ball release",
  "title": "ball release",
  "image": "provision/parts/ball release/ball release.svg",
  "info": [
    {
      "text": "Add a Coffee Mug to your game",
      "image": "provision/parts/ball release/ball release.gif"
    }
  ],
  "exposedVariables": [],
  "codeImage": "provision/parts/ball release/ball release.png"
};