module.exports = {
  "id": "runner",
  "title": "runner",
  "allowedGlitches": false,
  "category":"games",
  "project_file": "runner.gbp",
  "thumbnail": "/provision/projects/runner/runner.png",
  "exposedVariables": [
  ],
  "persistentVariables": []
};