const { log } = console

module.exports = function store (state, emitter) {
  function isEventOnDropArea (event) {
    if (!event.path) {
      return false
    }
    return event.path.find(
      element => element && element.classList && element.classList.contains(
        'glitch-drop-areas-wrapper'
      )
    )
  }

  emitter.on('clear-state', () => {

    state.movingGlitch = false
    state.movingPart = false
    state.pickingGlitch = false
    state.pickingPart = false
    state.movingSelectedTarget = false
    state.resizingSelectedTarget = false
    state.rotatingSelectedTarget = false
    state.applyingGlitch = false
    state.hoverRotation = 0
    state.hoveringTargetId = null
    state.draggingGlitchApplication = false
    state.importingPart = false
  })
  emitter.on('deselect-all', () => {
    log('deselect-all')
    if (!state.glitchEditorOpen) {
      state.isInventoryOpen = true
      state.currentVariablePage = 0
      state.showLevelsPanel = false
      emitter.emit('toggle-inventory')
      emitter.emit('close-menu')
      emitter.emit('deselect-part')
      emitter.emit('deselect-target')
      emitter.emit('deselect-glitch')
    }
  })
  emitter.on('drag-glitch-off-play-area', () => {
    state.draggingGlitchOutOfPlayArea = true
  })

  emitter.on('complete-drag-action', e => {
    log('complete-drag-action', e)
    if (state.draggingSceneId) {
      state.draggingSceneId = null
    }
    if (state.draggingGlitchOutOfPlayArea) {
      if (!isEventOnDropArea(e)) {
        emitter.emit('drop-to-trash')
      }
      state.draggingGlitchOutOfPlayArea = false
    }
    if (state.draggingSpriteVariable) {
      emitter.emit('set-selected-sprite-variable', e)
      state.draggingSpriteVariable = false
      state.selectedSpriteVariable = null
    }

    if (state.draggingTargetOutOfPlayArea) {
      emitter.emit('put-target-back-in-play-area')
    }
    emitter.emit('clear-state')
    setTimeout(() => emitter.emit('render'), 1)
  })

  emitter.on('clear-hovering-target', () => {
    state.hoveringTargetId = null
    emitter.emit('render')
  })
}
