module.exports = 
{
  "id": "space rocks",
  "title": "space rocks",
  "thumbnail": "/provision/projects/space rocks/space rocks.png",
  "category": "games",
  "toolbar": {
    "glitches": [],
    "parts": []
  },
  "project_file": "space rocks.gbp"
};