module.exports = {
  "id": "grid_layout",
  "title": "grid layout",
  "color": "orange",
  "icon": "dotgrid",
  "category": "clone",
  "description": "Adjust glitch variables to arrange many clones of your sprite on a grid.",
  "exposedVariables": [
    {
      "variableName": "# of columns",
      "spriteName": "$glitch_target",
      "value": 4,
      "input": {
        "type": "slider",
        "min": 1,
        "max": 30,
        "step": 1,
        "onchange": "set-grid-layout-variable"
      }
    },
    {
      "variableName": "column width",
      "spriteName": "$glitch_target",
      "value": 25,
      "input": {
        "type": "slider",
        "min": 1,
        "max": 100,
        "step": 1,
        "onchange": "set-grid-layout-variable"
      }
    },
    {
      "variableName": "# of rows",
      "spriteName": "$glitch_target",
      "value": 4,
      "input": {
        "type": "slider",
        "min": 1,
        "max": 30,
        "step": 1,
        "onchange": "set-grid-layout-variable"
      }
    },
    {
      "variableName": "row height",
      "spriteName": "$glitch_target",
      "value": 25,
      "input": {
        "type": "slider",
        "min": 1,
        "max": 100,
        "step": 1,
        "onchange": "set-grid-layout-variable"
      }
    }
  ],
  "info": [
    {
      "text": "Create many clones of a sprite arranged in a grid.",
      "image": "provision/glitches/grid_layout/grid_layout.gif"
    }
  ],
  "codeImage": "provision/glitches/grid_layout/grid_layout.png"
};