const html = require('choo/html')
const Button = require('../elements/button.js')
module.exports = function YouLoseMessage(state, emit) {
  if (state.gameOverMinimized) {
    return
  }
  if (state.isFullscreen) {
    const retryButton = Button(
      {
        text: 'RETRY',
        onClick: () => emit('reset-game')
      }
    )
    return html`
    <div class = "next-level-message">
    <p>Sorry, you lose.</p>
      ${retryButton}
    </div>
  `
  } else {
    const retryButton = Button(
      {
        text: 'RESET LEVEL',
        onClick: () => emit('reset-level')
      }
    )
    const continuePlayingButton = Button(
      {
        text: 'CONTINUE PLAYING',
        onClick: () => emit('continue-playing-after-game-over')
      }
    )
    return html`
    <div class = "next-level-message">
    <p>Sorry, you lose.  You can reset the level to its starting state or continue playing + glitching.</p>
      ${retryButton}
      ${continuePlayingButton}
    </div>
  `
  }
}
