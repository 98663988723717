module.exports = {
  "id": "air current",
  "title": "air current",
  "exposedVariables": [
    {
      "spriteName": "air current",
        "variableName": "air current size",
        "input": {
          "type": "slider",
          "options": [],
          "min": "1",
          "max": 2,
          "step": "1"
        },
      "value": "1"
    },
    {
    "spriteName": "air current",
      "variableName": "air current animation speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 0.3,
        "step": "0.01"
      },
      "value": "0.1"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/air current/air current.svg"
};