module.exports = {
  "id": "pulse",
  "color": "red",
  "title": "pulse",
  "icon": "pulse",
  "description": "Make a sprite grow and shrink.",
  "category": "magic",
  "exposedVariables": [
    {
      "variableName": "pulse speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": "20",
        "value": "3",
        "min": "0",
        "step": ".1"
      },
      "value": "3"
    },
    {
      "variableName": "pulse size",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": 100,
        "value": "1",
        "step": "1"
      },
      "value": "60"
    },
    {
      "variableName": "pulse delay",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": "10",
        "value": "1",
        "min": "0",
        "step": ".1"
      },
      "value": ".2"
    }
  ],
  "info": [
    {
      "text": "Make a sprite grow and shrink.",
      "image": "provision/glitches/pulse/pulse.gif"
    }
  ],
  "codeImage": "provision/glitches/pulse/pulse.png",
  "code": null,
  "zip": null,
  "sb3": null
};