/* eslint-disable max-len */
const availablePacks = [
  require('../../../provision/packs/brick_breaker_parts/brick_breaker_parts.json'),
  require('../../../provision/packs/brick_breaker_glitches/brick_breaker_glitches.json'),
  require('../../../provision/packs/platformer_parts/platformer_parts.json'),
  require('../../../provision/packs/platformer_glitches/platformer_glitches.json'),
  require('../../../provision/packs/fish_chomp_kit/fish_chomp_kit.json'),
  require('../../../provision/packs/pinball_kit/pinball_kit.json')
]

module.exports = availablePacks
