module.exports = {
  "id": "pinball",
  "title": "pinball",
  "category":"games",
  "thumbnail": "/provision/projects/pinball/pinball.png",
  "exposedVariables": [
    {
      "spriteName": "flipper",
      "variableName": "trigger key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "flipper2",
      "variableName": "trigger key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    }
  ],
  "project_file": "pinball.gbp"
}
;