module.exports = {
  "id": "terrain",
  "title": "terrain",
  "exposedVariables": [
    {
      "spriteName": "terrain",
      "variableName": "costume #",
      "input": {
        "type": "slider",
        "min": "1",
        "max": "4",
        "step": "1"
      },
      "value": "1"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/terrain/terrain.svg"
};