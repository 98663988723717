module.exports = {
  "id": "fireworks",
  "color": "pink",
  "title": "fireworks",
  "icon": "fireworks",
  "category": "powers",
  "exposedVariables": [
    {
      "variableName": "fireworks trigger",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key",
        "value": "space",
        "options": [        ]
      },
      "value": "space"
    },
    {
      "variableName": "fireworks count",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "1",
        "max": "100",
        "value": 25,
        "step": "1"
      },
      "value": "25"
    },
    {
      "variableName": "fireworks size",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "1",
        "max": "300",
        "value": 15,
        "step": "1"
      },
      "value": "15"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "fireworks speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "1",
        "max": "50",
        "step": ".1"
      },
      "value": "10"
    }
  ],
  "info": [
    {
      "text": "Turn a sprite into a fireworks launcher.",
      "image": "provision/glitches/fireworks/fireworks.gif"
    }
  ],
  "codeImage": "provision/glitches/fireworks/fireworks.png",
  "code": null,
  "zip": null,
  "sb3": null
};