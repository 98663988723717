const VariablesPanel = require('../components/variablespanel.js')
const CodePanel = require('../components/codepanel.js')
const GlitchDropAreas = require('../components/glitchdropareas.js')
const SpriteDropAreas = require('./spritedropareas.js')
const LevelsPanel = require('./levels-panel.js')
const SceneDropAreas = require('./scenedropareas.js')
const html = require('choo/html')
module.exports = function GameContext (state, emit) {
  let secondaryPanel
  let primaryPanel
  if (state.showLevelsPanel) {
    primaryPanel = LevelsPanel(state, emit)
  } else {
    primaryPanel = VariablesPanel(state, emit)
  }
  if (state.draggingGlitchApplication) {
    secondaryPanel = GlitchDropAreas(state, emit)
  } else if (state.movingSelectedTarget) {
    secondaryPanel = SpriteDropAreas(state, emit)
  } else if (state.draggingSceneId) {
    secondaryPanel = SceneDropAreas(state, emit)
  } else {
    secondaryPanel = CodePanel(state, emit)
  }
  if (state.isFullscreen) {
    primaryPanel = VariablesPanel(state, emit)
    secondaryPanel = html`
    <div style="height:10%"></div>`
  }
  return [
    primaryPanel,
    secondaryPanel
  ]
}
