module.exports = {
  "id": "builtin_physics",
  "color": "green",
  "title": "builtin_physics",
  "icon": "bouncify",
  "category": "builtin",
  "description": "Make a sprite move and bounce",
  "exposedVariables": [
    {
      "variableName": "velocity x",
      "spriteName": "$glitch_target",
      "value": 0,
      "input": {
        "type": "slider",
        "min": "-25",
        "max": "25",
        "step": ".01"
      }
    },
    {
      "variableName": "velocity y",
      "spriteName": "$glitch_target",
      "value": 0,
      "input": {
        "type": "slider",
        "min": "-25",
        "max": "25",
        "step": ".01"
      }
    },
    {
      "variableName": "is wall?",
      "spriteName": "$glitch_target",
      "value": "true",
      "input": {
        "type": "boolean"
      }
    },
      {
        "variableName": "is static?",
        "spriteName": "$glitch_target",
        "value": "true",
        "input": {
          "type": "boolean"
        }
    }
  ],
  "info": [
    {
      "text": "Turn a sprite into a bouncing ball",
      "image": "provision/glitches/builtin_physics/builtin_physics.gif"
    }
  ],
  "codeImage": "provision/glitches/builtin_physics/builtin_physics.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/builtin_physics/builtin_physics.svg"
};