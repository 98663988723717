/**
 * UID generator, from Blockly.
 * @module store/utils/uuid
 */

/**
 * Legal characters for the unique ID.
 * Should be all on a US keyboard.  No XML special characters or control codes.
 * Removed $ due to issue 251.
 * @private
 */
const soup = '!#%()*+,-./:;=?@[]^_`{|}~' +
 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

/**
 * Generate a unique ID, from Blockly.  This should be globally unique.
 * 87 characters ^ 20 length > 128 bits (better than a UUID).
 * @return {string} A globally unique ID string.
 */
function uid () {
  const length = 20
  const soupLength = soup.length
  const id = []
  for (let i = 0; i < length; i++) {
    id[i] = soup.charAt(Math.random() * soupLength)
  }
  return id.join('')
}

module.exports = uid
