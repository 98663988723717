module.exports = {
  "id": "star",
  "title": "star",
  "image": "provision/parts/star/star.svg",
  "info": [
    {
      "text": "Add a star to your game",
      "image": "provision/parts/star/star.gif"
    }
  ],
  "exposedVariables": []
};