const html = require('choo/html')

module.exports = function ButtonImage (args) {
  const {
    type = 'square',
    size = 'medium',
    image = null,
    selected = false,
    disabled = false,
    onClick = () => false,
    onMouseUp = () => false,
    onMouseDown = () => false,
    onMouseOver = () => false,
    tooltip = false,
    pickable = false
  } = args
  const selectedClass = selected ? 'selected' : ''
  const disabledClass = disabled ? 'disabled' : ''
  const pickableClass = pickable ? 'pickable' : ''
  const tooltipClass = tooltip ? 'tooltip' : ''
  const tooltipText =
    tooltip ? html`<div class="tooltiptext">${tooltip}</div>` : ''
  const classString = `${type} ${size} ${selectedClass} 
    ${disabledClass} ${pickableClass} ${tooltipClass}`
  const style = `
    background-position: center;
    background-size: contain;
    background-image: url('/${image}');
    background-repeat: no-repeat;
  `
  return html`
    <button
      onclick=${onClick}
      onmousedown=${onMouseDown}
      onmouseup=${onMouseUp}
      onmouseover=${onMouseOver}
      class="${classString}"
      style=${style}
      >
      ${tooltipText}
    </button>
  `
}
