const html = require('choo/html')

module.exports = function InputSprite (args) {
  const {
    label,
    value,
    disabled = false,
    selected = false,
    onSelect = () => false
  } = args
  let valueText = value
  let wiggleClass = ''
  if (value === 'none') {
    wiggleClass = 'wiggle'
    valueText = 'none - click to select'
  }

  const disabledClass = disabled ? 'disabled' : ''
  const selectedClass = selected ? 'selected' : ''
  return html`
    <div class="input input-sprite ${disabledClass} ${wiggleClass}">
      <label>${label}</label>
      <div class="input-area ${selectedClass}" onclick=${onSelect}>
        ${valueText}
      </div>
    </div>
  `
}
