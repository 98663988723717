module.exports = {
  "id": "tornado",
  "title": "tornado",
  "exposedVariables": [
    {
      "spriteName": "tornado",
      "variableName": "tornado animation speed",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 0.3,
        "step": "0.01"
      },
      "value": "0.1"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/tornado/tornado.svg"
};