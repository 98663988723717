/* eslint-disable max-lines-per-function */
const {
  fixVarReferences,
  giveClonesSameVariableIdsAsOriginal
} = require('./apply-glitch.js')
const { findTargetById, findSpriteByName } = require('../../shared_utils/find-target.js')

const OPTION_TYPES = [ 'input', 'boolean', 'key', 'option' ]

const KEY_OPTIONS = [
  'left arrow',
  'right arrow',
  'up arrow',
  'down arrow',
  'space',
  'w',
  'a',
  's',
  'd',
  'f',
  'none'
]
const BOOL_OPTIONS = [ 'true', 'false' ]
function sanitizeVariable (variable, exposedVariable) {
  if (!variable || !exposedVariable) {
    return false
  }
  if (exposedVariable.input.type === 'key') {
    if (!KEY_OPTIONS.includes(variable.value)) {
      [variable.value] = KEY_OPTIONS
    }
  } else if (exposedVariable.input.type === 'boolean') {
    if (!BOOL_OPTIONS.includes(variable.value)) {
      [variable.value] = BOOL_OPTIONS
    }
  } else if (exposedVariable.input.type === 'option') {
    const inputOptions = exposedVariable.input.options.map(i => i.value)
    let string = variable.value
    if (variable.value && variable.value.toString){
       string = variable.value.toString()
    }
    if (!inputOptions.includes(variable.value) && !inputOptions.includes(string)) {
      [variable.value] = inputOptions
    }
  }
  return variable.value
}

function fixGlobals (target, stageTarget) {
  if (target === stageTarget) {
    return
  }
  const stageVariables = Object.values(stageTarget.variables)
  Object.values(target.variables).forEach(variable => {
    const matchingGlobal = stageVariables.find(v => v.name === variable.name)
    if (matchingGlobal) {
      target.deleteVariable(variable.id)
    }
  })
}
/*
 * This function looks at all of the exposed variables in the game and checks
 * whether they are of a type that has a limited set of "allowed" values
 * AND if they are, sets any illegal values to legal ones.
 *
 * It's both a big mess
 * (evidence that we should refactor how we store glitch-variables)
 * AND also probably shouldn't ever need to happen.
 */
function sanitizeVariables (state) {
  const {
    vm,
    glitchApplications,
    availableGlitches,
    addedParts,
    exposedVariables
  } = state
  const spriteVariables = []
  const altVariables = {}
  const stageTarget = vm.runtime.getTargetForStage()
  if (exposedVariables) {
    exposedVariables.forEach(exposedVariable => {
      const target = findSpriteByName(state.vm, exposedVariable.spriteName)
      const variable = Object.values(target.variables).find(
        v => v.name === exposedVariable.variableName
      )
      if (variable) {
        spriteVariables.push({
          target,
          variableId: variable.id,
          exposedVariable
        })
      }
    })
  }
  glitchApplications.forEach(glitchApplication => {
    const glitch = availableGlitches.find(
      g => g.id === glitchApplication.glitchId
    )
    const glitchTarget = findTargetById(state.vm, glitchApplication.targetId)
    glitch.exposedVariables.forEach(exposedVariable => {
      let target
      if (exposedVariable.spriteName === '$glitch_target') {
        target = glitchTarget
      } else if (exposedVariable.spriteName === 'Stage') {
        target = stageTarget
      } else if (exposedVariable.spriteName === '$other_sprites') {
        target = 'all'
      }
      if (target === 'all') {
        altVariables[exposedVariable.variableName] = exposedVariable
      } else {
        const variable = Object.values(target.variables).find(
          v => v.name === exposedVariable.variableName
        )
        if (variable) {
          spriteVariables.push({
            target,
            variableId: variable.id,
            exposedVariable
          })
        }
      }
    })
  })
  addedParts.forEach(part => {
    const partTarget = findSpriteByName(state.vm, part.spriteName)
    part.exposedVariables.forEach(exposedVariable => {
      let target
      if (exposedVariable.spriteName === 'Stage') {
        target = stageTarget
      } else if (exposedVariable.spriteName === '$other_sprites') {
        target = 'all'
      } else {
        target = partTarget
      }
      if (target === 'all') {
        altVariables.push(exposedVariable)
      } else {
        const variable = Object.values(target.variables).find(
          v => v.name === exposedVariable.variableName
        )
        if (variable) {
          spriteVariables.push({
            target,
            variableId: variable.id,
            exposedVariable
          })
        }
      }
    })
  })
  const spriteOptionVariables = spriteVariables.filter(
    v => OPTION_TYPES.includes(v.exposedVariable.input.type)
  )
  const altOptionVariables = Object.values(altVariables).filter(
    v => OPTION_TYPES.includes(v.input.type)
  )
  spriteOptionVariables.forEach(v => {
    v.target.sprite.clones.forEach(clone => {
      sanitizeVariable(clone.variables[v.variableId], v.exposedVariable)
    })
  })
  altOptionVariables.forEach(exposedVariable => {
    vm.runtime.targets.forEach(t => {
      if (!t.isStage) {
        const variable = Object.values(t.variables).find(
          v => v.name === exposedVariable.variableName
        )
        sanitizeVariable(variable, exposedVariable)
      }
    })
  })
  state.vm.runtime.targets.forEach(target => {
    if (target.isOriginal) {
      fixGlobals(target, stageTarget)
      Object.values(target.blocks._blocks).forEach(block => {
        fixVarReferences(block, target)
      })
    } else {
      giveClonesSameVariableIdsAsOriginal(target)
    }
  })
}

function sanitizeSelections (state) {  
  if (state.selectedTargetId) {
    const target = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    if (!target) {
      if (state.selectedGlitchApplicationId) {
        const glitchApplication = state.glitchApplications.find(
          g => g.id === state.selectedGlitchApplicationId
        )
        state.selectedTargetId = glitchApplication.targetId
      } else {
        state.selectedTargetId = null
      }
    }
  }
}

module.exports = {
  sanitizeSelections,
  sanitizeVariables
}
