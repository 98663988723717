const html = require('choo/html')
const { getNormalizedTargetBounds} = require('../../shared_utils/coordinates.js')
const {
  findTargetById,
  findSpriteByName
} = require('../../shared_utils/find-target.js')
const HoverSprite = require('../elements/highlightsprite.js')
const GHOSTED = 0.25
const ELBOW_DISTANCE = 5
function getTargetBoundsForNone (stickerCoords) {
  return {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: (Math.abs(stickerCoords.right.x - stickerCoords.left.x)),
    height: (Math.abs(stickerCoords.top.y - stickerCoords.bottom.y))
  }
}
function findPair (targetCoords, stickerCoords, fromTop) {
  const pair = []
  let turnPoint
  if (fromTop) {
    pair[1] = {...stickerCoords.top, side: 'top'}
    turnPoint = {...stickerCoords.top}
    turnPoint.y -= ELBOW_DISTANCE
  } else {
    pair[1] = {...stickerCoords.bottom, side: 'bottom'}
    turnPoint = {...stickerCoords.bottom}
    turnPoint.y += ELBOW_DISTANCE
  }
  if (targetCoords.bottom.y >= turnPoint.y
    && targetCoords.top.y <= turnPoint.y) {
    if (targetCoords.right.x < turnPoint.x) {
      pair[0] = {
        ...targetCoords.right,
        side: 'right'
      }
    } else {
      pair[0] = {
        ...targetCoords.left,
        side: 'left'
      }
    }
  } else {
    if (targetCoords.bottom.y < turnPoint.y) {
      pair[0] = {
        ...targetCoords.bottom,
        side: 'bottom'
      }
    } else {
      pair[0] = {
        ...targetCoords.top,
        side: 'top'
      }
    }
  }
  return pair
}
function calculateElbow (pair) {
  let elbowDistance = ELBOW_DISTANCE
  if (pair[0].side === 'right' || pair[0].side === 'left') {
    elbowDistance = Math.abs(pair[0].y - pair[1].y)
  }
  switch (pair[1].side) {
    case 'bottom': {
      const line1 = [
        pair[1],
        {
          x: pair[1].x,
          y: pair[1].y + elbowDistance
        }
      ]
      const line2 = [
        line1[1],
        {
          x: pair[0].x,
          y: pair[1].y + elbowDistance
        }
      ]
      const line3 = [
        line2[1],
        pair[0]
      ]
      return [ line1, line2, line3 ]
    }
    case 'top': {
      const line1 = [
        pair[1],
        {
          x: pair[1].x,
          y: pair[1].y - elbowDistance
        }
      ]
      const line2 = [
        line1[1],
        {
          x: pair[0].x,
          y: pair[1].y - elbowDistance
        }
      ]
      const line3 = [
        line2[1],
        pair[0]
      ]
      return [ line1, line2, line3 ]
    }
    case 'mixed':
      break
    default:
      break
  }

  return [pair.map(c => ({x: c.x, y: c.y}))]
}

function generateSvgLineElement (pairs, color, opacity) {
  const lines = pairs.map(pair => html`
    <line
    opacity="${opacity}"
    x1="${pair[0].x}%" 
    y1="${pair[0].y}%" 
    x2="${pair[1].x}%" 
    y2="${pair[1].y}%" 
    stroke-dasharray="8,16"
    style="stroke:var(--${color});
    stroke-width:4" />
    `)

  return html`
    <svg 
      width="400" 
      height="180" 
      style="
        pointer-events: none; 
        position:absolute; 
        width:100%; 
        height:100%; 
        top:0; 
        left:0;
        z-index:9999;"
    >
      ${lines}
    </svg>`
}


function getConnectionPointsForTarget (bounds) {
  return {
    left: {
      x: bounds.left,
      y: bounds.top + (bounds.height / 2),
      axis: 'horizontal'
    },
    right: {
      x: bounds.left + bounds.width,
      y: bounds.top + (bounds.height / 2),
      axis: 'horizontal'
    },
    top: {
      x: bounds.left + (bounds.width / 2),
      y: bounds.top,
      axis: 'vertical'
    },
    bottom: {
      x: bounds.left + (bounds.width / 2),
      y: bounds.top + bounds.height,
      axis: 'vertical'
    }
  }
}
function getConnectionPointsForSticker (stickerBounds, canvasBounds) {
  function normalize (coords) {
    return {
      x: (coords.x / canvasBounds.width) * 100,
      y: (coords.y / canvasBounds.height) * 100,
      axis: coords.axis
    }
  }
  return {
    left: normalize({
      x: (stickerBounds.left - canvasBounds.left),
      y: stickerBounds.top + (stickerBounds.height / 2) - canvasBounds.top,
      axis: 'horizontal'
    }),
    right: normalize({
      x: stickerBounds.left + stickerBounds.width - canvasBounds.left,
      y: stickerBounds.top + (stickerBounds.height / 2) - canvasBounds.top,
      axis: 'horizontal'
    }),
    top: normalize({
      x: stickerBounds.left + (stickerBounds.width / 2) - canvasBounds.left,
      y: stickerBounds.top - canvasBounds.top,
      axis: 'vertical'
    }),
    bottom: normalize({
      x: stickerBounds.left + (stickerBounds.width / 2) - canvasBounds.left,
      y: stickerBounds.top + stickerBounds.height - canvasBounds.top,
      axis: 'vertical'
    })
  }
}

function getSpriteTypeVariables (glitch) {
  return glitch.exposedVariables.filter(
    v => v.input.type === 'sprite'
  ).map(v => v.variableName)
}

module.exports = function GlitchWires (state, emit) {
  if (!state.selectedGlitchApplicationId) {
    return null
  }
  const selectedGlitchApplication = state.glitchApplications.find(
    g => g.id === state.selectedGlitchApplicationId
  )
  const selectedGlitch = state.availableGlitches.find(
    g => g.id === selectedGlitchApplication.glitchId
  )
  const spriteVariables = getSpriteTypeVariables(selectedGlitch)
  if (spriteVariables.length === 0) {
    return null
  }
  const selectedTarget = findTargetById(state.vm, state.selectedTargetId)
  const connectedSprites = Object.values(selectedTarget.variables)
    .filter(v => spriteVariables.includes(v.name))
    .map(v => ({spriteName: v.value, variableId: v.id}))
  const selectedTargetBounds = getNormalizedTargetBounds(
    selectedTarget,
    state.canvas
  )
  const targetTop = selectedTargetBounds.top + selectedTargetBounds.height
  const selectedGlitchDiv = document.querySelector('.sticker > button.selected')
  if (!selectedGlitchDiv) {
    return null
  }
  const stickerBounds = selectedGlitchDiv.getBoundingClientRect()
  const canvasBounds = state.canvas.getBoundingClientRect()
  const stickerTop = (stickerBounds.top / canvasBounds.height) * 100
  const fromTop = stickerTop < targetTop
  const stickerCoords =
    getConnectionPointsForSticker(stickerBounds, canvasBounds)
  const highlights = []
  const svgElements = []
  connectedSprites.forEach(connectedSprite => {
    const target = findSpriteByName(state.vm, connectedSprite.spriteName)
    let targetBounds
    if (target) {
      targetBounds = getNormalizedTargetBounds(target, state.canvas)
    } else if (!state.settingSpriteVariable && !state.draggingSpriteVariable) {
      targetBounds = getTargetBoundsForNone(stickerCoords)
    }
    if (targetBounds) {
      const targetCoords = getConnectionPointsForTarget(targetBounds)
      const nearestPair = findPair(targetCoords, stickerCoords, fromTop)
      const lineCoords = calculateElbow(nearestPair)
      let opacity = 1
      if (state.settingSpriteVariable || state.draggingSpriteVariable) {
        opacity = GHOSTED
      }
      const line = generateSvgLineElement(
        lineCoords,
        selectedGlitch.color,
        opacity
      )
      svgElements.push(line)
      const { width, height, top, left } = targetBounds
      highlights.push(HoverSprite({
        width,
        height,
        top,
        left,
        highlight: true,
        color: selectedGlitch.color,
        onMouseDown: () => emit(
          'start-dragging-sprite-variable',
          connectedSprite.variableId
        )
      }))
    }
  })
  if ((state.settingSpriteVariable || state.draggingSpriteVariable)
    && state.glitchWireTargetPosition) {
    const pair = []
    if (fromTop) {
      pair[1] = {
        ...stickerCoords.top,
        side: 'top'
      }
    } else {
      pair[1] = {
        ...stickerCoords.bottom,
        side: 'bottom'
      }
    }
    const cursorNormalized = {
      x: (state.glitchWireTargetPosition.x - canvasBounds.x)
        / canvasBounds.width * 100,
      y: (state.glitchWireTargetPosition.y - canvasBounds.y)
        / canvasBounds.height * 100,
      side: 'top'
    }
    pair[0] = cursorNormalized
    const lineCoords = calculateElbow(pair)
    const line = generateSvgLineElement(lineCoords, selectedGlitch.color, 1)
    svgElements.push(line)
  }
  return [ svgElements, highlights ]
}
