module.exports = {
  "id": "bus",
  "title": "bus",
  "image": "provision/parts/bus/bus.svg",
  "info": [
    {
      "text": "Add a bus to your game",
      "image": "provision/parts/bus/bus.gif"
    }
  ],
  "exposedVariables": []
};