module.exports = {
    "id": "bomb",
    "title": "bomb",
    "image": "provision/parts/bomb/bomb.svg",
    "info": [
        {
            "text": "Add a bomb to your game",
            "image": "provision/parts/bomb/bomb.gif"
        }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/bomb/bomb.png"
};