const html = require('choo/html')
const Button = require('../elements/button.js')
module.exports = function NextLevelMessage(state, emit) {
  if (!state.levelComplete) {
    return null
  }
  const continuePlayingButton = Button(
    {
      text: 'CONTINUE PLAYING',
      onClick: () => emit('continue-playing')
    }
  )
  const resetLevelButton = Button(
    {
      text: 'RESET LEVEL',
      onClick: () => emit('reset-level')
    }
  )
  const nextLeveLButton = Button(
    {
      text: 'GO TO NEXT LEVEL',
      onClick: () => emit('go-to-next-level')
    }
  )

  if (state.levelCompleteMinimized) {
    return ''
  }
  if (state.isFullscreen) {
    return html`
    <div class = "next-level-message">
    <p>Congratulations!  You completed the level!</p>
      ${nextLeveLButton}
    </div>
  `
  } else {
    return html`
    <div class = "next-level-message">
    <p>Congratulations!  You completed the level!</p>
    <p>You can reset the level to its starting condition or continue playing and glitching from here.</p>
    <div>
      ${resetLevelButton}
      ${continuePlayingButton}
      </div>
    </div>
  `
  }

}
