/* eslint-disable multiline-ternary */
const PanelElement = require('../elements/panel.js')
const html = require('choo/html')
const Button = require('../elements/button.js')
const ButtonIcon = require('../elements/buttonicon.js')
const InputText = require('../elements/inputtext.js')

module.exports = function VariablesPanel(state, emit) {
  function RenameSceneInput(scene) {
    return InputText({
      value: scene.metadata.id,
      extraStyle: 'width:70%; margin-right:var(--size-4)',
      inputId: 'rename-scene-input',
      autoFocus: true,
      onBlur: () => emit('stop-renaming-scene'),
      onInput: e => emit('update-scene-name', e.target.value)
    })
  }
  function SceneButton(scene) {
    if (state.renamingScene && scene.metadata.id === state.currentScene.metadata.id) {
      return RenameSceneInput(scene)
    }
    const s = scene
    const isCurrentScene = state.currentScene.metadata.id === s.metadata.id
    let color, onClick
    if (isCurrentScene) {
      color = 'purple'
      onClick = function onClick(){
        emit('rename-scene', s.metadata.id)
      }
    } else {
      color = 'mediumgrey'
      onClick = function onClick(){
        emit('complete-drag-action')
        emit('load-scene', state.project.scenes.indexOf(s))
      }
    }
    const index = state.project.scenes.indexOf(scene)
    const button = Button({
      text: s.metadata.id,
      color,
      type: 'icon-aligned', 
      size: 'small',
      onMouseOver: () => {
        if (state.draggingSceneId && state.draggingSceneId !== s.metadata.id) {
          emit('swap-scenes', state.draggingSceneId, s.metadata.id)
        }
      },
      onMouseDown: e => {
        const mini = Button({
          color: state.currentScene.metadata.id === s.metadata.id ? 'purple' : 'mediumgrey',
          text: `${index + 1}: ${s.metadata.id}`,
          type: 'fullwidth', size: 'small'
        })
        emit('start-drag', mini, e)
        emit('start-moving-scene', s.metadata.id)
      },
      onClick
    })
    return button
  }

  function EditSceneButton(scene) {
    if (state.levelLoading) {
      return null
    }
    if (state.currentScene === scene) {
      return ButtonIcon({
        icon: 'refresh',
        onClick:
          () => emit('reset-level'),
        size: 'medium'
      })
    }
    return null
  }


  const sceneItems = state.project.scenes.map(s => html`
  <div style="display:flex; margin-bottom: var(--size-2);">
    ${SceneButton(s)}
    ${EditSceneButton(s)}
  </div> `)
  sceneItems.push(html`
    <div style="display:flex; 
    justify-content: flex-end; 
    margin-top: var(--size-8);
    margin-bottom: var(--size-2);">
    <span style = "    margin-right:var(--size-1);">
    ${ButtonIcon({
    size: 'medium',
    icon: 'drawer-out',
    tooltip: 'import level',
    onClick: () => emit('open-import-scene-dialog')
  }
  )}
  </span>

    ${ButtonIcon({
    size: 'medium',
    icon: 'drawer-in',
    tooltip: 'export level',
    onClick: () => emit('export-current-scene')
  })}
  
</div>    `)
  return PanelElement({
    color: 'purple',
    children: sceneItems
  })
}
