module.exports = {
  "id": "moon lander",
  "title": "moon lander",
  "allowedGlitches": false,
  "category":"games",
  "project_file": "moon lander.gbp",
  "thumbnail": "/provision/projects/moon lander/moon lander.png",
  "exposedVariables": [
    {
      "spriteName": "ufo",
      "variableName": "thrust key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "ufo",
      "variableName": "left key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "ufo",
      "variableName": "right key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    }
  ],
  "persistentVariables": []
};