const ToolbarElement = require('../elements/toolbar.js')
const ButtonIcon = require('../elements/buttonicon.js')


module.exports = function CodeEditorPanel (state, emit) {
  if (!state.glitchEditorContentLoaded
    || state.selectedPartId
    || (state.selectedTargetId && !state.selectedGlitchApplicationId)
  ) {
    return null
  }
  return ToolbarElement({
    categoryA: ButtonIcon({
      icon: 'target',
      onClick: () => emit('glitch-editor-select-target')
    }),
    categoryB: ButtonIcon({
      icon: 'stack',
      onClick: () => emit('glitch-editor-select-others')
    }),
    categoryC: []
  })
}
