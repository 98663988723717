module.exports = {
  "id": "soccer",
  "title": "soccer",
 
  "project_file": "soccer.gbp",
  "exposedVariables": [
  ],
  "persistentVariables": [],
  "thumbnail": "/provision/projects/soccer/soccer.png",
  "category": "games",
  "toolbar":{
    "glitches":[],
    "parts":[]
  }
}
;