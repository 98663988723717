const html = require('choo/html')
const Icon = require('./icon.js')

module.exports = function ButtonIcon (args) {
  const {
    type = 'square',
    size = 'medium',
    color = 'mediumgrey',
    noIconBackground = false,
    icon = null,
    tooltip = false,
    selected = false,
    disabled = false,
    pickable = false,
    onClick = () => false,
    onMouseDown = () => false,
    onMouseUp = () => false,
    onMouseOver = () => false,
    margin = false
  } = args
  const marginClass = margin ? 'margin' : ''
  const selectedClass = selected ? 'selected' : ''
  const disabledClass = disabled ? 'disabled' : ''
  const iconBackgroundClass = noIconBackground ? 'no-background' : ''
  const pickableClass = pickable ? 'pickable' : ''
  const tooltipClass = tooltip ? 'tooltip' : ''
  const tooltipText =
    tooltip ? html`<div class="tooltiptext">${tooltip}</div>` : ''
  const classString = `${type} ${size} ${color} 
    ${selectedClass} ${disabledClass} ${pickableClass} ${tooltipClass} 
    ${marginClass}`

  return html`
    <button
      onclick=${onClick}
      onmousedown=${onMouseDown}
      onmouseup=${onMouseUp}
      onmouseover=${onMouseOver}
      class="${classString}"
      >
      <span class="icon ${iconBackgroundClass}">
        ${Icon({ iconName: icon })}
      </span>
      ${tooltipText}
    </button>
  `
}
