const initialState = require('./initial-state.js')
module.exports = function store(state, emitter) {
  emitter.on('browser-loaded', () => {
    if (state.vm) {
      window.location.reload()
    }
    emitter.emit('stop-vm')
    emitter.emit('deselect-all')
    const oldState = { ...state }
    const initialStateCopy = JSON.parse(JSON.stringify(initialState))
    state = Object.assign(state, initialStateCopy)
    state.getHistory = initialState.getHistory
    emitter.emit('render')
  })
}
