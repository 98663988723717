module.exports = {
    "id": "construction floor",
    "title": "construction floor",
    "image": "provision/parts/construction floor/construction floor.svg",
    "info": [
      {
        "text": "Add a construction floor to your game",
        "image": "provision/parts/construction floor/construction floor.gif"
      }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/construction floor/construction floor.png"
  };