const availableGlitches = require('./initial-data/glitches.js')
const availableParts = require('./initial-data/parts.js')
const availablePacks = require('./initial-data/packs.js')
const availableProjects = require('./initial-data/projects.js')
module.exports = {
  availablePacks,
  availableProjects,
  availableGlitches,
  availableParts,
  packs: [],
  toolbar: {
    glitches: [],
    parts: []
  },
  draggingGlitchOutOfPlayArea: false,
  selectedGlitchCategories: ['rules'],
  glitchesLoaded: false,
  partsLoaded: false,
  projectData() {
    throw("Using projectData")
  }, 
  loadedVM: false,
  vm: null,
  vmInterval: 0,
  canvas: null,

  selectedTargetId: null,
  selectedGlitchId: null,
  selectedPartId: null,

  rotatingSelectedTarget: false,
  resizingSelectedTarget: false,
  resizingHandle: null,

  movingSelectedTarget: false,
  movingSpriteOffset: {x: 0, y: 0},
  hoverRotation: 0,
  rotationStartingDirection: 0,

  selectedInventoryCategory: 'glitch',
  currentVariablePage: 0,
  isInventoryOpen: null,
  inventoryFilter: '',

  disabledToolbarIcons: [],
  highlightToolbarIcons: [],

  isMenuOpen: false,
  masterVolume: 30,
  unmuteVolume: 30,
  isGamePlaying: false,
  wasGamePlaying: false,
  future: [],
  currentHistoryItem: null,
  currentScene: null,
  histories: {},
  getHistory () {
    if (!this.histories[this.currentScene.metadata.id]) {
      this.histories[this.currentScene.metadata.id] = []
    }
    return this.histories[this.currentScene.metadata.id]
  },
  glitchApplications: [],
  selectedGlitchApplicationId: null,
  addedParts: [],

  applyingGlitch: false,
  draggingGlitchApplication: false,
  hoveringTargetId: null,
  selectedVariableId: null,
  settingSpriteVariable: false,
  changingVariable: false,

  pickingGlitch: false,
  pickingPart: false,

  draggingElement: null,
  draggingPosition: { top: 0, left: 0 },
  projectMetadata: null,
  sceneId() {
    throw("Using state.sceneId")
  },
  documentEventListeners: [],
  glitchEditorVariables: null,
  gameOver: false,
  glitchEditorOpen: false,
  showGlitches: true,
  spriteOperationsAllowed: true,
  isFullscreen: false,
  isFullscreenOnly: false,
  levelComplete: false,
  levelCompleteMinimized: false,
  selectedSceneIndex: false,
  draggingSceneId: null,
  project: null
}
