const { log } = console
module.exports = function store (state, emitter) {
  emitter.on('select-glitch-application', glitchApplicationId => {
    log('select-glitch-application', glitchApplicationId)
    state.currentVariablePage = 0

    const glitchApplication = state.glitchApplications.find(
      h => h.id === glitchApplicationId
    )
    state.selectedGlitchApplicationId = glitchApplicationId
    const glitchTarget = state.vm.runtime.targets.find(
      t => t.id === glitchApplication.targetId
    )
    const targetToSelect = glitchTarget.sprite.clones.find(
      c => c.id === state.selectedTargetId
    )
    if (!targetToSelect) {
      state.selectedTargetId = glitchApplication.targetId
    }
    emitter.emit('render')
  })

  emitter.on('select-glitch-on-target', (glitchId, targetId) => {
    const target = state.vm.runtime.targets.find(t => t.id === targetId)
    const [original] = target.sprite.clones
    const glitchApplication = state.glitchApplications.find(
      g => g.targetId === original.id
      && g.glitchId === glitchId
    )
    emitter.emit('select-target', targetId)
    emitter.emit('select-glitch-application', glitchApplication.id)
  })
}
