module.exports = {
  "id": "cactus",
  "title": "cactus",
  "exposedVariables": [
    {
      "spriteName": "cactus",
      "variableName": "idle time",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 10,
        "step": "0.01"
      },
      "value": "1"
    },
    {
      "spriteName": "cactus",
      "variableName": "grow time",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "10",
        "step": ".01"
      },
      "value": ".25"
    },
    {
      "spriteName": "cactus",
      "variableName": "lifespan",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": 10,
        "step": ".1"
      },
      "value": "3"
    }
  ],
  "info": [],
  "image": "provision/parts/cactus/cactus.svg"
};