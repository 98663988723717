module.exports = {
    "id": "scoreboard",
    "title": "scoreboard",
    "exposedVariables": [
        {
            "spriteName": "scoreboard",
            "variableName": "value",
            "input": {
                "type": "slider",
                "options": [],
                "min": "0",
                "max": "100",
                "step": "1"
            },
            "value": "0"
        },
        {
            "spriteName": "scoreboard",
            "variableName": "variable to display",
            "input": {
                "type": "option",
                "options": [
                    {
                        "value": "player 1: score",
                        "label": "p1 score"
                    },
                    {
                        "value": "player 2: score",
                        "label": "p2 score"
                    },
                    {
                        "value": "player 1: lives",
                        "label": "p1 lives"
                    },
                    {
                        "value": "player 2: lives",
                        "label": "p2 lives"
                    },
                    {
                        "value": "level #",
                        "label": "level #"
                    },
                    {
                        "value": "timer",
                        "label": "timer"
                    }
                ]
            },
            "value": "Score"
        }
    ],
    "info": [],
    "zip": null,
    "sb3": null,
    "code": null,
    "image": "provision/parts/scoreboard/scoreboard.svg"
};