const html = require('choo/html')

module.exports = function InputText (args) {
  const {
    label,
    value,
    placeholder,
    disabled = false,
    greyedOut = false,
    onFocus = () => false,
    onBlur = () => false,
    onChange = () => false,
    onKeyUp = null,
    onKeyDown = null,
    onInput = () => false,
    onClick = () => false,
    extraStyle = false,
    autoFocus = false,
    inputId = ''
  } = args
  let disabledClass = ''
  if (disabled) {
    disabledClass = 'disabled'
  } else if (greyedOut) {
    disabledClass = 'greyedOut'
  }
  const input = html`
  <input
    class="input-area"
    type="text"
    value="${value}"
    placeholder=${placeholder}
    disabled="${disabled}"
    onfocus=${onFocus}
    onblur=${onBlur}
    onchange=${onChange}
    onkeyup=${onKeyUp}
    onkeydown=${onKeyDown}
    oninput=${onInput}
    onclick=${onClick}
    />

  `
  if (inputId) {
    input.id = inputId
  }
  if (autoFocus) {
    input.autofocus = true
    if (inputId) {
      const el = document.getElementById(inputId)
      if (el) {
        el.focus()
      }
    }
  }
  return html`
    <div style="${extraStyle}" class="input input-text ${disabledClass}">
      <label>${label}</label>
      ${input}
    </div>
  `
}
