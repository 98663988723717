module.exports = {
  "id": "traffic",
  "color": "orange",
  "title": "Traffic",
  "icon": "traffic-light",
  "category": "clone",
  "exposedVariables": [
    {
      "variableName": "traffic direction",
      "spriteName": "$glitch_target",
      "input": {
        "type": "option",
        "value": "none",
        "options": [
          {
            "value": "up",
            "label": "up"
          },
          {
            "value": "down",
            "label": "down"
          },
          {
            "value": "left",
            "label": "left"
          },
          {
            "value": "right",
            "label": "right"
          }
        ]
      },
      "options": [
        {
          "label": "up",
          "value": "up"
        },
        {
          "label": "down",
          "value": "down"
        },
        {
          "label": "left",
          "value": "left"
        },
        {
          "label": "right",
          "value": "right"
        }
      ],
      "value": "left"
    },
    {
      "variableName": "movement speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "20",
        "value": "5",
        "step": ".1"
      },
      "value": "1"
    },
    {
      "variableName": "delay min",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "10",
        "value": "1",
        "step": ".1"
      },
      "value": "1"
    },
    {
      "variableName": "delay max",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "10",
        "value": "1",
        "step": ".1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "show original?",
      "input": {
        "type": "boolean",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "false"
    },
    {
      "variableName": "start time",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "20",
        "value": "5",
        "step": ".1"
      },
      "value": "1"
    },
  ],
  "info": [
    {
      "text": "Create a stream of clones to move across the screen.",
      "image": "provision/glitches/traffic/traffic.gif"
    }
  ],
  "codeImage": "provision/glitches/traffic/traffic.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/traffic/traffic.svg"
};