const uuid = require('./uuid.js')

const messagesWhitelist = [
  'reset-game',
  'next-level',
  'game-over',
  'reset-level',
  'load-scene-by-id',
  'jump-to-level'
]

const {log} = console



function updateSpriteList (vm) {
  const gameStage = vm.runtime.getTargetForStage()
  if (!gameStage) {
    return
  }


  const walls = []
  const kickers = []
  const spriteListValues = []
  vm.runtime.targets.filter(t => !t.isStage && t.isOriginal).forEach(t => {
    spriteListValues.push(t.sprite.name)
    const isWall = t.lookupOrCreateVariable('is wall?', 'is wall?').value
    const kickStrength = t.lookupOrCreateVariable(
      'kick strength',
      'kick strength'
    ).value

    if (kickStrength > 0) {
      kickers.push(t.sprite.name)
    }
    if (isWall === 'true' || isWall === true) {
      walls.push(t.sprite.name)
    }
    const spriteName = t.lookupOrCreateVariable('$sprite_name', '$sprite_name')
    spriteName.value = t.sprite.name

    const visible = t.lookupOrCreateVariable('$visible', '$visible')

    if (t.isOriginal) {
      visible.value = t.visible
      const width = t.lookupOrCreateVariable('$width', '$width')
      width.value = t.getBounds().width
      const height = t.lookupOrCreateVariable('$height', '$height')
      height.value = t.getBounds().height
    }
  })
  const spriteList = gameStage.lookupOrCreateList('SpriteList', 'SpriteList')
  spriteList.value = spriteListValues
  const wallList = gameStage.lookupOrCreateList(
    '$wall_sprites',
    '$wall_sprites'
  )
  const kickerList = gameStage.lookupOrCreateList(
    '$kicker_sprites',
    '$kicker_sprites'
  )
  wallList.value = walls
  kickerList.value = kickers
}

function updateAppliedGlitchesLists (vm, glitchApplications) {
  const stageTarget = vm.runtime.getTargetForStage()
  const listId = Object.values(stageTarget.variables).find(
    v => v.name === '$applied_glitches'
  )
  if (listId) {
    stageTarget.deleteVariable(listId.id)
  }
  const originals = vm.runtime.targets.filter(
    t => t.isOriginal && !t.isStage
  )
  originals.forEach(target => {
    const targetGlitches = glitchApplications.filter(
      g => g.targetId === target.id
    )
    target.sprite.clones.forEach(t => {
      const glitchList = t.lookupOrCreateList(
        uuid(),
        '$applied_glitches'
      )
      glitchList.value = targetGlitches.map(g => g.glitchId)
    })
  })
}

function handleMessages (vm, emitter) {
  const stageTarget = vm.runtime.getTargetForStage()
  if (!stageTarget) {
    return
  }
  const messagesVar = stageTarget.lookupOrCreateList(
    uuid(),
    '$gamebender_messages'
  )
  const messages = messagesVar.value
  while (messages.length > 0) {
    const messageArray = messages.pop().split(',')
      .map(string => string.trim())
      log("handlindg message", messageArray)
    if (messagesWhitelist.includes(messageArray[0])) {

      emitter.emit(...messageArray)
    }
  }
  messagesVar.value = []
}


module.exports = {
  updateAppliedGlitchesLists,
  updateSpriteList,
  handleMessages
}
