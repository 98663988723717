const { log } = console
const { copyBlocks, copyVariables } = require('./apply-glitch.js')
const uuid = require('./uuid.js')
function addPartToVM(part, vm, opts) {
  log('importPart', part.id, opts)
  const { coords = { x: 0, y: 0 }, name = false } = opts
  const code = JSON.parse(JSON.stringify(part.code))
  code.monitors = []
  code.targets = code.targets.filter(t => !t.isStage)
  return vm.sb3.deserialize(code, vm.runtime, part.zip)
    .then(({ targets, extensions }) => vm.installTargets(
      [targets[0]],
      extensions,
      true
    ).then(() => {
      const newPart = vm.runtime.targets[
        vm.runtime.targets.length - 1
      ]
      if (name) {
        vm.renameSprite(newPart.id, name)
      }
      newPart.blocks.getScripts().forEach(blockId => {
        const block = newPart.blocks.getBlock(blockId)
        if (block.opcode === 'event_whenflagclicked') {
          vm.runtime._pushThread(block.id, newPart)
        }
      })
      newPart.setXY(coords.x, coords.y)
      const addedPart = {
        partId: part.id,
        spriteName: newPart.sprite.name,
        exposedVariables: []
      }
      part.exposedVariables.forEach(v => {
        const copy = JSON.parse(JSON.stringify(v))
        copy.spriteName = newPart.sprite.name
        addedPart.exposedVariables.push(copy)
      })

      return {
        addedPartEntry: addedPart,
        target: newPart
      }
    }))
}

function addAppliedGlitchesCodeToPart(
  target,
  vm,
  glitches,
  glitchApplications
) {
  glitchApplications.forEach(glitchApplication => {
    const glitch = glitches.find(g => g.id === glitchApplication.glitchId)
    if (glitchApplication.otherSpritesBlockIds.length > 0) {
      const glitchTarget = vm.runtime.targets.find(
        t => t.id === glitchApplication.targetId
      )
      const glitchOthers = glitch.code.targets.find(
        t => t.name === '$other_sprites'
      )
      if (glitchOthers) {
        const blockIds = copyBlocks(glitchOthers, target, vm)
        copyVariables(glitchOthers, target, glitch.exposedVariables)
        glitchApplication.otherSpritesBlockIds =
          glitchApplication.otherSpritesBlockIds.concat(blockIds)
        target.blocks.updateAssetName(
          '$glitch_target',
          glitchTarget.sprite.name,
          'sprite'
        )
      }
    }
    const exposedOtherSpritesVariables =
      glitch.exposedVariables.filter(v => v.spriteName === '$other_sprites')
    exposedOtherSpritesVariables.forEach(exposedVar => {
      const targetVar = target.lookupOrCreateVariable(
        uuid(),
        exposedVar.variableName
      )
      targetVar.value = exposedVar.value
    })
  })
  return target
}
module.exports = { addPartToVM, addAppliedGlitchesCodeToPart }
