
const { log } = console

module.exports = function store (state, emitter) {
  emitter.on('game-over', () => {
    log('game-over')
    emitter.emit('pause')
    state.gameOver = true
    state.gameOverMinimized = false
    emitter.emit('render')
  })

  emitter.on('continue-playing-after-game-over', () => {
    log('game-over')
    state.gameOver = true
    state.gameOverMinimized = true
    emitter.emit('play')
    emitter.emit('render')
  })
}
