module.exports = {
  "id": "blank project",
  "title": "Blank Project",
  "scenes": [{
    "id": "main",
    "metadata": "main.json",
    "sb3": "blank project.sb3"
  }],
  "levels": [
    "main"    
  ],
  "exposedVariables": [
  
  ],
  "startingGlitches": [
  ],
  "thumbnail": "/provision/projects/blank project/blank project.png",
  "category": "games",
  "toolbar":{
    "glitches":[],
    "parts":[]
  }
}
;