const { addPartToVM } = require("./utils/add-part")
const { applyGlitch } = require("./utils/apply-glitch")
const { findSpriteByName } = require("../shared_utils/find-target.js")
const uuid = require('./utils/uuid.js')
const { initializePrototypes } = require('./utils/scratch/object-prototypes.js')
const { log } = console
const { deserializeSnapshot } = require('./utils/import-export-helpers.js')
const { loadSnapshot } = require("./utils/load-snapshot")
const { sanitizeSelections } = require("./utils/sanitize-state")
function clearIoDevices(runtime) {
  for (const deviceName in runtime.ioDevices) {
    if (deviceName) {
      const device = runtime.ioDevices[deviceName]
      if (device) {
        runtime.ioDevices[deviceName] = new device.constructor(runtime)
      }
    }
  }
}
function setExposedVariables(vm, exposedVariables) {
  exposedVariables.forEach(exposedVar => {
    const varTarget = vm.runtime.targets.find(
      t => t.sprite.name === exposedVar.spriteName
    )
    const variables = Object.values(varTarget.variables)
    let existingVar = variables.find(
      v => v.name === exposedVar.variableName
    )
    if (!existingVar && exposedVar.isList === false) {
      existingVar = varTarget.lookupOrCreateVariable(
        uuid(),
        exposedVar.variableName
      )
    } else {
      if (!existingVar && exposedVar.isList) {
        existingVar = varTarget.lookupOrCreateList(
          uuid(),
          exposedVar.variableName
        )
      }
    }
    if (existingVar) {
      existingVar.value = exposedVar.value
    }
  })
}

function setSpriteProperties(metadata, vm) {
  metadata.spriteProperties.forEach(spriteData => {
    const target = findSpriteByName(vm, spriteData.name)
    spriteData.lists.forEach(list => {
      const newList = target.lookupOrCreateList(uuid(), list.name)
      newList.value = list.value
    })

    spriteData.variables.forEach(variable => {
      const newVar = target.lookupOrCreateVariable(uuid(), variable.name)
      newVar.value = variable.value
    })
    target.setXY(spriteData.x, spriteData.y)
    target.setDirection(spriteData.direction)
    target.setSize(spriteData.size)
    if (spriteData.currentCostume) {
      target.setCostume(spriteData.currentCostume)
    }
  })
}

module.exports = function store(state, emitter) {
  function loadSceneFromProjectData(sceneToLoad, vm) {
    state.addedParts = []
    state.glitchApplications = []
    return vm.loadProject(sceneToLoad.sb3).then(() => {
      vm.runtime.greenFlag()
      initializePrototypes(vm)
      if (sceneToLoad.snapshot) {
        return state.vm.loadProject(sceneToLoad.sb3).then(() => {
          const addPartPromises = []
          sceneToLoad.snapshot.stateData.addedParts.forEach(addedPartEntry => {
            const partToAdd = state.availableParts.find(part => part.id === addedPartEntry.partId)
            if (partToAdd) {
              addPartPromises.push(addPartToVM(partToAdd, state.vm, { name: addedPartEntry.spriteName }))
            }
          })
          return Promise.all(addPartPromises).then(addedParts => {
            const snapshotDeserialized = deserializeSnapshot(sceneToLoad.snapshot, state.vm)
            sceneToLoad.snapshotDeserialized = snapshotDeserialized
            state.currentScene = sceneToLoad
            loadSnapshot(snapshotDeserialized, state)
            state.levelCompleteMinimized = false
            state.levelComplete = false
            state.gameOverMinimized = false
            state.gameOver = false
            return sceneToLoad
          })
        })
      } else if (sceneToLoad.metadata) {
        return setupSceneFromMetadata(sceneToLoad.metadata, vm)
      }
    })
  }

  function setupSceneFromMetadata(metadata, vm) {
    log("setupSceneFromMetadata", metadata, vm)
    const addPartPromises = []
    if (!metadata.startingParts) {
      metadata.startingParts = []
    }
    metadata.startingParts.forEach(addedPartEntry => {
      const partToAdd = state.availableParts.find(part => part.id === addedPartEntry.partId)
      if (partToAdd) {
        addPartPromises.push(addPartToVM(partToAdd, vm, { name: addedPartEntry.spriteName }))
      }
    })
    return Promise.all(addPartPromises).then(addedParts => {
      const addGlitchPromises = []
      const BUILTIN_PHYSICS = state.availableGlitches.find(g => g.id === 'builtin_physics')
      const builtinGlitchPromises = []

      state.vm.runtime.targets.filter(t => t.isOriginal && !t.isStage).forEach(target => {
        builtinGlitchPromises.push(applyGlitch(BUILTIN_PHYSICS, target, vm))
      })
      addedParts.forEach(addedPart => {
        addedPart.target.sprite.name = addedPart.addedPartEntry.spriteName
      })
      Promise.all(builtinGlitchPromises).then(physicsResults => {
        state.glitchApplications = state.glitchApplications.concat(physicsResults)
        state.addedParts = addedParts.map(p => p.addedPartEntry)
        metadata.startingGlitches.forEach(startingGlitchEntry => {
          const glitchToApply = state.availableGlitches.find(
            glitch => startingGlitchEntry.glitchId === glitch.id)
          if (glitchToApply) {

            const glitchTarget = vm.runtime.targets.find(t => t.sprite.name === startingGlitchEntry.spriteName)

            addGlitchPromises.push(applyGlitch(glitchToApply, glitchTarget, vm))
          }
        })
        return Promise.all(addGlitchPromises).then(glitchResults => {
          state.glitchApplications = state.glitchApplications.concat(glitchResults)
          if (metadata.exposedVariables) {
            setExposedVariables(vm, metadata.exposedVariables)
          }
          if (metadata.spriteProperties) {
            setSpriteProperties(metadata, vm)
          }
          state.levelComplete = false
          state.levelCompleteMinimized = false
          state.gameOverMinimized = false
          state.gameOver = false
          return Promise.resolve(true)
        })
      })

    })
  }
  emitter.on('next-level', () => {
    console.log("next-level")
    emitter.emit('pause')
    // emitter.emit('go-to-next-level')
    state.levelComplete = true
    emitter.emit('render')
  })

  emitter.on('continue-playing', () => {
    log('continue-playing')
    emitter.emit('play')
    state.levelCompleteMinimized = true
    state.gameOverMinimized = false
    emitter.emit('render')
  })

  emitter.on('go-to-next-level', () => {
    state.levelComplete = false
    let index = state.project.scenes.indexOf(state.currentScene)
    index++
    if (index > state.project.scenes.length - 1) {
      index = 0
    }
    emitter.emit('pause')
    emitter.emit('load-scene', index)
  })
  emitter.on('load-scene', (sceneIndex, resetScene) => {
    const cachedValues = {}

    if (state.currentScene) {
      const variableChanges = state.getHistory().filter(h => h.actionType === 'change-variable')
      variableChanges.forEach(h => {
        if (h.metadata && h.metadata.targetId && h.metadata.variableId) {
          const target = state.vm.runtime.targets.find(t => t.id === h.metadata.targetId)
          const targetName = target.sprite.name
          const variableName = target.variables[h.metadata.variableId].name
          if (!cachedValues[targetName]) {
            cachedValues[targetName] = {}
          }
          cachedValues[targetName][variableName] = h.metadata.value
        }
      })
    }
    state.sceneIsLoading = true
    if (state.currentScene) {
      if (!state.currentHistoryItem) {
        emitter.emit('push-history', 'switch-scenes')
      } else {
        state.getHistory().push(state.currentHistoryItem)
      }
    }
    emitter.emit('pause')
    log('load-scene', sceneIndex)
    const sceneToLoad = state.project.scenes[sceneIndex]
    emitter.emit('deselect-all')
    if (sceneToLoad) {
      const penSkin = state.vm.runtime.renderer._allSkins.find(skin => skin.constructor.name === 'PenSkin')
      if (penSkin) {
        state.vm.runtime.renderer.penClear(penSkin._id)
      }
      if (state.histories[sceneToLoad.metadata.id] &&
        state.histories[sceneToLoad.metadata.id].length > 0) {
        if (resetScene) {
          //the first history item is the initial state of the scene
          state.histories[sceneToLoad.metadata.id].splice(1)
        }
        state.currentScene = sceneToLoad
        sanitizeSelections(state)
        emitter.emit('pop-history')
        emitter.emit('deselect-all')
        state.sceneIsLoading = false
        filterGlitches()
        state.future = []
        setTimeout(() => emitter.emit('render'), 20)
        console.log("loaded from history")
        console.log("cachedValues", cachedValues)
        resetVariables(cachedValues)
   
      } else {
        loadSceneFromProjectData(state.project.scenes[sceneIndex], state.vm).then(sceneData => {
          state.currentScene = sceneToLoad
          filterGlitches()
          state.future = []
          emitter.emit('load-complete')
          state.sceneIsLoading = false
          setTimeout(() => emitter.emit('render'), 20)
          console.log("loaded from project")
          resetVariables(cachedValues)
        })
      }
    } else {
      throw (Error(`Scene ${sceneIndex} not found`))
    }
  })

  emitter.on('load-complete', () => {
    handleHiddenTargets()
    emitter.emit('add-document-event-listeners')
    clearIoDevices(state.vm.runtime)
    emitter.emit('deselect-all')
    state.vm.runtime.currentStepTime = 1
    state.loadedVM = true
    emitter.emit('render')
    //  emitter.emit('play')
  })

  function resetVariables(cachedValues) {
    Object.entries(cachedValues).forEach(([targetName, variables]) => {
      console.log("targetName", targetName, "variables", variables)
      const target = state.vm.runtime.targets.find(t => t.sprite.name === targetName)
      if (!target) {
        return
      }
      Object.entries(variables).forEach(([variableName, value]) => {
        const variable = Object.values(target.variables).find(v => v.name === variableName)
        if (!variable) {
          return
        }
        emitter.emit('set-variable', target, variable, value)
        emitter.emit('stop-changing-variable')
      })
    })
  }

  function filterGlitches() {
    state.glitchApplications = state.glitchApplications.filter(g => state.availableGlitches.find(ag => ag.id === g.glitchId))
    state.toolbar.glitches = state.toolbar.glitches.filter(g => state.availableGlitches.find(ag => ag.id === g))
  }
  function handleHiddenTargets() {
    state.hiddenTargets.forEach(t => {
      const target = state.vm.runtime.targets.find(target => target.id === t.id)
      if (target) {
        if (state.isFullscreen) {
          target.setVisible(false)
          target.renderer.draw()
          target.effects.ghost = t.ghost
        } else {
          target.visible = true
          target.effects.ghost = 85
        }
      }
    })
  }
}
