const html = require('choo/html')

module.exports = function DraggingElement (state) {
  if (!state.draggingElement) {
    return null
  }
  const { left, top } = state.draggingPosition
  return html`
    <div
      id="dragging-element"
      class="${state.draggingElement ? 'dragging' : ''}"
      style="top:${top}px;left:${left}px;"
      >
      ${state.draggingElement}
    </div>
  `
}
