module.exports = {
  "id": "truck",
  "title": "truck",
  "image": "provision/parts/truck/truck.svg",
  "info": [
    {
      "text": "Add a truck to your game",
      "image": "provision/parts/truck/truck.gif"
    }
  ],
  "exposedVariables": []
};