module.exports = {
  "id": "tile_movement",
  "title": "tile movement",
  "color": "pink",
  "icon": "expand",
  "description": "Move a sprite around on a grid",
  "category": "powers",
  "exposedVariables": [
    {
      "variableName": "left key",
      "spriteName": "$glitch_target",
      "value": "left arrow",
      "input": {
        "type": "key",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      }
    },
    {
      "variableName": "up key",
      "spriteName": "$glitch_target",
      "value": "up arrow",
      "input": {
        "type": "key",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      }
    },
    {
      "variableName": "down key",
      "spriteName": "$glitch_target",
      "value": "down arrow",
      "input": {
        "type": "key",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      }
    },
    {
      "variableName": "right key",
      "spriteName": "$glitch_target",
      "value": "right arrow",
      "input": {
        "type": "key",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      }
    },
    {
      "variableName": "tile walk speed",
      "spriteName": "$glitch_target",
      "value": 2,
      "input": {
        "type": "slider",
        "max": "20",
        "step": ".1",
        "min": "0"
      }
    },
    {
      "variableName": "stop after move?",
      "spriteName": "$glitch_target",
      "value": "true",
      "input": {
        "type": "boolean",
        "options": [
          {
            "label": "true",
            "value": "true"
          },
          {
            "label": "false",
            "value": "false"
          }
        ]
      }
    },
    {
      "variableName": "tile size",
      "spriteName": "$glitch_target",
      "value": 17,
      "input": {
        "type": "slider",
        "max": 50,
        "step": 1,
        "min": "1"
      }
    },
    {
      "variableName": "is bridge?",
      "spriteName": "$other_sprites",
      "value": "false",
      "input": {
        "type": "boolean",
        "options": [
          {
            "label": "true",
            "value": "true"
          },
          {
            "label": "false",
            "value": "false"
          }
        ]
      }
    },
    {
      "spriteName": "$other_sprites",
      "variableName": "is wall?",
      "input": {
        "type": "boolean",
        "options": [],
        "min": -999,
        "max": 999
      },
      "value": "true"
    }
  ],
  "info": [
    {
      "text": "Move a sprite around on a grid.  Set 'AI' to 'true' to move automatically.",
      "image": "provision/glitches/tile_movement/tile_movement.gif"
    }
  ],
  "codeImage": "provision/glitches/tile_movement/tile_movement.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/tile_movement/tile_movement.svg"
};