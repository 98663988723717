const { log } = console
const { getTargetAtClientPosition } = require('../shared_utils/coordinates.js')
module.exports = function store(state, emitter) {
  emitter.on('start-dragging-sprite-variable', variableId => {
    log('start-dragging-sprite-variable')
    state.draggingSpriteVariable = true
    state.selectedVariableId = variableId
    emitter.emit('render')
  })

  emitter.on('start-setting-sprite-variable', variableId => {
    log('start-setting-sprite-variable')
    state.settingSpriteVariable = true
    state.selectedVariableId = variableId
    emitter.emit('render')
  })

  emitter.on('stop-changing-variable', () => {
    log('stop-changing-variable')
    state.changingVariable = false
  })

  let timeout = null
  emitter.on('set-grid-layout-variable', (target, variable, value, instanceParam) => {
    log('set-grid-layout-variable', target, variable, value)
    target = target.sprite.clones[0]
    state.selectedTargetId = target.id
    if (target) {
      emitter.emit('set-variable', target, variable, value, instanceParam)
      const rows = Object.values(target.variables).find(v => v.name === '# of rows').value
      const columns = Object.values(target.variables).find(v => v.name === '# of columns').value
      const columnWidth = Object.values(target.variables).find(v => v.name === 'column width').value
      const rowHeight = Object.values(target.variables).find(v => v.name === 'row height').value

      const allClones = [...target.sprite.clones]
      allClones.forEach(c => {
        if (!c.isOriginal) {
          state.vm.runtime.targets = state.vm.runtime.targets.filter(t => t !== c)
          state.vm.runtime.executableTargets = state.vm.runtime.executableTargets.filter(t => t !== c)
          c.dispose()

        }
      })
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < columns; j++) {
          if (!(i === 0 && j === 0)) {
            const newClone = target.makeClone()
            newClone.setXY(target.x + j * columnWidth, target.y + i * -rowHeight)
            if (!state.vm.runtime.targets.includes(newClone)) {
              state.vm.runtime.targets.push(newClone)
            }
          }
        }
      }

    }
    state.vm.renderer.draw()
  })

  emitter.on('set-variable', (target, variable, value, instanceParam) => {
    log('set-variable', target, variable, value)
    const part = state.addedParts.find(p => p.spriteName === target.sprite.name)
    let variableToChange = variable
    if (part && part.partId === 'scoreboard') {
      if (variable.name === 'value') {
        const displayWhat = Object.values(target.variables).find(v => v.name === 'variable to display')
        if (displayWhat) {
          const stage = state.vm.runtime.targets.find(t => t.isStage)
          const displayVar = Object.values(stage.variables).find(v => v.name === displayWhat.value)
          if (displayVar) {
            state.vm.setVariableValue(stage.id, displayVar.id, value)
          }
        }
      }
    }
    if (!state.changingVariable) {
      emitter.emit('push-history', 'change-variable', {targetId: target.id, variableId: variable.id, value})
      state.changingVariable = true
    }
    if (instanceParam) {
      state.vm.setVariableValue(target.id, variableToChange.id, value)

    } else {
      target.sprite.clones.forEach(t => {
        state.vm.setVariableValue(t.id, variableToChange.id, value)
      })
    }
    emitter.emit('render')
  })

  emitter.on('set-selected-sprite-variable', e => {
    log('set-selected-sprite-variable', e)
    const clickTarget = getTargetAtClientPosition(
      e.clientX,
      e.clientY,
      state.vm
    )
    let selectedTarget = state.vm.runtime.targets.find(
      t => t.id === state.selectedTargetId
    )
    if (!selectedTarget) {
      selectedTarget = state.vm.runtime.getTargetForStage()
    }
    const variable = selectedTarget.variables[state.selectedVariableId]
    let value = 'none'
    if (clickTarget) {
      value = clickTarget.sprite.name
    }
    emitter.emit('set-variable', selectedTarget, variable, value)
    state.settingSpriteVariable = false
    state.draggingSpriteVariable = false
    state.selectedVariableId = null
    emitter.emit('render')
  })

  emitter.on('select-variable-page', index => {
    log('select-variable-page', index)
    state.currentVariablePage = index
    emitter.emit('render')
  })
}
