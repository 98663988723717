module.exports = {
  "id": "black hole",
  "title": "black hole",
  "icon": "blackhole",
  "color": "pink",
  "description": "Suck in other sprites when they get too close",
  "category": "powers",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "black hole radius",
      "input": {
        "type": "slider",
        "options": [],
        "min": "25",
        "max": 200,
        "step": "1"
      },
      "value": "75"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "black hole strength",
      "input": {
        "type": "slider",
        "options": [],
        "min": "1",
        "max": 10,
        "step": "1"
      },
      "value": "5"
    }
  ],
  "info": [
    {
      "text": "Suck in other sprites when they get too close",
      "image": "provision/glitches/black hole/black hole.gif"
    }
  ],
  "codeImage": "provision/glitches/black hole/black hole.png",
  "code": null,
  "zip": null,
  "sb3": null
};