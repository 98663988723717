const html = require('choo/html')
const Icon = require('./icon.js')

module.exports = function Disaplay (args) {
  const {
    icon = null,
    image = null,
    spriteImage = null,
    text = null,
    selected = false,
    size = 'medium',
    type = 'tight',
    color = 'mediumgrey',
    iconSize = '',
    iconAlign = '',
    onClick = () => false
  } = args

  let display = null
  if (image) {
    display = html`
    <span
      class="icon"
      style="
        background-size: cover;
        background-image: url('${image}');
        background-position: center;
        "
      >
    </span>
    `
  } else if (icon) {
    display = html`<span class="icon ${iconSize} ${iconAlign}">
      ${Icon({ iconName: icon })}
    </span>`
  } else if (spriteImage) {
    // TODO: consider if this is the best way to display the sprite image here
    display = html`
    <span class = "icon">
      <img style = "
        display:block; 
        max-width: 100%; 
        height: 100%;
      " 
      src = ${spriteImage}>
      </img>
    </span>`
  }
  const selectedClass = selected ? 'selected' : ''
  return html`
    <button
      onclick=${onClick}
      class="${type} ${size} ${color} ${selectedClass} has-icon"
      >
      ${display}
            <span class="text ${iconAlign}">${text}</span>


    </button>
  `
}
