module.exports = {
  "id": "glow_when_hit",
  "color": "red",
  "icon": "glow",
  "title": "glow when hit",
  "description": "Glow when hit",
  "category": "magic",
  "exposedVariables": [
    {
      "variableName": "Glow Time",
      "spriteName": "$glitch_target",
      "value": ".2",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "5",
        "step": ".1"
      }
    }
  ],
  "info": [
    {
      "text": "Glow when touched by any other sprite.",
      "image": "provision/glitches/glow_when_hit/glow_when_hit.gif"
    }
  ],
  "codeImage": "provision/glitches/glow_when_hit/glow_when_hit.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/glow_when_hit/glow_when_hit.svg"
};