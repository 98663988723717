/**
 * Scratch API for generating project and asset URLS from the scratch server.
 * @module store/utils/scratch-api
 */

// TODO move project fetching methods here in a future iteration
const ASSET_SERVER = 'https://assets.scratch.mit.edu/'
const PROJECT_SERVER = 'https://projects.scratch.mit.edu/'
const INTERNAL_ASSET_PATH = 'internalapi/asset/'

/**
 * This is pulled from scratch-vm/playground/benchmark.js
 * @param {Asset} asset - calculate a URL for this asset.
 * @returns {string} a URL to download a project file.
 */
function getProjectUrl (asset) {
  const assetIdParts = asset.assetId.split('.')
  const assetUrlParts = [ PROJECT_SERVER, assetIdParts[0] ]
  if (assetIdParts[1]) {
    assetUrlParts.push(assetIdParts[1])
  }
  return assetUrlParts.join('')
}

/**
 * This is pulled from scratch-vm/playground/benchmark.js
 * @param {Asset} asset - calculate a URL for this asset.
 * @returns {string} a URL to download a project asset (PNG, WAV, etc.)
 */
function getAssetUrl (asset) {
  const assetUrlParts = [
    ASSET_SERVER,
    INTERNAL_ASSET_PATH,
    asset.assetId,
    '.',
    asset.dataFormat || asset.assetType.runtimeFormat,
    '/get/'
  ]
  return assetUrlParts.join('')
}

function getProjectUrlById (id) {
  return `${PROJECT_SERVER}${id}`
}

module.exports = {
  ASSET_SERVER,
  PROJECT_SERVER,
  INTERNAL_ASSET_PATH,
  getProjectUrl,
  getAssetUrl,
  getProjectUrlById
}
