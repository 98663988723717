const { log } = console

module.exports = function store (state, emitter) {
  emitter.on('pick-and-replace-glitch', glitchId => {
    const i = state.toolbar.glitches.findIndex(id => id === glitchId)
    state.toolbar.glitches[i] = state.selectedGlitchId
    emitter.emit('render')
  })
  emitter.on('pick-and-replace-part', partId => {
    const i = state.toolbar.parts.findIndex(id => id === partId)
    state.toolbar.parts[i] = state.selectedPartId
    emitter.emit('render')
  })

  emitter.on('start-picking-glitch', glitchId => {
    log('start-picking-glitch')
    state.pickingGlitch = true
    emitter.emit('select-glitch', glitchId)
  })
  emitter.on('start-picking-part', partId => {
    log('start-picking-part')
    state.pickingPart = true
    emitter.emit('select-part', partId)
  })

  emitter.on('pick-glitch', () => {
    log('pick-glitch')
    state.pickingGlitch = false
    const glitch = state.availableGlitches.find(
      g => g.id === state.selectedGlitchId
    )
    state.toolbar.glitches.push(glitch.id)
    emitter.emit('render')
  })
  emitter.on('pick-part', () => {
    log('pick-part')
    state.pickingPart = false
    const part = state.availableParts.find(
      g => g.id === state.selectedPartId
    )
    state.toolbar.parts.push(part.id)
    emitter.emit('render')
  })
  emitter.on('load-pack-to-toolbar', packId => {
    log('load-pack-to-toolbar', packId)
    const pack = state.availablePacks.find(p => p.id === packId)
    state.toolbar.glitches = pack.glitches
    state.toolbar.parts = pack.parts
    emitter.emit('render')
  })
}
