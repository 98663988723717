module.exports = {
  "id": "dizzy",
  "icon": "dizzy",
  "color": "green",
  "title": "dizzy",
  "info": [
    {
      "text": "Spin around",
      "image": "provision/glitches/dizzy/dizzy.gif"
    }
  ],
  "category": "movement",
  "exposedVariables": [
    {
      "value": 5,
      "variableName": "Dizzy Speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -30,
        "max": 30
      }
    }
  ],
  "codeImage": "provision/glitches/dizzy/dizzy.png",
  "code": null,
  "zip": null,
  "sb3": null
};