module.exports = {
  "id": "cartwheel",
  "title": "cartwheel",
  "color": "pink",
  "icon": "cartwheel",
  "category": "powers",
  "info": [
    {
      "text": "Press a key to make your target sprite do a cartwheel",
      "image": "provision/glitches/cartwheel/cartwheel.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": "space",
      "variableName": "cartwheel trigger",
      "spriteName": "$glitch_target",
      "input": {
        "type": "option",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "cartwheel direction",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "left",
            "label": "left"
          },
          {
            "value": "right",
            "label": "right"
          },
          {
            "value": "random",
            "label": "random"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "random"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "cartwheel distance",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "100",
        "step": "1"
      },
      "value": "40"
    }
  ],
  "codeImage": "provision/glitches/cartwheel/cartwheel.png",
  "code": null,
  "zip": null,
  "sb3": null
};