const { deserializeSnapshot } = require('./import-export-helpers')
const { saveSnapshot } = require('./save-snapshot')

function loadSnapshot (snapshot, state) {
  const { stateData, vmData } = snapshot
  state.selectedGlitchApplication = null

  state.vm.runtime.targets.forEach(t => {
    state.vm.runtime.stopForTarget(t)
    state.vm.runtime.disposeTarget(t)
  })

  state.vm.runtime.targets = vmData.targets
  state.vm.runtime.executableTargets = vmData.executableTargets
  state.vm.runtime.threads = vmData.threads

  const msDelta = state.vm.runtime.currentMSecs - vmData.currentMSecs

  vmData.threads.forEach(t => {
    t.stackFrames.forEach(f => {
      if (f.executionContext && f.executionContext.timer) {
        f.executionContext.timer.startTime -= msDelta
//        if (f.exec)
      }
    })
  })
  state.vm.runtime.ioDevices.clock._projectTimer.startTime += msDelta
  vmData.targetLayers.forEach(targetId => {
    if (targetId) {
      const target = state.vm.runtime.targets.find(t => t.id === targetId)
      if (target.isStage) {
        target.drawableID = state.vm.renderer.createDrawable('background')
      } else {
        target.drawableID = state.vm.renderer.createDrawable('sprite')
      }
    }
  })

  state.vm.runtime.targets.forEach(t => {
    if (t.currentCostume >= t.sprite.costumes.length){
      t.currentCostume = 0
    }
    t.updateAllDrawableProperties()
  })

  state.vm.runtime.targets.filter(t => t.isOriginal && t.sprite.clones.length > 1).forEach(target => {
    Object.values(target.variables).forEach(variable => {
      const cloneVar = target.sprite.clones[1].variables[variable.id]
      if (!cloneVar){
        target.sprite.clones.forEach(clone => {
          if (variable.type === 'list'){
          clone.lookupOrCreateList(variable.id, variable.name).value = variable.value
          } else {
            clone.lookupOrCreateVariable(variable.id, variable.name).value = variable.value
            
          }
        })
      }
    })
  })
  state.vm.runtime.renderer.draw()
  state.effectsCache = vmData.effectsCache
  state.glitchApplications = vmData.glitchApplications
  state.addedParts = vmData.addedParts
  Object.assign(state, stateData)

  /*
   * Loading the snapshot connects it to the vm so we need to replace it
   * with a fresh unconnecte snapshot if we want to browse forward on the
   * timeline
   */

  
     const newSnapshot = saveSnapshot(state)
    snapshot.vmData = newSnapshot.vmData
    snapshot.stateData = newSnapshot.stateData
   
  state.vm.renderer.draw()
}
exports.loadSnapshot = loadSnapshot
