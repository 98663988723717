module.exports = {
  "id": "brick",
  "title": "brick",
  "exposedVariables": [
    {
      "value": "colorful",
      "variableName": "color mode",
      "spriteName": "brick",
      "input": {
        "type": "option",
        "options": [
          {
            "label": "colorful",
            "value": "colorful"
          },
          {
            "label": "grayscale",
            "value": "grayscale"
          }
        ]
      },
      "id": "Y.vq9_1v/go9YdG70LcZ"
    },
    {
      "value": 1,
      "variableName": "blink probability",
      "spriteName": "brick",
      "input": {
        "type": "slider",
        "min": 0,
        "max": 5,
        "step": 0.01
      },
      "id": "{Pu0I(yMRZ5;)jzTSn$%"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/brick/brick.svg"
};