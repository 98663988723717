const html = require('choo/html')

module.exports = function HighlightSprite (args) {
  const {
    width = 0,
    height = 0,
    top = 0,
    left = 0,
    color = 'purple',
    onMouseDown = null,
    onClick = null
  } = args || {}
  const style = `
      top:${top}%;
      left:${left}%;
      width:${width}%;
      height:${height}%;
      background: ${color}; 
      opacity:0.2;
      pointer-events: ${onMouseDown || onClick ? 'auto' : 'none'}
    `

  return html`
    <div class="highlight-sprite ${color}" 
      style=${style} 
      onmousedown=${onMouseDown}
      onclick = ${onClick}>
    </div>`
}
