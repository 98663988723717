const GlitchContext = require('./glitchcontext.js')
const PartContext = require('./partcontext.js')
const GameContext = require('./gamecontext.js')
const CodeEditorContext = require('./code-editor-context.js')
const ScreenTitle = require('./screentitle.js')
const ChipElement = require('../elements/chip.js')
const ButtonIcon = require('../elements/buttonicon.js')
module.exports = function ContextMenu (state, emit) {
  if (state.isFullscreen){
    const disabled = state.isMenuOpen || state.levelComplete || state.gameOver
    const chipElement =  ChipElement({
      stretch:true,
      children: [
        ButtonIcon({
          disabled,
          icon: state.isGamePlaying ? 'pause' : 'play',
          onClick: () => emit('toggle-play')
        }),
        ButtonIcon({
          disabled,
          icon: state.masterVolume === 0 ? 'mute' : 'unmute',
          onClick: () => emit('toggle-mute')
        }),
        ButtonIcon({
          icon: state.isMenuOpen ? 'cross' : 'hamburger',
          onClick: () => emit('toggle-main-menu')
        })
      ]
    })
    return [
    ScreenTitle(state, emit),
    GameContext(state, emit),
    chipElement
    
    ]
  }

  if (state.glitchEditorOpen) {
    return CodeEditorContext(state, emit)
  }
  if (state.selectedGlitchId) {
    return GlitchContext(state, emit)
  }
  if (state.selectedPartId) {
    return PartContext(state, emit)
  }
  if (state.isInventoryOpen) {
    return null
  }
  return GameContext(state, emit)
}
