const html = require('choo/html')
const ButtonIcon = require('../elements/buttonicon.js')
const ButtonImage = require('../elements/buttonimage.js')
const inventorypacks = require('./inventorypacks.js')
const Button = require('../elements/button.js')
module.exports = function InventoryItems(state, emit) {
  if (state.selectedInventoryCategory === 'packs') {
    return inventorypacks(state, emit)
  }
  let toolbarSection
  let items = []
  let type = 'square'
  let ButtonFactory
  let event
  function filter(item) {
    const title = item.title.toLowerCase()
    const term = state.inventoryFilter.toLowerCase()
    return title.indexOf(term) !== -1
  }
  if (state.selectedInventoryCategory === 'glitch') {
    ButtonFactory = ButtonIcon
    event = 'start-picking-glitch'
    toolbarSection = state.toolbar.glitches
    if (state.currentScene.metadata.allowedGlitches) {
      items = state.availableGlitches.filter(
        g => state.currentScene.metadata.allowedGlitches.includes(g.id)
      )
      items = items.filter(filter)
    } else {
      items = state.availableGlitches.filter(filter)
    }
  } else if (state.selectedInventoryCategory === 'part') {
    ButtonFactory = ButtonImage
    event = 'start-picking-part'
    type = 'circle'
    toolbarSection = state.toolbar.parts
    if (state.currentScene.metadata.allowedParts) {
      items = state.availableParts.filter(
        i => state.currentScene.metadata.allowedParts.includes(i.id)
      )
      items = items.filter(filter)
    } else {
      items = state.availableParts.filter(filter)
    }
  }
  let categoryButtons = null
  const subcategories = state.glitchCategories
  if (state.selectedInventoryCategory === 'glitch') {
    if (!state.selectedGlitchCategories.includes('all')){
      items = items.filter(i => state.selectedGlitchCategories.includes(i.category))
    } else {
      items = items.filter(i => i.category !== 'builtin')
    }
    items = items.sort((a, b) => !a.category.localeCompare(b.category))
    categoryButtons = subcategories.map(subcategory => Button({
      selected: state.selectedGlitchCategories.includes(subcategory.id),
      text: subcategory.id,
      color: subcategory.color,
      onMouseDown: () => {
        emit('toggle-glitch-category', subcategory.id)
      }
    }))
    categoryButtons.push(Button({
      selected: state.selectedGlitchCategories.includes('all'),
      text: 'all',
      color: 'lightgrey',
      onMouseDown: () => {
        emit('toggle-glitch-category', 'all')
      }
    }))
    
  }
 
  items = items.map(
    item => ButtonFactory({
      disabled: toolbarSection.find(itemId => itemId === item.id),
      selected: state.selectedGlitchId === item.id,
      size: 'big',
      icon: item.icon,
      image: item.image,
      type,
      color: item.color,
      pickable: true,
      tooltip: item.title,
      onMouseDown: e => {
        const mini = ButtonFactory({
          icon: item.icon,
          color: item.color,
          image: item.image,
          type
        })
        emit('start-drag', mini, e)
        emit(event, item.id)
      }
    })
  )

  if (categoryButtons) {
    return html`
      <div class="grid-8 inventory-items">
          <div class="column-2 categories">${categoryButtons}</div>
      <div class="column-6 items">${items}</div>
      </div>
    `
  }
  return html`
      <div class="grid-8 inventory-items">
          <div class="column-1 categories"></div>
      <div class="column-6 items">${items}</div>
      <div class="column-1 categories"></div>
      </div>
    `
}
