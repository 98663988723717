const DEFAULT_STEP = 0.1
const CHARACTER_LENGTH = 11
const html = require('choo/html')
// const Component = require('choo/component')

function map (n, start1, stop1, start2, stop2) {
  return ((n - start1) * (stop2 - start2) / (stop1 - start1)) + start2
}

module.exports = function InputSlider (args) {
  const {
    label,
    value = 0,
    min = 0,
    max = 100,
    step = DEFAULT_STEP,
    disabled = false,
    selected = false,
    onInput = () => false,
    onChange = () => false
  } = args

  this.disabled = disabled
  const disabledClass = disabled ? 'disabled' : ''
  const selectedClass = selected ? 'selected' : ''
  const w = map(value, min, max, 0, 100)
  const roundedValue = Math.round(value * 100) / 100
  const textFieldWidth = (roundedValue.toString().length) * CHARACTER_LENGTH
  return html`
      <div class="input input-slider ${disabledClass}">
        <label>${label}</label>
        <div class="input-area ${selectedClass}">
          <div class="foreground" style="width: ${w}%"></div>
          <input
            type="range"
            oninput=${onInput}
            onchange=${onChange}
            min=${min}
            max=${max}
            step=${step}
            value=${value}
            onmouseup=${e => e.target.blur()}
          />
          <input type = "number" oninput= ${onInput} 
          onchange=${onChange} 
          class="value" 
          max="9999"
          style="width:${textFieldWidth}rem;"
          value="${roundedValue}"></input>
        </div>
      </div>
    `
}

