module.exports = {
  "id": "teleport",
  "title": "teleport",
  "icon": "teleport",
  "color": "pink",
  "description": "Press SPACE to teleport to a random location",
  "category": "powers",
  "exposedVariables": [
    {
      "variableName": "teleport key",
      "spriteName": "$glitch_target",
      "input": {
        "type": "key",
        "value": "space",
        "options": [
          {
            "label": "left arrow",
            "value": "left arrow"
          },
          {
            "label": "right arrow",
            "value": "right arrow"
          },
          {
            "label": "up arrow",
            "value": "up arrow"
          },
          {
            "label": "down arrow",
            "value": "down arrow"
          },
          {
            "label": "space",
            "value": "space"
          },
          {
            "label": "w",
            "value": "w"
          },
          {
            "label": "a",
            "value": "a"
          },
          {
            "label": "s",
            "value": "s"
          },
          {
            "label": "d",
            "value": "d"
          },
          {
            "label": "f",
            "value": "f"
          }
        ]
      },
      "value": "space"
    }
  ],
  "info": [
    {
      "text": "Press SPACE to teleport to a random location",
      "image": "provision/glitches/teleport/teleport.gif"
    }
  ],
  "codeImage": "provision/glitches/teleport/teleport.png",
  "code": null,
  "zip": null,
  "sb3": null
};