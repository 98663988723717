const html = require('choo/html')
const PanelElement = require('../elements/panel.js')
const CarouselElement = require('../elements/carousel.js')
const InputOption = require('../elements/inputoption.js')
const InputText = require('../elements/inputtext.js')
const Button = require('../elements/button.js')
const SPRITE_NAMES = [
  {
    label: '$glitch_target',
    value: '$glitch_target'
  }, {
    label: '$other_sprites',
    value: '$other_sprites'
  },
  {
    label: 'Stage',
    value: 'Stage'
  }
]
const INPUT_TYPES = [
  {
    label: 'text',
    value: 'text'
  }, {
    label: 'slider',
    value: 'slider'
  },
  {
    label: 'option',
    value: 'option'
  },
  {
    label: 'sprite',
    value: 'sprite'
  },
  {
    label: 'key',
    value: 'key'
  },
  {
    label: 'boolean',
    value: 'boolean'
  }

]
const itemsPerPage = 1
function paginateInputs (inputs) {
  const pages = []
  let pageIndex = 0
  pages[0] = []
  for (let i = 0; i < inputs.length; i++) {
    if (pages[pageIndex].length === itemsPerPage) {
      pageIndex += 1
      pages[pageIndex] = []
    }
    pages[pageIndex].push(inputs[i])
  }

  const items = pages.map(p => html`<div class="info">${p}</div>`)
  return items
}
function VariableNameInput (variable, variableOptions, emit) {
  const selectedVariable = variableOptions.findIndex(
    o => o.value === variable.variableName
  )
  const options = [...variableOptions]
  return InputOption({
    label: 'Variable to Expose',
    options,
    selectedItem: selectedVariable,
    onChange: index => {
      emit(
        'glitch-editor-pick-variable',
        variable,
        options[index].value
      )
    }
  })
}
function VariableTypeInput (variable, emit) {
  const selectedType = INPUT_TYPES.findIndex(
    i => i.value === variable.input.type
  )
  return InputOption({
    options: INPUT_TYPES,
    label: 'Input Type',
    selectedItem: selectedType,
    onChange: index => {
      emit(
        'glitch-editor-pick-type',
        variable,
        INPUT_TYPES[index].value
      )
    }
  })
}

function VariableValueInput (variable, emit) {
  return InputText({
    label: 'Starting Value',
    value: variable.value,
    onInput: e => {
      emit(
        'glitch-editor-set-variable-value',
        variable,
        e.target.value
      )
    }
  })
}

function SpriteNameInput (variable, emit) {
  let selectedSpriteName = SPRITE_NAMES.findIndex(
    i => i.value === variable.spriteName
  )
  if (selectedSpriteName === -1) {
    selectedSpriteName = 0
  }
  return InputOption({
    options: SPRITE_NAMES,
    label: 'SpriteName',
    selectedItem: selectedSpriteName,
    onChange: index => {
      emit(
        'glitch-editor-pick-sprite',
        variable,
        SPRITE_NAMES[index].value
      )
    }
  })
}


function SliderParamsInput (param, variable, emit) {
  return InputText({
    value: variable.input[param],
    label: param,
    onInput: e => {
      emit(
        'glitch-editor-set-slider-param',
        variable,
        e.target.value,
        param
      )
    }
  })
}

function OptionChoiceInput (variable, optionChoice, emit, index) {
  let label = ''
  if (index === 0) {
    label = 'options'
  }
  return InputText({
    value: optionChoice.value,
    label,
    onChange: e => {
      emit(
        'glitch-editor-set-option-choice',
        variable,
        optionChoice,
        e.target.value
      )
    }
  })
}

module.exports = function CodeEditorVariablesPanel (state, emit) {
  let children = []
  let inputs = []
  let color = 'purple'
  let {selectedGlitchId} = state
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    selectedGlitchId = glitchApplication.glitchId
  }
  if (selectedGlitchId) {
    const glitch = state.availableGlitches.find(
      g => g.id === selectedGlitchId
    );
    ({color} = glitch)
  }
  if (state.glitchEditorContentLoaded) {
    let variablesToDisplay = []
    let targetVariables = []

    if (state.glitchEditorVariables) {
      variablesToDisplay = [...state.glitchEditorVariables]
      // alt variables?  what if it's not a glitch?

      state.glitchEditor.vm.runtime.targets.forEach(t => {
        targetVariables = targetVariables.concat(Object.values(t.variables))
      })
    }

    // Generate input elements
    inputs = variablesToDisplay.map(variable => {
      const variableOptions = targetVariables.map(
        v => ({label: v.name, value: v.name})
      )

      const nameInput = VariableNameInput(variable, variableOptions, emit)
      const typeInput = VariableTypeInput(variable, emit)
      let spriteNameInput = null
      if (state.selectedGlitchApplicationId) {
        spriteNameInput = SpriteNameInput(variable, emit)
      }
      const valueInput = VariableValueInput(variable, emit)
      const variableParams = [
        nameInput,
        spriteNameInput,
        valueInput,
        typeInput
      ]
      let inputParams = []
      if (variable.input.type === 'slider') {
        const sliderMinInput = SliderParamsInput('min', variable, emit)
        const sliderMaxInput = SliderParamsInput('max', variable, emit)
        const sliderStepInput = SliderParamsInput('step', variable, emit)
        inputParams = [ sliderMinInput, sliderMaxInput, sliderStepInput ]
      }
      if (variable.input.type === 'option') {
        inputParams = variable.input.options.map(
          (i, index) => OptionChoiceInput(variable, i, emit, index)
        )
        inputParams.push(
          InputText({
            value: 'new',
            greyedOut: true,
            onClick: () => emit('glitch-editor-add-new-option', variable)
          })
        )
      }
      inputParams.push(new Button({
        text: 'hide this variable',
        onClick: () => emit('glitch-editor-hide-variable', variable)
      }))
      return variableParams.concat(inputParams)
    })
    inputs.push(new Button({
      text: 'expose new variable',
      onClick: () => emit('glitch-editor-expose-variable')
    }))
    children = CarouselElement({
      selectedItem: state.currentVariablePage,
      items: paginateInputs(inputs),
      disabled: state.selectedVariableId !== null
        || state.isMenuOpen,
      onChange: state.selectedVariableId ? (
        () => false) : index => emit('select-variable-page', index)
    })
  }
  const panel = PanelElement({
    color,
    children
  })

  panel.classList.add('code-editor-panel')
  return panel
}
