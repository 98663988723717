module.exports = {
  "id": "collectible",
  "title": "Collectible",
  "color": "blue",
  "info": [
    {
      "text": "Make a sprite disappear when touched by another sprite.",
      "image": "provision/glitches/collectible/collectible.gif"
    }
  ],
  "icon": "pacman",
  "category": "rules",
  "exposedVariables": [
    {
      "value": "none",
      "variableName": "collector sprite",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite"
      }
    },
    {
      "value": "10",
      "variableName": "point value",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "step": 1,
        "min": -50,
        "max": 50
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "scoring player",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "none",
            "label": "none"
          },
          {
            "value": "1",
            "label": "1"
          },
          {
            "value": "2",
            "label": "2"
          }
        ],
        "min": "1",
        "max": "2",
        "step": "1"
      },
      "value": "1"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "collect all to win?",
      "input": {
        "type": "boolean"
      },
      "value": "false"
    }
  ],
  "codeImage": "provision/glitches/collectible/collectible.png",
  "code": null,
  "zip": null,
  "sb3": null
};