const uuid = require('./uuid.js')
const { log } = console
/*
 * source:
 * https://github.com/LLK/scratch-vm/blob/develop/src/util/new-block-ids.js
 * using eslint-disable in this function in order to leave it as a direct
 * copy of the original.
 */
function newBlockIds(blocks) {
  const oldToNew = {}
  // First update all top-level IDs and create old-to-new mapping
  for (let i = 0; i < blocks.length; i++) {
    const newId = uuid()
    const oldId = blocks[i].id
    // eslint-disable-next-line no-multi-assign
    blocks[i].id = oldToNew[oldId] = newId
  }

  /*
   * Then go back through and update inputs (block/shadow)
   * and next/parent properties
   */
  for (let i = 0; i < blocks.length; i++) {
    for (const key in blocks[i].inputs) {
      const input = blocks[i].inputs[key]
      input.block = oldToNew[input.block]
      input.shadow = oldToNew[input.shadow]
    }
    if (blocks[i].parent) {
      blocks[i].parent = oldToNew[blocks[i].parent]
    }
    if (blocks[i].next) {
      blocks[i].next = oldToNew[blocks[i].next]
    }
  }
}

function copyVariables(source, destination, exposedVariables) {
  const variableNames = []
  let variablesArray = Object.keys(source.variables)
  if (source.lists) {
    const listsArray = Object.keys(source.lists)
    variablesArray = variablesArray.concat(listsArray)
  }
  let isList = false
  variablesArray.forEach(variableId => {
    let variable = source.variables[variableId]
    if (!variable) {
      variable = source.lists[variableId]
      isList = true
    }
    let varName, varValue, varType
    if (!variable.type) {
      if (variable.name) {
        varName = variable.name
        varValue = variable.value
        varType = variable.type
      } else {
        ([varName, varValue] = variable)
        varType = isList ? 'list' : ''
      }

      const varId = uuid()
      destination.sprite.clones.forEach(c => {
        const existingVar = Object.values(c.variables).find(
          v => v.name === varName
        )
        let exposedVariable = null
        if (exposedVariables) {
          exposedVariable = exposedVariables.find(
            ev => ev.spriteName === source.name
              && ev.variableName === varName
          )
        }
        let glitchVar
        if (existingVar) {
          glitchVar = existingVar
        } else {
          if (varType === 'list') {
            glitchVar = c.lookupOrCreateList(varId, varName)
          } else {
            glitchVar = c.lookupOrCreateVariable(varId, varName)
          }
          glitchVar.value = varValue

          if (exposedVariable) {
            glitchVar.value = exposedVariable.value
          }
        }

        if (exposedVariable && exposedVariable.override) {
          glitchVar.value = exposedVariable.value
        }
      })

      variableNames.push({ name: varName, type: varType })
    }
  })

  giveClonesSameVariableIdsAsOriginal(destination)
  return variableNames
}

function copyVariablesToMany(source, destinations, exposedVariables) {
  const variableNames = []
  Object.keys(source.variables).forEach(variableId => {
    const variable = source.variables[variableId]
    const [varName, varValue] = variable
    const varId = uuid()
    variableNames.push(varName)
    destinations.forEach(c => {
      let glitchVar
      const existingVar = Object.values(c.variables).find(
        v => v.name === varName
      )
      if (existingVar) {
        glitchVar = existingVar
      } else {
        glitchVar = c.lookupOrCreateVariable(varId, varName)
        glitchVar.value = varValue
        const exposedVariable = exposedVariables.find(
          ev => ev.spriteName === source.name
            && ev.variableName === varName
        )
        if (exposedVariable) {
          glitchVar.value = exposedVariable.value
        }
      }
      giveClonesSameVariableIdsAsOriginal(c)
    })
  })
  return variableNames
}

function copyBlocks(source, destination, destinationVM, blocksToCopy) {
  const blockIds = []
  let blocks
  if (source.blocks._blocks) {
    blocks = JSON.parse(JSON.stringify(source.blocks._blocks))
  } else {
    const blocksCopy = JSON.parse(JSON.stringify(source.blocks))
    blocks = destinationVM.sb3.deserializeBlocks(blocksCopy)
  }
  let blocksArray = Object.values(blocks)
  if (blocksToCopy) {
    blocksArray = blocksArray.filter(b => blocksToCopy.includes(b.id))
  }
  newBlockIds(blocksArray)
  blocksArray.forEach(block => {
    destination.blocks.createBlock(block)
    fixVarReferences(block, destination)
    blockIds.push(block.id)
    if (block.opcode === 'event_whenflagclicked') {
      destination.sprite.clones.forEach(c => {
        c.runtime._pushThread(block.id, c)
      })
    }
  })
  return blockIds
}

function copyBlocksToMany(source, destinations, destinationVM) {
  const blockIds = []
  const blocksCopy = JSON.parse(JSON.stringify(source.blocks))
  const blocks = destinationVM.sb3.deserializeBlocks(blocksCopy)
  const blocksArray = Object.values(blocks)
  newBlockIds(blocksArray)
  blocksArray.forEach(block => {
    blockIds.push(block.id)
    destinations.forEach(destination => {
      destination.blocks.createBlock(block)
      if (block.opcode === 'event_whenflagclicked') {
        destination.sprite.clones.forEach(c => {
          c.runtime._pushThread(block.id, c)
        })
      }
    })
  })
  return blockIds
}

function applyGlitchById(
  glitchId,
  targetId,
  vm,
  glitchApplications,
  availableGlitches
) {
  const glitch = availableGlitches.find(
    g => g.id === glitchId
  )
  const target = vm.runtime.targets.find(
    t => t.id === targetId
  )
  const [original] = target.sprite.clones
  const previousApplication = glitchApplications.find(
    g => g.glitchId === glitchId
      && g.targetId === original.id
  )
  let glitchApplication
  if (previousApplication) {
    glitchApplication = previousApplication
  } else {
    glitchApplication = applyGlitch(glitch, original, vm)
    glitchApplications.push(glitchApplication)
  }
  // sanitizeVariables(state)
}

function applyGlitch(glitch, gameTarget, vm) {
  const glitchApplication = {
    id: uuid(),
    glitchId: glitch.id,
    targetId: gameTarget.id,
    broadcasts: [],
    targetBlockIds: [],
    otherSpritesBlockIds: [],
    targetVarNames: [],
    otherSpritesVarNames: [],
    stageVarNames: []
  }

  const glitchStage = glitch.code.targets.find(t => t.name === 'Stage')
  const glitchTargetSprite = glitch.code.targets.find(
    t => t.name === '$glitch_target'
  )
  const glitchOthers = glitch.code.targets.find(
    t => t.name === '$other_sprites'
  )
  const gameStage = vm.runtime.getTargetForStage()

  // Create broadcast variables
  Object.keys(glitchStage.broadcasts).forEach(broadcastId => {
    const broadcast = glitchStage.broadcasts[broadcastId]
    const existingBroadcast = gameStage.lookupBroadcastByInputValue(broadcast)
    if (!existingBroadcast) {
      gameStage.createVariable(broadcastId, broadcast, 'broadcast_msg', false)
    }
    glitchApplication.broadcasts.push(broadcast)
  })

  glitchApplication.stageVarNames = copyVariables(
    glitchStage,
    gameStage,
    glitch.exposedVariables
  )
  glitchApplication.targetVarNames =
    copyVariables(glitchTargetSprite, gameTarget, glitch.exposedVariables)

  /*
   * Inject code from $glitch_target sprite into glitch target
   * and start green flags
   */
  glitchApplication.targetBlockIds = copyBlocks(
    glitchTargetSprite,
    gameTarget,
    vm
  )

  if (glitchOthers) {
    const gameOthers = vm.runtime.targets.filter(
      t => t.sprite.name !== gameTarget.sprite.name
        && !t.isStage
    )
    glitchApplication.otherSpritesVarNames =
      copyVariablesToMany(glitchOthers, gameOthers, glitch.exposedVariables)
    glitchApplication.otherSpritesBlockIds =
      copyBlocksToMany(glitchOthers, gameOthers, vm)
  }
  const allTargets = vm.runtime.targets
  for (let i = 0; i < allTargets.length; i++) {
    const currTarget = allTargets[i]
    currTarget.blocks.updateAssetName(
      '$glitch_target',
      gameTarget.sprite.name,
      'sprite'
    )
  }
  if (glitch.variableOverrides) {
    glitch.variableOverrides.forEach(o => {
      let targets = []
      if (o.spriteName === '$glitch_target') {
        targets.push(gameTarget)
      } else if (o.spriteName === '$other_sprites') {
        targets = vm.runtime.targets.filter(t => t !== gameTarget)
      }

      targets.forEach(t => {
        const variable = Object.values(t.variables).find(
          v => v.name === o.name
        )
        if (variable) {
          variable.value = o.value
        }
      })
    })
  }
  return glitchApplication
}

function giveClonesSameVariableIdsAsOriginal(target) {
  const originalVariables = Object.values(target.sprite.clones[0].variables)
  const variables = Object.values(target.variables)
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i]
    const prevId = variable.id
    const originalVar = originalVariables.find(v => v.name === variable.name)
    if (!originalVar) {
      delete target.variables[variable.id]

    } else {
      variable.id = originalVar.id
      target.variables[variable.id] = variable
      if (variable.id !== prevId) {
        delete target.variables[prevId]
      }
    }
  }
}

function fixVarReferences(block, target) {
  let varOrListField = null
  let variable = null
  if (block.fields.VARIABLE) {
    varOrListField = block.fields.VARIABLE
    variable = Object.values(target.variables).find(
      v => v.name === varOrListField.value
    )
    if (!variable) {
      variable = target.lookupOrCreateVariable(uuid(), varOrListField.value)
    }
  } else if (block.fields.LIST) {
    varOrListField = block.fields.LIST
    variable = Object.values(target.variables).find(
      v => v.name === varOrListField.value
    )
    if (!variable) {
      variable = target.lookupOrCreateList(uuid(), varOrListField.value)
    }
  }
  if (varOrListField) {
    varOrListField.id = variable.id
  }
}

function removeGlitch(target, vm, glitchApplication) {
  log('remove glitch', target, glitchApplication)
  glitchApplication.targetBlockIds.forEach(blockId => {
    target.blocks.deleteBlock(blockId)
  })
  glitchApplication.otherSpritesBlockIds.forEach(blockId => {
    vm.runtime.targets.forEach(t => {
      t.blocks.deleteBlock(blockId)
    })
  })
}

module.exports = {
  applyGlitchById,
  applyGlitch,
  removeGlitch,
  copyBlocks,
  copyVariables,
  fixVarReferences,
  giveClonesSameVariableIdsAsOriginal
}
