const ButtonIcon = require('../elements/buttonicon.js')
const PanelElement = require('../elements/panel.js')
const html = require('choo/html')
module.exports = function SceneDropAreas (state, emit) {
  const trash = ButtonIcon({
    icon: 'trash',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('delete-scene', state.draggingSceneId)
  })

  const duplicate = ButtonIcon({
    icon: 'duplicate',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('duplicate-scene', state.draggingSceneId)
  })
  const dropZone = html`
  <div class="glitch-drop-areas-wrapper">
    ${duplicate}
    ${state.currentScene.metadata.id === state.draggingSceneId ? null : trash}
  </div>`
  return PanelElement({
    color: 'purple',
    size: 'small',
    noPadding: false,
    children: dropZone
  })
}
