module.exports = {
  "id": "random_walk",
  "color": "green",
  "title": "random walk",
  "icon": "shuffle",
  "category": "movement",
  "exposedVariables": [
    {
      "variableName": "walk speed",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": 50,
        "value": 8,
        "min": "1",
        "step": ".1"
      },
      "value": "5"
    },
    {
      "variableName": "turn range",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "max": 180,
        "value": 50,
        "min": "1",
        "step": ".1"
      },
      "value": "30"
    },
    {
      "variableName": "bounce off edge?",
      "spriteName": "$glitch_target",
      "input": {
        "type": "boolean",
        "options": []
      },
      "value": "true"
    }
  ],
  "info": [
    {
      "text": "Make a sprite wander around the screen randomly.",
      "image": "provision/glitches/random_walk/random_walk.gif"
    }
  ],
  "codeImage": "provision/glitches/random_walk/random_walk.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/random_walk/random_walk.svg"
};