/* eslint-disable no-underscore-dangle */
const { log } = console
const BRIEF_INTERVAL = 10

function setCorrectTarget (state, emitter) {
  if (state.selectedGlitchApplicationId) {
    emitter.emit('select-glitch-application', state.selectedGlitchApplicationId)
  } else if (state.selectedTargetId) {
    emitter.emit('select-target', state.selectedTargetId)
  }
}

module.exports = function store (state, emitter) {
  let editorLoadedInterval = null
  function checkIfEditorLoaded () {
    if (state.glitchEditorOpen) {
      if (window.frames[0].glitchEditor
        && window.frames[0].glitchEditor.vm.runtime.targets[1]) {
        cleanupEditorLoadedInterval()
        state.glitchEditor = window.frames[0].glitchEditor
        emitter.emit('load-glitch-editor-content')
      } else if (!window.frames[0]) {
        cleanupEditorLoadedInterval()
      }
    } else {
      cleanupEditorLoadedInterval()
    }
  }
  function cleanupEditorLoadedInterval () {
    clearInterval(editorLoadedInterval)
    editorLoadedInterval = null
  }
  emitter.on('close-glitch-editor', () => {
    log('close-glitch-editor')
    state.glitchEditorOpen = false
    state.glitchEditor = null
    state.glitchEditorVariables = null
    if (state.selectedTargetId) {
      setTimeout(
        () => setCorrectTarget(state, emitter),
        BRIEF_INTERVAL
      )
    } else if (state.selectedGlitchId || state.selectedPartId) {
      state.isInventoryOpen = true
    }
    if (state.wasGamePlaying) {
      emitter.emit('play')
    }
    emitter.emit('render')
  })

  emitter.on('show-glitch-editor', () => {
    log('show-glitch-editor')
    if (editorLoadedInterval === null) {
      state.wasGamePlaying = state.isGamePlaying
      state.glitchEditorOpen = true
      state.isInventoryOpen = false
      emitter.emit('pause')
      state.glitchEditorContentLoaded = false
      editorLoadedInterval = setInterval(checkIfEditorLoaded, 100)
    }
  })
}
