module.exports = {
    "id": "transformer",
    "title": "transformer",
    "image": "provision/parts/transformer/transformer.svg",
    "info": [
        {
            "text": "Add a transformer to your game",
            "image": "provision/parts/transformer/transformer.gif"
        }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/transformer/transformer.png"
};