const html = require('choo/html')
const SelectSprite = require('../elements/selectsprite.js')
const ButtonIcon = require('../elements/buttonicon.js')
const { getNormalizedTargetBounds} = require('../../shared_utils/coordinates.js')
const GAMESCREEN_ASPECT_RATIO = 1.333
const MIN_WIDTH = 5
const MIN_HEIGHT = MIN_WIDTH * GAMESCREEN_ASPECT_RATIO
const MAX_TOP_VALUE = 90
const ROW_HEIGHT = 5
const PADDING = 2
const STICKERS_PER_ROW = 3
const { BUILTIN_PHYSICS } = require('../../shared_utils/gamebender-constants.js')
function AppliedGlitches (args) {
  const {
    top,
    left,
    width,
    height,
    glitches = [],
    beginDrag,
    selectedGlitchApplicationId
  } = args
  const stickerRows = Math.ceil(glitches.length / STICKERS_PER_ROW)
  const leftVal = left + (width / 2)
  let topVal = top
  if (topVal > MAX_TOP_VALUE) {
    topVal -= (height + (stickerRows * ROW_HEIGHT) + PADDING)
  }
  const style = `top: ${topVal}%;left: ${leftVal}%;`
  const codeStickers = glitches.map(glitch => {
    const button = ButtonIcon({
      size: 'small',
      color: glitch.color,
      icon: glitch.icon,
      pickable: true,
      selected: glitch.applicationId === selectedGlitchApplicationId,
      onMouseDown: e => beginDrag(e, glitch)
    })
    return html`<div class="sticker">${button}</div>`
  })
  return html`
    <div class="code-stickers" style=${style}>
      ${codeStickers}
    </div>
  `
}

module.exports = function SpriteSelector (state, emit) {
  if (!state.selectedTargetId) {
    return null
  }
  if (state.selectedTargetId) {
    function onRotate (e) {
      e.preventDefault()
      emit('start-rotating-target')
    }

    function onResize (e, clickRegion) {
      e.preventDefault()
      emit('start-resizing-target', e, clickRegion)
    }

    function onMove (e) {
      e.preventDefault()
      emit('start-moving-target', e)
    }
    if (state.isFullscreen){
      onMove = null
      onRotate = null
      onResize = null 
    }
    const target = state.vm.runtime.targets
      .find(t => t.id === state.selectedTargetId)
    const bounds = getNormalizedTargetBounds(target, state.canvas)
    let { width, height, top, left } = bounds

    if (!state.resizingSelectedTarget) {
      width = Math.max(bounds.width, MIN_WIDTH)
      height = Math.max(bounds.height, MIN_HEIGHT)
    }
    if (state.rotatingSelectedTarget) {
      const size = Math.max(width, height / GAMESCREEN_ASPECT_RATIO)
      width = size
      height = size * GAMESCREEN_ASPECT_RATIO
    }

    top = bounds.top - ((height - bounds.height) / 2)
    left = bounds.left - ((width - bounds.width) / 2)
    
    const disabled = state.selectedVariableId !== null
    const hover = SelectSprite({
      width,
      height,
      top,
      left,
      rotation: state.hoverRotation,
      rotating: state.rotatingSelectedTarget,
      resizing: state.resizingSelectedTarget,
      moving: state.movingSelectedTarget,
      disabled,
      hideRotationHandle: state.isFullscreen,
      onRotate,
      onResize,
      onMove
    })
    const originalId = target.sprite.clones[0].id
    const glitchApplications = state.glitchApplications
      .filter(g => g.targetId === originalId && g.glitchId !== BUILTIN_PHYSICS)
      .map(h => {
        const glitch = state.availableGlitches.find(g => g.id === h.glitchId)
        return {
          ...glitch,
          applicationId: h.id
        }
      })
    let appliedGlitches = null
    if (state.showGlitches) {
      appliedGlitches = AppliedGlitches({
        top: top + height,
        left,
        glitches: glitchApplications,
        width,
        height,
        beginDrag: (e, glitch) => {
          const dragSticker = ButtonIcon({
            size: 'small',
            color: glitch.color,
            icon: glitch.icon
          })
          emit('start-drag', dragSticker, e)
          emit('select-glitch-application', glitch.applicationId)
          emit('start-dragging-glitch-application')
        },
        selectedGlitchApplicationId: state.selectedGlitchApplicationId
      })
    }

    return [ hover, state.isFullscreen ? null : appliedGlitches ]
  }
}
