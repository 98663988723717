/* eslint-disable multiline-comment-style */
const { log } = console
const {
  parseSb3, 
  fetchArrayBuffer
} = require('./utils/fetch-and-parse.js')
const getVM = require('./utils/get-vm.js')

function loadGlitchSb3 (glitch) {
  return fetchArrayBuffer(`/provision/glitches/${glitch.id}/${glitch.id}.sb3`)
    .then(sb3 => {
      log('loaded glitch', glitch.id)
      return Promise.resolve(sb3)
    })
}

function loadPartSb3 (part) {
  return fetchArrayBuffer(`/provision/parts/${part.id}/${part.id}.sb3`)
    .then(sb3 => {
      log('loaded part', part.id)
      return Promise.resolve(sb3)
    })
}
module.exports = function loadPartsAndGlitches (state, emitter) {
  emitter.on('load-vm', () => {
    log('load-vm')
    if (window.location.search.includes('fullscreen=true')){
      emitter.emit('set-fullscreen-mode', true)
    }
    const RENDERER_WIDTH = 960
    const RENDERER_HEIGHT = 720
    state.canvas = document.createElement('canvas')
    state.canvas.id = 'scratch-canvas'
    state.canvas.width = 480
    state.canvas.height = 360
    state.canvas.classList.add('game')
    state.vm = getVM(state.canvas)
    window.sb3Utils = state.vm.sb3
    state.vm.renderer.resize(RENDERER_WIDTH, RENDERER_HEIGHT)

    //TODO : Understand why we need the timeout here.  This is a deep choo problem
    setTimeout(() => emitter.emit('render'), 10)
  })

  emitter.on('load-glitches', () => {
    log('load-glitches')
    const subcategories = []
    state.availableGlitches.forEach(g => {
      if (g.category) {
        subcategories[g.category] = {
          id: g.category,
          color: g.color
        }
      }
    })
    state.glitchCategories = Object.values(subcategories).filter(
      c => c.id !== 'builtin'
    )
    state.glitchCategories.sort((a, b) => !a.id.localeCompare(b.id))
    state.selectedGlitchCategories = [state.glitchCategories[0].id]
    const promises = state.availableGlitches.map(
      glitch => loadGlitchSb3(glitch).then(
        sb3 => parseSb3(sb3, state.vm)
      )
    )

    Promise.all(promises).then(results => {
      results.forEach((parsedGlitch, i) => {
        state.availableGlitches[i].code = parsedGlitch.code
        state.availableGlitches[i].zip = parsedGlitch.zip
        state.availableGlitches[i].sb3 = parsedGlitch.sb3
      })
      state.glitchesLoaded = true
      emitter.emit('render')
    })
  })

  emitter.on('load-parts', () => {
    log('load-parts')
    const promises = state.availableParts.map(
      part => loadPartSb3(part).then(
        sb3 => parseSb3(sb3, state.vm)
      )
    )
    Promise.all(promises).then(results => {
      results.forEach((parsedPart, i) => {
        state.availableParts[i].code = parsedPart.code
        state.availableParts[i].zip = parsedPart.zip
      })
      state.partsLoaded = true
      emitter.emit('render')
    })
  })
}
