/* eslint-disable no-underscore-dangle */
const { log } = console
const blankTarget = {
  isStage: false,
  name: 'Sprite1',
  variables: {},
  lists: {},
  broadcasts: {},
  blocks: {},
  comments: {},
  currentCostume: 0,
  costumes: [
    {
      assetId: 'bcf454acf82e4504149f7ffe07081dbc',
      name: 'costume1',
      bitmapResolution: 1,
      md5ext: 'bcf454acf82e4504149f7ffe07081dbc.svg',
      dataFormat: 'svg',
      rotationCenterX: 48,
      rotationCenterY: 50
    }
  ],
  sounds: [],
  volume: 100,
  visible: true,
  x: 0,
  y: 0,
  size: 100,
  direction: 90,
  draggable: false,
  rotationStyle: 'all around'
}
module.exports = function store (state, emitter) {
  emitter.on('glitch-editor-select-target', () => {
    log('glitch-editor-select-target')
    const target = state.glitchEditor.vm.runtime.targets.find(
      t => t.sprite.name === '$glitch_target'
    )
    state.glitchEditor.vm.setEditingTarget(target.id)
    if (!target) {
      const targetToAdd = {...blankTarget}
      targetToAdd.name = '$glitch_target'
      state.glitchEditor.vm.addSprite(targetToAdd).then(
        () => emitter.emit('glitch-editor-select-target')
      )
    }
  })


  emitter.on('glitch-editor-select-others', () => {
    log('glitch-editor-select-others')
    const target = state.glitchEditor.vm.runtime.targets.find(
      t => t.sprite.name === '$other_sprites'
    )
    if (target) {
      state.glitchEditor.vm.setEditingTarget(target.id)
    } else {
      const targetToAdd = {...blankTarget}
      targetToAdd.name = '$other_sprites'
      state.glitchEditor.vm.addSprite(targetToAdd).then(
        () => emitter.emit('glitch-editor-select-others')
      )
    }
  })
}
