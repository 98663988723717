const html = require('choo/html')

module.exports = function SelectSprite (args) {
  const {
    width = 0,
    height = 0,
    top = 0,
    left = 0,
    hideRotationHandle = false,
    rotating = false,
    resizing = false,
    disabled = false,
    rotation = 0,
    onMove = () => false,
    onResize = () => false,
    onRotate = () => false
  } = args || {}
  const style = `
      top:${top}%;
      left:${left}%;
      width:${width}%;
      height:${height}%; 
      transform:rotate(${rotation}deg)
    `
  if (rotating) {
    return html`
      <div class="select-sprite rotating" style=${style}>
        <div class="move-handle"></div>
        <div class="rotation-handle"></div>
      </div>
    `
  }

  if (resizing) {
    return html`
      <div class="select-sprite resizing" style=${style}>
        <div class="move-handle"></div>

      </div>
    `
  }
  if (disabled) {
    return html`
    <div class="select-sprite disabled" style=${style}>
      <div class="move-handle"></div>
      <div class="upper-handle"></div>
      <div class="upper-right-handle"></div>
      <div class="right-handle"></div>
      <div class="bottom-right-handle"></div>
      <div class="bottom-handle"></div>
      <div class="bottom-left-handle"></div>
      <div class="left-handle"></div>
      <div class="upper-left-handle"></div>
    </div>
  `
  }
  return html`
    <div class="select-sprite" style=${style}>
      <div class="move-handle" onmousedown=${onMove}></div>
      <div class="upper-handle" onmousedown=${e => onResize(e, 'top')}></div>
      <div class="upper-right-handle" 
        onmousedown=${e => onResize(e, 'topRight')}
      ></div>
      <div class="right-handle" 
        onmousedown=${e => onResize(e, 'right')}
      ></div>
      <div class="bottom-right-handle" 
        onmousedown=${e => onResize(e, 'bottomRight')}
      ></div>
      <div class="bottom-handle" 
        onmousedown=${e => onResize(e, 'bottom')}
      ></div>
      <div class="bottom-left-handle" 
        onmousedown=${e => onResize(e, 'bottomLeft')}
      ></div>
      <div class="left-handle" 
        onmousedown=${e => onResize(e, 'left')}
      ></div>
      <div class="upper-left-handle" 
        onmousedown=${e => onResize(e, 'topLeft')}
      ></div>
      ${hideRotationHandle ? '' : html`<div class="rotation-handle" onmousedown=${onRotate}></div>`}
    </div>
  `
}
