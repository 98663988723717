const html = require('choo/html')

module.exports = function Chip (args) {
  const { children = [], stretch } = args
  const stretchClass = stretch ? 'stretch' : ''
  return html`
    <div class="chip ${stretchClass}">
      <div class="screw top-left"></div>
      <div class="screw top-right"></div>
      <div class="screw bottom-left"></div>
      <div class="screw bottom-right"></div>
      ${children}
    </div>
  `
}
