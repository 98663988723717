module.exports = {
    "id": "piano",
    "title": "piano",
    "image": "provision/parts/piano/piano.svg",
    "info": [
      {
        "text": "Add a piano to your game",
        "image": "provision/parts/piano/piano.gif"
      }
    ],
    "exposedVariables": [{
      "spriteName": "piano",
        "variableName": "piano notes",
        "input": {
          "type": "slider",
          "options": [],
          "min": "1",
          "max": 5,
          "step": "1"
        },
        "value": "5"
    }]
  };