module.exports = {
    "id": "speed up",
    "color": "green",
    "title": "Speed up",
    "icon": "forward",
    "category": "movement",
    "info": [
        {
            "text": "Increase the movement speed of this sprite",
            "image": "provision/glitches/go forward/go forward.gif"
        }
    ],
    "exposedVariables": [
        {
            "spriteName": "$glitch_target",
            "variableName": "acceleration",
            "input": {
                "type": "slider",
                "min": -10,
                "max": 10,
                "step": 0.1
            },
            "value": "1"
        }
    ],
    "codeImage": "provision/glitches/focus/focus.png",
    "code": null,
    "zip": null,
    "sb3": null,
    "isRemix": true
};