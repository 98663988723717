const { log } = console

module.exports = function store (state, emitter) {
  function onMouseMove (e) {
    const newBounds = document.querySelector('#app').getBoundingClientRect()
    state.draggingPosition = {
      top: e.clientY - (state.draggingElement.offsetHeight / 2) - newBounds.top,
      left: e.clientX - (state.draggingElement.offsetWidth / 2) - newBounds.left
    }
    emitter.emit('render')
  }

  emitter.on('cleanup-drag', () => {
    if (state.draggingElement) {
      state.draggingPosition = null
      state.draggingElement = null
      document.removeEventListener('mousemove', onMouseMove)
      emitter.emit('render')
    }
  })

  emitter.on('start-drag', (el, event) => {
    log('start-drag')
    state.draggingElement = el

    const bounds = document.querySelector('#app').getBoundingClientRect()
    state.draggingPosition = {
      top: event.clientY - bounds.top,
      left: event.clientX - bounds.left
    }

    document.addEventListener('mousemove', onMouseMove)
    emitter.emit('render')
  })
}
