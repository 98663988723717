const html = require('choo/html')

const PanelElement = require('../elements/panel.js')

module.exports = function CodePanel (state, emit) {
  let imgSrc = 'graphics/placeholder_code_image.png'
  let { selectedGlitchId } = state
  let color = 'purple'
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    selectedGlitchId = glitchApplication.glitchId
  }
  if (selectedGlitchId) {
    const glitch = state.availableGlitches.find(
      g => g.id === selectedGlitchId
    )
    imgSrc = glitch.codeImage;
    ({color} = glitch)
  } else if (state.selectedPartId) {
    const part = state.availableParts.find(
      p => p.id === state.selectedPartId
    )
    imgSrc = part.codeImage
  }
  return PanelElement({
    color,
    size: 'small',
    noPadding: true,
    children: html`
        <img width="100%" 
        height="100%" 
        src="/${imgSrc}"
        onclick=${() => emit('show-glitch-editor')} />
      `
  })
}
