module.exports = {
  "id": "goal",
  "title": "goal",
  "color": "blue",
  "info": [
    {
      "text": "Score points when one sprite touches another.",
      "image": "provision/glitches/goal/goal.gif"
    }
  ],
  "icon": "golf",
  "category": "rules",
  "exposedVariables": [
    {
      "value": "none",
      "variableName": "scoring sprite",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite"
      }
    },
    {
      "value": "none",
      "variableName": "destination sprite",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite"
      }
    },
    {
      "value": "0",
      "variableName": "starting velocity x",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "step": 1,
        "min": -50,
        "max": 50
      }
    },
    {
      "value": "0",
      "variableName": "starting velocity y",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "step": 1,
        "min": -50,
        "max": 50
      }
    },
    {
      "value": "1",
      "variableName": "point value",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "step": 1,
        "min": -50,
        "max": 50
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "scoring player",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "none",
            "label": "none"
          },
          {
            "value": "1",
            "label": "1"
          },
          {
            "value": "2",
            "label": "2"
          }
        ],
        "min": "1",
        "max": "2",
        "step": "1"
      },
      "value": "1"
    }
  ],
  "codeImage": "provision/glitches/goal/goal.png",
  "code": null,
  "zip": null,
  "sb3": null
};