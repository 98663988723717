module.exports = {
    "id": "zap",
    "title": "zap",
    "image": "provision/parts/zap/zap.svg",
    "info": [
      {
        "text": "Add a zap to your game",
        "image": "provision/parts/zap/zap.gif"
      }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/zap/zap.png"
  };