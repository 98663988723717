const html = require('choo/html')
const PanelElement = require('../elements/panel.js')
const CarouselElement = require('../elements/carousel.js')
const InputOption = require('../elements/inputoption.js')
const InputText = require('../elements/inputtext.js')
const InputSlider = require('../elements/inputslider.js')
const InputSprite = require('../elements/inputsprite.js')
const Button = require('../elements/button.js')
const itemsPerPage = 4
const PARAMETERS = [
  {
    variableName: 'velocity x',
    input: { type: 'slider', min: -10, max: 10, step: 0.01, instanceParam: true }
  },
  {
    variableName: 'velocity y',
    input: { type: 'slider', min: -10, max: 10, step: 0.01, instanceParam: true }
  },
  {
    variableName: 'is static?',
    input: { type: 'boolean', instanceParam: true }
  },
  {
    variableName: 'is wall?',
    input: { type: 'boolean' }
  }
]
const BOOL_OPTIONS = [
  { value: 'true', label: 'true' },
  { value: 'false', label: 'false' }
]

const KEY_OPTIONS = [
  {
    label: 'left arrow',
    value: 'left arrow'
  },
  {
    label: 'right arrow',
    value: 'right arrow'
  },
  {
    label: 'up arrow',
    value: 'up arrow'
  },
  {
    label: 'down arrow',
    value: 'down arrow'
  },
  {
    label: 'space',
    value: 'space'
  },
  {
    label: 'w',
    value: 'w'
  },
  {
    label: 'a',
    value: 'a'
  },
  {
    label: 's',
    value: 's'
  },
  {
    label: 'd',
    value: 'd'
  },
  {
    label: 'f',
    value: 'f'
  },
  {
    label: 'none',
    value: 'none'
  }
]

function generateListInput(variable, gameVariable, target, state, emit) {
  const { input } = gameVariable
  const inputs = []
  variable.value.forEach((value, index) => {
    let label = variable.name
    if (index > 0) {
      label = null
    }
    const commonProps = {
      ...input,
      label,
      value,
      onChange: () => emit('stop-changing-variable', target, variable),
      onInput: e => emit(
        'set-list-value',
        target,
        variable,
        index,
        e.target.value
      )
    }
    inputs.push(InputText({
      ...commonProps,
      disabled: state.selectedVariableId !== null
        || state.isMenuOpen,
      onKeyDown: e => e.stopPropagation(),
      onKeyUp: e => e.stopPropagation()
    }))
  })
  inputs.push(new Button({
    text: 'add value',
    onClick: () => emit('add-item-to-list', target, variable)
  }))
  return inputs
}

function generateParameterInput(parameter, target, state, emit) {
  const { id, label } = parameter
  const disabled = state.selectedVariableId !== null || state.isMenuOpen

  switch (id) {
    case 'currentCostume': return InputSlider({
      label,
      value: target.currentCostume,
      min: 0,
      max: target.sprite.costumes.length - 1,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-costume', target, e.target.value),
      disabled
    })

    case 'color': return InputSlider({
      label,
      value: target.effects.color,
      min: 0,
      max: 200,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'color', target, e.target.value),
      disabled
    })

    case 'brightness': return InputSlider({
      label,
      value: target.effects.brightness,
      min: -100,
      max: 100,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'brightness', target, e.target.value),
      disabled
    })

    case 'mosaic': return InputSlider({
      label,
      value: target.effects.mosaic,
      min: 0,
      max: 100,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'mosaic', target, e.target.value),
      disabled
    })

    case 'pixelate': return InputSlider({
      label,
      value: target.effects.pixelate,
      min: 1,
      max: 100,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'pixelate', target, e.target.value),
      disabled
    })

    case 'whirl': return InputSlider({
      label,
      value: target.effects.whirl,
      min: -100,
      max: 100,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'whirl', target, e.target.value),
      disabled
    })

    case 'fisheye': return InputSlider({
      label,
      value: target.effects.fisheye,
      min: 0,
      max: 100,

      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-graphic-effect', 'fisheye', target, e.target.value),
      disabled
    })

    case 'ghost': {
      let value = target.effects.ghost
      if (!state.isGamePlaying) {
        const hiddenMe = state.hiddenTargets.find(t => t.id === target.id)
        if (hiddenMe) {
          value = hiddenMe.ghost
        }
      }
      return InputSlider({
        label,
        value,
        min: 0,
        max: 100,
        step: 1,
        onChange: () => emit('stop-changing-variable', target, parameter),
        onInput: e => emit('set-graphic-effect', 'ghost', target, e.target.value),
        disabled
      })
    }
    case 'visible': {
      const options = [
        { value: true, label: 'visible' },
        { value: false, label: 'hidden' }
      ]
      let value = target.visible
      if (!state.isGamePlaying) {
        const hiddenMe = state.hiddenTargets.find(t => t.id === target.id)
        if (hiddenMe) {
          value = false
        }
      }
      const selectedItem = options.indexOf(options.find(o => o.value === value))
      return InputOption({
        label,
        options,
        selectedItem,
        onChange: index => emit('set-visible', target, options[index].value),
        disabled
      })
    }

    case 'layer': return InputSlider({
      label,
      value: target.getLayerOrder(),
      min: 1,
      max: state.vm.runtime.targets.length - 1,
      step: 1,
      onChange: () => emit('stop-changing-variable', target, parameter),
      onInput: e => emit('set-layer-order', target, e.target.value),
      disabled
    })
  }
}

function generateInputElement(gameVariable, target, state, emit) {
  const { input, id } = gameVariable

  const variable = target.variables[id]
  if (variable.type === 'list') {
    return generateListInput(variable, gameVariable, target, state, emit)
  }
  function setVariable(value) {
    if (gameVariable.input.onchange) {
      emit(gameVariable.input.onchange, target, variable, value, input.instanceParam)
    } else {
      emit('set-variable', target, variable, value, input.instanceParam)
    }
  }
  let label = variable.name
  if (state.selectedTargetId !== target.id) {
    label = `${gameVariable.spriteName}: ${variable.name}`
  }
  const commonProps = {
    ...input,
    label,
    value: variable.value,
    disabled: state.selectedVariableId !== null
      || state.isMenuOpen,
    onChange: () => emit('stop-changing-variable', target, variable),
    onInput: e => setVariable(e.target.value)
  }
  if (input.type === 'slider') {
    return InputSlider({ ...commonProps })
  }
  if (input.type === 'option'
    || input.type === 'key'
    || input.type === 'boolean') {
    let { options } = input
    if (input.type === 'key') {
      options = KEY_OPTIONS
    } else if (input.type === 'boolean') {
      options = BOOL_OPTIONS
    }

    let selectedItem = options.findIndex(
      o => o.value === variable.value
    )
    if (!selectedItem) {
      selectedItem = 0
    }
    if (options.length === 0) {
      return null
    }
    return InputOption({
      ...commonProps,
      options,
      selectedItem,
      onChange: index => {
        setVariable(options[index].value)
        emit('stop-changing-variable', target, variable)
      }
    })
  }
  if (input.type === 'text') {
    return InputText({
      ...commonProps,
      onKeyDown: e => e.stopPropagation(),
      onKeyUp: e => e.stopPropagation()
    })
  }
  if (input.type === 'sprite') {
    if (state.selectedVariableId === id) {
      commonProps.value = 'CLICK ON A SPRITE'
      commonProps.disabled = false
    }
    return InputSprite({
      ...commonProps,
      onSelect: () => emit('start-setting-sprite-variable', variable.id)
    })
  }
  return null
}

function paginateInputs(inputs) {
  const pages = []
  let pageIndex = 0
  pages[0] = []
  for (let i = 0; i < inputs.length; i++) {
    if (pages[pageIndex].length === itemsPerPage) {
      pageIndex += 1
      pages[pageIndex] = []
    }
    pages[pageIndex].push(inputs[i])
  }

  const items = pages.map(p => html`<div class="info">${p}</div>`)
  return items
}

function getExposedVariableWithIds(gameVariables, inTarget) {
  return gameVariables.reduce(
    (acc, exposedVariable) => {
      let target
      if (exposedVariable.spriteName === inTarget.sprite.name) {
        target = inTarget
      } else {
        target = inTarget.runtime.targets.find(t => t.sprite.name === exposedVariable.spriteName)
      }
      // Find variable in scratch sprite
      const gameVariable = Object.values(target.variables).find(
        v => v.name === exposedVariable.variableName
      )
      if (!gameVariable) {
        target.lookupOrCreateVariable(exposedVariable.variableName, exposedVariable.variableName)
      }
      // Include the scratch variable id on exposed variable info
      if (gameVariable) {
        exposedVariable.id = gameVariable.id
        acc.push(exposedVariable)
      }
      return acc
    },
    []
  )
}

function getExposedProjectVariables(target, projectVars, glitchApplications, availableGlitches) {
  let keyVariables = []
  let exposedVariables = []
  if (!projectVars) {
    projectVars = []
  }
  if (target.isStage) {
    exposedVariables = projectVars.filter(v => v.spriteName === 'Stage')
    keyVariables = getKeyInputVariables(glitchApplications, availableGlitches, target.runtime)
  } else {
    exposedVariables = projectVars.filter(v => v.spriteName === target.sprite.name)
  }
  const varsWithIds = getExposedVariableWithIds(exposedVariables, target)
  return varsWithIds.concat(keyVariables)
}

function getKeyInputVariables(glitchApplications, availableGlitches, runtime) {
  const glitchesWithKeyVars = availableGlitches.filter(g => g.exposedVariables.find(v => v.input.type === 'key'))
  const keyVarApplications = glitchApplications.filter(g => glitchesWithKeyVars.find(_g => _g.id === g.glitchId))
  let glitchVars = []
  keyVarApplications.forEach(glitchApplication => {
    const glitch = availableGlitches.find(g => g.id === glitchApplication.glitchId)
    const t = runtime.targets.find(t => t.id === glitchApplication.targetId)
    glitchVars = glitchVars.concat(getGlitchVariables(t, glitchApplication, availableGlitches))
  })
  glitchVars = glitchVars.filter(gv => gv.input.type === 'key')
  return glitchVars
}

function getGlitchVariables(target, glitchApplication, availableGlitches) {
  const glitch = availableGlitches.find(
    g => g.id === glitchApplication.glitchId
  )
  const exposedVariables = glitch.exposedVariables.filter(
    v => v.spriteName === '$glitch_target'
  ).map(
    v => ({
      ...v,
      spriteName: target.sprite.name
    })
  )
  return getExposedVariableWithIds(exposedVariables, target)
}

function getGameVariables(target, addedParts) {
  let exposedVariables
  const selectedPart = addedParts.find(
    p => p.spriteName === target.sprite.name
  )
  if (selectedPart) {
    ({ exposedVariables } = selectedPart)
  }
  if (exposedVariables) {
    exposedVariables = exposedVariables.filter(
      v => v.spriteName === target.sprite.name
    )
  } else {
    exposedVariables = []
  }
  return getExposedVariableWithIds(exposedVariables, target)
}

function getAltVariables(target, glitchApplications, availableGlitches) {
  if (target.isStage) {
    return []
  }
  const altVars = []
  glitchApplications.forEach(glitchApplication => {
    const glitch = availableGlitches.find(
      g => g.id === glitchApplication.glitchId
    )
    const exposedVariables = glitch.exposedVariables.filter(
      v => v.spriteName === '$other_sprites'
    ).map(
      v => ({
        ...v,
        spriteName: target.sprite.name
      })
    )
    exposedVariables.forEach(v => {
      if (!altVars.find(altVar => altVar.variableName === v.variableName)) {
        altVars.push(v)
      }
    })
  })

  return getExposedVariableWithIds(altVars, target)
}

function getPhysicsParameters(target) {
  if (target.isStage) {
    return []
  }

  let targetParameters = PARAMETERS.map(param => ({
    ...param,
    spriteName: target.sprite.name
  }))

  return getExposedVariableWithIds(targetParameters, target)
}
module.exports = function VariablesPanel(state, emit) {
  let target = state.vm.runtime.targets.find(
    t => t.id === state.selectedTargetId
  )
  if (!target) {
    target = state.vm.runtime.getTargetForStage()
    if (!target) {
      return ''
    }
  }
  let color = 'purple'
  const uniqueVariables = []
  let variablesToDisplay = []

  const parametersToDisplay = []
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    const glitch = state.availableGlitches.find(
      g => g.id === glitchApplication.glitchId
    );

    if (glitch.id === 'effects') {
      parametersToDisplay.push(
        { id: 'color', label: 'color' },
        { id: 'brightness', label: 'brightness' },
        { id: 'mosaic', label: 'mosiac' },
        { id: 'pixelate', label: 'pixelate' },
        { id: 'whirl', label: 'whirl' },
        { id: 'fisheye', label: 'fisheye' }
      )
    } else if (glitch.id === 'visibility') {
      parametersToDisplay.push(
        { id: 'ghost', label: 'ghost' },
        { id: 'visible', label: 'visible' },
        { id: 'layer', label: 'layer' }
      )
    }
    ({ color } = glitch)
    variablesToDisplay = getGlitchVariables(
      target,
      glitchApplication,
      state.availableGlitches
    )
  } else {
    if (target.sprite.costumes.length > 1 && !state.isFullscreen) {
      parametersToDisplay.push({
        id: 'currentCostume',
        label: target.isStage ? 'Current Backdrop' : 'Current Costume'
      })
    }
    const spriteVariables = getGameVariables(
      target,
      state.addedParts
    )
    const altVariables = getAltVariables(
      target,
      state.glitchApplications,
      state.availableGlitches
    )
    const params = getPhysicsParameters(target)
    variablesToDisplay = spriteVariables.concat(params).concat(altVariables)
    if (target.isStage || state.isFullscreen) {
      variablesToDisplay = getExposedProjectVariables(target,
        state.projectMetadata.exposedVariables,
        state.glitchApplications,
        state.availableGlitches)
    }
  }








  variablesToDisplay.forEach(v => {
    if (!uniqueVariables.find(uv => uv.variableName === v.variableName && v.spriteName === uv.spriteName)) {
      uniqueVariables.push(v)
    }
  })

  const parameterInputs = parametersToDisplay.map(p => generateParameterInput(p, target, state, emit))
  let inputs = uniqueVariables.map(
    gameVariable => {
      if (target.sprite.name !== gameVariable.spriteName) {
        const spriteTarget = state.vm.runtime.targets.find(t => t.sprite.name === gameVariable.spriteName)
        return generateInputElement(gameVariable, spriteTarget, state, emit)
      }
      return generateInputElement(gameVariable, target, state, emit)
    }
  )
  inputs = parameterInputs.concat(inputs)
  const items = paginateInputs(inputs)
  if (!state.currentVariablePage || state.currentVariablePage >= items.length) {
    state.currentVariablePage = 0
  }
  return PanelElement({
    color,
    children: CarouselElement({
      selectedItem: state.currentVariablePage,
      items,
      disabled: state.selectedVariableId !== null
        || state.isMenuOpen,
      onChange: state.selectedVariableId ? (
        () => false) : index => emit('select-variable-page', index)
    })
  })
}
