module.exports = {
  "id": "patrol",
  "color": "green",
  "title": "patrol",
  "icon": "patrol",
  "category": "movement",
  "description": "Make a sprite go left and right.",
  "exposedVariables": [
    {
      "variableName": "patrol speed",
      "spriteName": "$glitch_target",
      "value": 5,
      "input": {
        "type": "slider",
        "max": 20
      }
    }
  ],
  "info": [
    {
      "text": "Make a sprite go left and right.",
      "image": "provision/glitches/patrol/patrol.gif"
    }
  ],
  "codeImage": "provision/glitches/patrol/patrol.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/patrol/patrol.svg"
};