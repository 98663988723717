module.exports = {
  "id": "tomato cross",
  "title": "tomato cross",
  "category":"games",
  "thumbnail": "/provision/projects/tomato cross/tomato cross.png",
  "project_file": "tomato cross.gbp",
  "exposedVariables": [
    {
      "spriteName": "tomato",
      "variableName": "left key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "tomato",
      "variableName": "right key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "tomato",
      "variableName": "up key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "tomato",
      "variableName": "down key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    }
  ],
  "persistentVariables": []
}
;