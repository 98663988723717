module.exports = {
  "id": "volleyball",
  "title": "volleyball",
 
  "project_file": "volleyball.gbp",
  "exposedVariables": [
  ],
  "persistentVariables": [],
  "thumbnail": "/provision/projects/volleyball/volleyball.png",
  "category": "games",
  "toolbar":{
    "glitches":[],
    "parts":[]
  }
}
;