/* eslint-disable no-underscore-dangle */
const { log } = console
const FileSaver = require('file-saver')
const {
  deleteNonGlitchBlocks,
  copyNonGlitchBlocks
} = require('./utils/glitch-editor.js')

function cleanupAssets (vmTarget, editorTarget) {
  editorTarget.sprite.sounds = vmTarget.sprite.sounds.map(s => {
    const newSound = {...s}
    newSound.asset = {...s.asset}
    newSound.asset.data = {...s.asset.data}
    Object.setPrototypeOf(newSound.asset, Object.getPrototypeOf(s.asset))
    return newSound
  })
  editorTarget.sprite.costumes = vmTarget.sprite.costumes.map(c => {
    const newCostume = {...c}
    newCostume.asset = {...c.asset}
    newCostume.asset.data = {...c.asset.data}
    Object.setPrototypeOf(newCostume.asset, Object.getPrototypeOf(c.asset))
    return newCostume
  })
  editorTarget.sprite.sounds.forEach(s => {
    s.asset.data = window.frames[0].Uint8Array.from(s.asset.data)
  })
  editorTarget.sprite.costumes.forEach(c => {
    c.asset.data = window.frames[0].Uint8Array.from(c.asset.data)
  })
}
function getEditingGlitch (state) {
  if (state.selectedGlitchId) {
    const glitch = state.availableGlitches.find(
      g => g.id === state.selectedGlitchId
    )
    return glitch
  }
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    const glitch = state.availableGlitches.find(
      g => g.id === glitchApplication.glitchId
    )
    return glitch
  }
  return null
}

// eslint-disable-next-line max-lines-per-function
module.exports = function store (state, emitter) {
  emitter.on('glitch-editor-export', () => {
    log('glitch-editor-export')
    emitter.emit('save-and-apply-changes')
    let glitchOrPart
    if (state.selectedPartId) {
      glitchOrPart = state.availableParts.find(
        p => p.id === state.selectedPartId
      )
    } else if (state.selectedGlitchId || state.selectedGlitchApplicationId) {
      glitchOrPart = getEditingGlitch(state)
    } else {
      let [glitchEditorTarget] = state.glitchEditor.vm.runtime.targets
      let selectedTarget = state.vm.runtime.getTargetForStage()
      if (state.selectedTargetId) {
        selectedTarget = state.vm.runtime.targets.find(
          t => t.id === state.selectedTargetId
        );
        [ , glitchEditorTarget ] = state.glitchEditor.vm.runtime.targets
        state.glitchEditor.vm.runtime.targets[0].variables = {}
      }

      cleanupAssets(selectedTarget, glitchEditorTarget)
      glitchOrPart = {
        id: selectedTarget.sprite.name,
        title: selectedTarget.sprite.name,
        exposedVariables: state.glitchEditorVariables,
        info: []
      }
    }
    state.glitchEditor.vm.saveProjectSb3().then(
      sb3 => {
        const imageAsset =
          state.glitchEditor.vm.runtime.targets[1].sprite.costumes[0].asset
        if (imageAsset.assetType.name === 'ImageVector') {
          const svgText = imageAsset.decodeText()
          const fileToSave = new Blob(
            [svgText],
            {type: 'text/plain;charset=utf-8'}
          )
          FileSaver.saveAs(fileToSave, `${glitchOrPart.id}.svg`)
        }
        FileSaver.saveAs(sb3, `${glitchOrPart.id}.sb3`)
        const configObject = {
          ...glitchOrPart,
          zip: null,
          sb3: null,
          code: null
        }

        if (imageAsset.assetType.name === 'ImageVector') {
          configObject.image =
            `provision/parts/${glitchOrPart.id}/${glitchOrPart.id}.svg`
        }

        const fileToSave = new Blob(
          [JSON.stringify(configObject)],
          {type: 'text/plain;charset=utf-8'}
        )
        FileSaver.saveAs(fileToSave, `${glitchOrPart.id}.json`)
      }
    )
  })
  emitter.on('remix-glitch', () => {
    let {selectedGlitchId} = state
    if (state.selectedGlitchApplicationId) {
      const glitchApplication = state.glitchApplications.find(
        g => g.id === state.selectedGlitchApplicationId
      )
      selectedGlitchId = glitchApplication.glitchId
    }
    const originalGlitch = state.availableGlitches.find(
      g => g.id === selectedGlitchId
    )
    const newGlitch = {...originalGlitch}
    newGlitch.id = newGlitch.title.toLowerCase()
    while (state.availableGlitches.find(
      g => g.id.toLowerCase() === newGlitch.id
    )) {
      newGlitch.id = `${newGlitch.id}_remix`
    }
    newGlitch.title = `${newGlitch.title} remix`
    newGlitch.code = JSON.parse(state.glitchEditor.vm.toJSON())
    newGlitch.isRemix = true
    state.availableGlitches.push(newGlitch)
    state.selectedGlitchId = newGlitch.id
    state.selectedGlitchApplicationId = null
    state.inventoryFilter = originalGlitch.title
    emitter.emit('render')
  })

  emitter.on('glitch-editor-set-glitch-title', title => {
    log('glitch-editor-set-glitch-title', title)
    const glitch = getEditingGlitch(state)
    glitch.title = title
    emitter.emit('render')
  })

  emitter.on('save-and-apply-changes', () => {
    log('save-and-apply-changes')
    const glitch = getEditingGlitch(state)
    if (glitch) {
      const glitchApplication = state.glitchApplications.find(
        g => g.id === state.selectedGlitchApplicationId
      )
      glitch.exposedVariables = state.glitchEditorVariables
      glitch.code = JSON.parse(state.glitchEditor.vm.toJSON())
      const glitchApplicationsToReapply = state.glitchApplications.filter(
        g => g.glitchId === glitch.id
      )
      glitchApplicationsToReapply.forEach(g => {
        emitter.emit('reapply-glitch', g.id)
      })

      if (glitchApplication) {
        const matchingGlitchApplication = state.glitchApplications.find(
          g => g.glitchId === glitchApplication.glitchId
        && g.targetId === glitchApplication.targetId
        )
        state.selectedGlitchApplicationId = matchingGlitchApplication.id
      }
    } else {
      let selectedTarget = state.vm.runtime.getTargetForStage()
      let [editorTarget] = state.glitchEditor.vm.runtime.targets
      if (state.selectedTargetId) {
        selectedTarget = state.vm.runtime.targets.find(
          t => t.id === state.selectedTargetId
        );
        [ , editorTarget ] = state.glitchEditor.vm.runtime.targets
      }
      state.currentScene.metadata.exposedVariables =
      state.currentScene.metadata.exposedVariables.filter(
        v => v.spriteName !== selectedTarget.sprite.name
      )
      let {exposedVariables} = state.currentScene.metadata
      exposedVariables = exposedVariables.concat(state.glitchEditorVariables)
      state.currentScene.metadata.exposedVariables = exposedVariables
      deleteNonGlitchBlocks(selectedTarget, state.glitchApplications)
      const [original] = selectedTarget.sprite.clones
      copyNonGlitchBlocks(
        editorTarget,
        original,
        state.vm,
        state.glitchApplications
      )
      emitter.emit('render')
    }
  })
}
