module.exports = {
  "id": "flag",
  "title": "flag",
  "image": "provision/parts/flag/flag.svg",
  "info": [
    {
      "text": "Add a flag to your game",
      "image": "provision/parts/flag/flag.gif"
    }
  ],
  "exposedVariables": [
  ],
  "codeImage": "provision/parts/flag/flag.png"
};