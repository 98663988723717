const { log } = console
const { findTargetById } = require('../shared_utils/find-target.js')
module.exports = function store (state, emitter) {
  emitter.on('drop-to-all', () => {
    const fromTarget = findTargetById(state.vm, state.selectedTargetId)
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    const otherSprites = state.vm.runtime.targets.filter(
      t => t.sprite !== fromTarget.sprite
        && t.isOriginal
        && !t.isStage
    )

    emitter.emit('push-history', 'drop-to-all')
    otherSprites.forEach(sprite => {
      emitter.emit(
        'copy-glitch-application',
        fromTarget.id,
        sprite.id,
        glitchApplication.id
      )
    })
    emitter.emit('select-glitch-application', glitchApplication.id)
    log('drop-to-all')
  })
}
