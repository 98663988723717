const ButtonIcon = require('../elements/buttonicon.js')
const PanelElement = require('../elements/panel.js')
const html = require('choo/html')
module.exports = function SpriteDropAreas (state, emit) {
  const trash = ButtonIcon({
    icon: 'trash',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('delete-selected-sprite')
  })

  const duplicate = ButtonIcon({
    icon: 'duplicate',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('duplicate-selected-sprite')
  })
  const dropZone = html`
  <div class="glitch-drop-areas-wrapper">
    ${duplicate}
    ${trash}
  </div>`
  return PanelElement({
    color: 'purple',
    size: 'small',
    noPadding: false,
    children: dropZone
  })
}
