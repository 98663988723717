const { log } = console
const { copyBlocks } = require('./utils/apply-glitch.js')
const { findTargetById } = require('../shared_utils/find-target.js')
const {
  getNonGlitchBlocks,
  getGlitchesAppliedToTarget,
  startGreenFlags
} = require('./utils/glitch-helpers.js')
const { addAppliedGlitchesCodeToPart } = require('./utils/add-part.js')

module.exports = function store(state, emitter) {
  emitter.on('delete-selected-sprite', () => {
    log('delete-selected-sprite')
    if (state.selectedTargetId) {
      emitter.emit('delete-sprite', state.selectedTargetId)


      emitter.emit('deselect-all')
      state.draggingTargetOutOfPlayArea = false
      emitter.emit('cleanup-drag')
      emitter.emit('render')
    }
  })


  emitter.on('delete-sprite', originalId => {
    emitter.emit('push-history', 'delete-sprite')
    const target = findTargetById(
      state.vm,
      originalId
    )
    const [original] = target.sprite.clones
    const targetsToDispose = [...target.sprite.clones]
    targetsToDispose.forEach(c => {
      state.vm.runtime.stopForTarget(c)
      state.vm.runtime.disposeTarget(c)
    })
    state.glitchApplications = state.glitchApplications.filter(
      g => g.targetId !== original.id
    )
    state.addedParts = state.addedParts.filter(
      p => p.spriteName !== original.sprite.name
    )
    state.vm.renderer.draw()
  })


  emitter.on('duplicate-selected-sprite', () => {
    log('duplicate-selected-sprite')
    emitter.emit('put-target-back-in-play-area')
    const selectedTarget = findTargetById(state.vm, state.selectedTargetId)
    const [original] = selectedTarget.sprite.clones
    const addedPart = state.addedParts.find(
      p => p.spriteName === original.sprite.name
    )

    const glitches = getGlitchesAppliedToTarget(
      original,
      state.glitchApplications
    )
    original.duplicate().then(newSprite => {
      state.vm.runtime.addTarget(newSprite)
      if (addedPart) {
        const newPart = JSON.parse(JSON.stringify(addedPart))
        newPart.exposedVariables = newPart.exposedVariables.map(v => ({
          ...v,
          spriteName: newSprite.sprite.name
        }))
        newPart.spriteName = newSprite.sprite.name
        state.addedParts.push(newPart)
      }
      Object.values(newSprite.blocks._blocks).forEach(block => {
        newSprite.blocks.deleteBlock(block.id)
      })
      const blocksToAdd = getNonGlitchBlocks(original, state.glitchApplications)
        .map(b => b.id)
      copyBlocks(original, newSprite, state.vm, blocksToAdd)
      startGreenFlags(newSprite)
      addAppliedGlitchesCodeToPart(
        newSprite,
        state.vm,
        state.availableGlitches,
        state.glitchApplications
      )
      glitches.forEach(glitchId => {
        emitter.emit('apply-glitch', glitchId, newSprite.id)
      })
      state.vm.runtime.renderer.draw()
      emitter.emit('render')
    })
  })
}
