var EventEmitter = require('events').EventEmitter

var storage = require('./lib/storage')
var logger = require('./lib/logger')
var debug = require('./lib/debug')
var copy = require('./lib/copy')
var help = require('./lib/help')
var perf = require('./lib/perf')
var log = require('./lib/log')
var getAllRoutes = require('wayfarer/get-all-routes')

module.exports = expose

function expose (opts) {
  opts = opts || {}
  store.storeName = 'choo-devtools'
  return store
  function store (state, emitter, app) {
    var localEmitter = new EventEmitter()

    if (typeof window !== 'undefined') {
      logger(state, emitter, opts)
    }

    emitter.on('DOMContentLoaded', function () {
      if (typeof window === 'undefined') return
      window.choo = {}

      window.choo.state = state
      window.choo.emit = function () {
        emitter.emit.apply(emitter, arguments)
      }
      window.choo.on = function (eventName, listener) {
        emitter.on(eventName, listener)
      }

      debug(state, emitter, app, localEmitter)

      log(state, emitter, app, localEmitter)
      perf(state, emitter, app, localEmitter)
      window.choo.copy = copy
      if (app.router && app.router.router) {
        window.choo.routes = Object.keys(getAllRoutes(app.router.router))
      }

      storage()
      help()
    })
  }
}
