const { log } = console

module.exports = function store (state, emitter) {
  emitter.on('toggle-glitch', glitchId => {
    if (state.selectedGlitchId === glitchId) {
      emitter.emit('deselect-glitch')
    } else {
      emitter.emit('select-glitch', glitchId)
    }
  })

  emitter.on('deselect-glitch', () => {
    state.selectedGlitchId = null
    emitter.emit('render')
  })

  emitter.on('select-glitch', glitchId => {
    log('select-glitch', glitchId)
    state.selectedPartId = null
    state.selectedGlitchId = glitchId
    emitter.emit('deselect-target')
    emitter.emit('render')
  })

  emitter.on('toggle-part', partId => {
    if (state.selectedPartId === partId) {
      emitter.emit('deselect-part')
    } else {
      emitter.emit('select-part', partId)
    }
  })

  emitter.on('select-part', partId => {
    log('select-part', partId)
    state.selectedGlitchId = null
    state.selectedPartId = partId
    emitter.emit('deselect-target')
    emitter.emit('render')
  })

  emitter.on('deselect-part', () => {
    state.selectedPartId = null
    emitter.emit('render')
  })
}
