module.exports = {
  "id": "coconut",
  "title": "coconut",
  "exposedVariables": [
    {
      "spriteName": "Coconut",
      "variableName": "hang time",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "10",
        "step": ".1"
      },
      "value": "1",
      "id": "B]=h(Qf0b0kuh._1n:bA"
    },
    {
      "spriteName": "Coconut",
      "variableName": "x-range",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "10",
        "step": ".1"
      },
      "value": "3",
      "id": "B]=h(Qf0b0kuh._1n:bA"
    },
    {
      "spriteName": "Coconut",
      "variableName": "y-range",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "10",
        "step": ".1"
      },
      "value": "3",
      "id": "B]=h(Qf0b0kuh._1n:bA"
    },
    {
      "spriteName": "Coconut",
      "variableName": "animation delay",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "1",
        "step": ".01"
      },
      "value": ".05",
      "id": "2dX].+8Wey8?Z7G4BEoT"
    }
  ],
  "info": [],
  "zip": null,
  "sb3": null,
  "code": null,
  "image": "provision/parts/Coconut/Coconut.svg"
};