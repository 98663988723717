const html = require('choo/html')

const DraggingElement = require('./components/draggingelement.js')
const Menu = require('./components/menu.js')
const GameControls = require('./components/gamecontrols.js')
const ScreenTitle = require('./components/screentitle.js')
const Toolbar = require('./components/toolbar.js')
const Screen = require('./components/screen.js')
const ContextMenu = require('./components/contextmenu.js')
const makeyMakeyLogo = require('../static/graphics/makeyMakeyLogo.png')

function LoadingMessage (text) {
  return html`
  <div id="app">
    <div class="grid-12">
      <div class="column-12 huge-letters" style="text-align: center;">
        ${text}
      </div>
    </div>
  </div>`
}
module.exports = function PlayingView (state, emit) {
  state.isFullscreen = true
  state.isFullscreenOnly = true
  if (!state.vm){
    emit('load-vm')
    return LoadingMessage('Loading VM')
  } 
  if (!state.glitchesLoaded) {
    emit('load-glitches')
    return LoadingMessage('Loading Glitches')
  }
  if (!state.partsLoaded) {
    emit('load-parts')
    return LoadingMessage('Loading Parts')
  }
  if (!state.project) {
    emit('fetch-project', state.params.project_id)
    return LoadingMessage('Fetching Project')
  }
  if (!state.currentScene) {
    emit('load-scene', 0)
    return LoadingMessage('Loading Scene')
  }
  const brandingWidth = "124rem"
  const brandingElement = document.getElementById('branding')
  brandingElement.style.display = 'block'
  brandingElement.style.width = brandingWidth
  const gameTitleElement = document.getElementById('game-title')
  gameTitleElement.innerText = state.projectMetadata.title

  const gameWidth = document.getElementById('app').offsetWidth
  const gameHeight = document.getElementById('app').offsetHeight
  const gameAspectRatio = gameWidth / gameHeight
  const bodyWidth = document.body.offsetWidth
  const bodyHeight = document.body.offsetHeight
  const bodyAspectRatio = bodyWidth / bodyHeight
  let height = "100vh"
  if (bodyAspectRatio < 1.87){
    const rootFontSize = .00089 * bodyWidth
    const rootFontSizePx = rootFontSize + 'px'
    document.documentElement.style.fontSize = rootFontSizePx
  }
  else {
    document.documentElement.style.fontSize = 'calc(calc(5 / 3) * 0.1vh)' 
  }
    return html`
    <div id="app" style="margin-left:${brandingWidth};"  >
      <div class="grid-12 main-row">

        <div class="column-9">
          ${Screen(state, emit)}
        </div>
        
        <div class="column-3 context-menu">
          ${ContextMenu(state, emit)}
        </div>
      </div>
    </div>
  `
}

