const availableParts = [
  require('../../../provision/parts/angry_fish/angry_fish.json'),
  require('../../../provision/parts/space rock/space rock.json'),
  require('../../../provision/parts/ball/ball.json'),
  require('../../../provision/parts/banana_peel/banana_peel.json'),
  require('../../../provision/parts/bonus star/bonus star.json'),
  require('../../../provision/parts/brick/brick.json'),
  require('../../../provision/parts/bubble/bubble.json'),
  require('../../../provision/parts/bumper/bumper.json'),
  require('../../../provision/parts/carl/carl.json'),
  require('../../../provision/parts/chompy/chompy.json'),
  require('../../../provision/parts/coconut/coconut.json'),
  require('../../../provision/parts/coffee_mug/coffee_mug.json'),
  require('../../../provision/parts/dungeon_floor/dungeon_floor.json'),
  require('../../../provision/parts/flipper/flipper.json'),
  require('../../../provision/parts/flag/flag.json'),
  require('../../../provision/parts/giant_skull/giant_skull.json'),
  require('../../../provision/parts/little_skull/little_skull.json'),
  require('../../../provision/parts/maria/maria.json'),
  require('../../../provision/parts/maze/maze.json'),
  require('../../../provision/parts/paddle/paddle.json'),
  require('../../../provision/parts/pinball side/pinball side.json'),
  require('../../../provision/parts/spikes/spikes.json'),
  require('../../../provision/parts/pinball_wall/pinball_wall.json'),
  require('../../../provision/parts/player ship/player ship.json'),
  require('../../../provision/parts/prize/prize.json'),
  require('../../../provision/parts/projectile/projectile.json'),
  require('../../../provision/parts/tombstone/tombstone.json'),
  require('../../../provision/parts/vine/vine.json'),
  require('../../../provision/parts/scoreboard/scoreboard.json'),
  require('../../../provision/parts/speed boost/speed boost.json'),
  require('../../../provision/parts/ufo/ufo.json'),
  require('../../../provision/parts/portal/portal.json'),
  require('../../../provision/parts/ball release/ball release.json'),
  require('../../../provision/parts/tomato/tomato.json'),
  require('../../../provision/parts/terrain/terrain.json'),
  require('../../../provision/parts/truck/truck.json'),
  require('../../../provision/parts/star/star.json'),
  require('../../../provision/parts/cactus/cactus.json'),
  require('../../../provision/parts/finish line/finish line.json'),
  require('../../../provision/parts/bridge/bridge.json'),
  require('../../../provision/parts/bus/bus.json'),
  require('../../../provision/parts/rock/rock.json'),
  require('../../../provision/parts/launchpad/launchpad.json'),
  require('../../../provision/parts/tornado/tornado.json'),
  require('../../../provision/parts/bomb/bomb.json'),
  require('../../../provision/parts/lava/lava.json'),
  require('../../../provision/parts/mud/mud.json'),
  require('../../../provision/parts/piano/piano.json'),
  require('../../../provision/parts/boulder/boulder.json'),
  require('../../../provision/parts/forcefield/forcefield.json'),
  require('../../../provision/parts/air current/air current.json'),
  require('../../../provision/parts/construction floor/construction floor.json'),
  require('../../../provision/parts/flying saw/flying saw.json'),
  require('../../../provision/parts/transformer/transformer.json'),
  require('../../../provision/parts/zap/zap.json')
]

module.exports = availableParts
