module.exports = {
  "id": "point towards",
  "color": "green",
  "title": "point towards",
  "icon": "follow",
  "category": "movement",
  "info": [
    {
      "text": "Make one sprite point towards another.",
      "image": "provision/glitches/point towards/point towards.gif"
    }
  ],
  "exposedVariables": [
    {
      "type": "sprite",
      "value": "none",
      "variableName": "sprite to point towards",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite"
      }
    }
  ],
  "codeImage": "provision/glitches/focus/focus.png",
  "code": null,
  "zip": null,
  "sb3": null
};