const ButtonIcon = require('../elements/buttonicon.js')
const PanelElement = require('../elements/panel.js')
const html = require('choo/html')
module.exports = function GlitchDropAreas (state, emit) {
  const trash = ButtonIcon({
    icon: 'trash',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('drop-to-trash')
  })
  const dropToAll = ButtonIcon({
    icon: 'expand',
    type: 'tight',
    size: 'large',
    noIconBackground: true,
    onMouseUp: () => emit('drop-to-all')
  })

  const dropZone = html`
  <div class="glitch-drop-areas-wrapper">
    ${trash}
    ${dropToAll}
  </div>`
  return PanelElement({
    color: 'purple',
    size: 'small',
    noPadding: false,
    children: dropZone
  })
}
