module.exports = {
  "id": "accelerate",
  "color": "red",
  "title": "accelerate",
  "icon": "lightning",
  "category": "magic",
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "acceleration strength",
      "input": {
        "type": "slider",
        "options": [],
        "min": -10,
        "max": 10,
        "step": ".1"
      },
      "value": "5"
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "acceleration direction",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "mine",
            "label": "mine"
          },
          {
            "value": "touching",
            "label": "touching"
          }
        ]
      },
      "value": "mine"
    }
  ],
  "info": [
    {
      "text": "Make other sprites accelerate forward when touched",
      "image": "provision/glitches/accelerate/accelerate.gif"
    }
  ],
  "codeImage": "provision/glitches/acclerate/accelerate.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/accelerate/accelerate.svg"
};