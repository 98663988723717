module.exports = {
  "id": "bouncify",
  "color": "green",
  "title": "bouncify",
  "icon": "bouncify",
  "category": "movement",
  "description": "Make a sprite move and bounce",
  "variableOverrides": [
    {
      "name": "is static?",
      "value": "false",
      "spriteName": "$glitch_target"
    }
  ],
  "exposedVariables": [
    {
      "variableName": "velocity x",
      "spriteName": "$glitch_target",
      "value": 0,
      "override": true,
      "input": {
        "type": "slider",
        "min": "-25",
        "max": "25",
        "step": ".01"
      }
    },
    {
      "variableName": "velocity y",
      "spriteName": "$glitch_target",
      "value": 0,
      "override": true,
      "input": {
        "type": "slider",
        "min": "-25",
        "max": "25",
        "step": ".01"
      }
    },
    {
      "variableName": "friction",
      "spriteName": "$glitch_target",
      "value": "0",
      "input": {
        "type": "slider",
        "min": "0",
        "max": "1",
        "step": ".01"
      }
    },
    {
      "spriteName": "$glitch_target",
      "variableName": "bounciness",
      "input": {
        "type": "slider",
        "options": [],
        "min": "0",
        "max": "1",
        "step": ".1"
      },
      "value": "1"
    }
  ],
  "info": [
    {
      "text": "Turn a sprite into a bouncing ball",
      "image": "provision/glitches/bouncify/bouncify.gif"
    }
  ],
  "codeImage": "provision/glitches/bouncify/bouncify.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/bouncify/bouncify.svg"
};