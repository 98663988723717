module.exports = {
    "id": "forcefield",
    "title": "forcefield",
    "image": "provision/parts/forcefield/forcefield.svg",
    "info": [
        {
            "text": "Add a forcefield to your game",
            "image": "provision/parts/forcefield/forcefield.gif"
        }
    ],
    "exposedVariables": [],
    "codeImage": "provision/parts/forcefield/forcefield.png"
};