module.exports = {
  "id": "angry_fish",
  "title": "Angry Fish",
  "image": "provision/parts/angry_fish/angry_fish.svg",
  "info": [
    {
      "text": "Add an Angry Fish to your game",
      "image": "provision/parts/angry_fish/angry_fish.gif"
    }
  ],
  "exposedVariables": [
    {
      "value": "true",
      "variableName": "is wall?",
      "spriteName": "angry_fish",
      "input": {
        "type": "boolean"
      }
    },
    {
      "value": "right",
      "variableName": "movement direction",
      "spriteName": "angry_fish",
      "input": {
        "type": "option",
        "options": [
          {
            "label": "up",
            "value": "up"
          },
          {
            "label": "down",
            "value": "down"
          },
          {
            "label": "left",
            "value": "left"
          },
          {
            "label": "right",
            "value": "right"
          }
        ]
      }
    },
    {
      "value": 1,
      "variableName": "color",
      "spriteName": "angry_fish",
      "input": {
        "type": "slider",
        "min": 0,
        "max": 100,
        "step": 1
      }
    },
    {
      "value": 0.1,
      "variableName": "animation delay",
      "spriteName": "angry_fish",
      "input": {
        "type": "slider",
        "min": 0,
        "max": 2,
        "step": 0.01
      }
    }
  ],
  "codeImage": "provision/parts/angry_fish/angry_fish.png"
};