module.exports = {
  "id": "strobe",
  "color": "green",
  "title": "strobe",
  "icon": "strobe",
  "category": "magic",
  "description": "Make a sprite flash brighter and darker",
  "exposedVariables": [
    {
      "variableName": "strobe speed",
      "spriteName": "$glitch_target",
      "value": 10,
      "input": {
        "type": "slider",
        "max": 25,
        "step": 1,
        "min": "1"
      }
    },
    {
      "variableName": "strobe intensity",
      "spriteName": "$glitch_target",
      "value": 25,
      "input": {
        "type": "slider",
        "max": 50,
        "min": "10",
        "step": "1"
      }
    }
    
  ],
  "info": [
    {
      "text": "Make a sprite move around at random.",
      "image": "provision/glitches/wander/wander.gif"
    }
  ],
  "codeImage": "provision/glitches/wander/wander.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/wander/wander.svg"
};