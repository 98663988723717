const { log } = console
const BUILTIN_PHYSICS = 'builtin_physics'
const {getScratchPointOfMouseEvent} = require('../shared_utils/coordinates.js')
const {
  addPartToVM,
} = require('./utils/add-part.js')
const {sanitizeVariables} = require('./utils/sanitize-state.js')
const {addAppliedGlitchesCodeToPart} = require('./utils/add-part.js')
const uuid = require('./utils/uuid.js')
module.exports = function store (state, emitter) {
  emitter.on('start-importing-part', partId => {
    log('start-importing-part', partId)
    state.selectedPartId = partId
    state.importingPart = true
    emitter.emit('render')
  })

  emitter.on('drop-part-on-game', e => {
    log('drop-part-on-game', e)
    const part = state.availableParts.find(p => p.id === state.selectedPartId)
    const coords = getScratchPointOfMouseEvent(e, state.canvas)

    emitter.emit('push-history', 'add-part')
    addPartToVM(part, state.vm, {coords})
      .then(newPartEntry => {
        state.addedParts.push(newPartEntry.addedPartEntry)
        const newPart = newPartEntry.target
        part.exposedVariables.forEach(v => {
          newPart.lookupOrCreateVariable(uuid(), v.variableName).value = v.value
        })
        addAppliedGlitchesCodeToPart(
          newPart,
          state.vm,
          state.availableGlitches,
          state.glitchApplications
        )
        emitter.emit('apply-glitch', BUILTIN_PHYSICS, newPart.id)
        state.vm.runtime.renderer.draw()
        setTimeout(
          () => {
            state.vm.runtime.renderer.draw()
            emitter.emit('select-target', newPart.id)
            emitter.emit('render')
          }
          , 100
        )
      })
    sanitizeVariables(state)
  })

  emitter.on('end-importing-part', () => {
    state.importingPart = false
    state.selectedPartId = null
    emitter.emit('render')
  })
}
