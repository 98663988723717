module.exports = {
  "id": "stick to sprite",
  "color": "green",
  "title": "stick to sprite",
  "icon": "pushpin",
  "category": "movement",
  "description": "Stick one sprite to another.",
  "exposedVariables": [
    {
      "variableName": "pinned to",
      "spriteName": "$glitch_target",
      "input": {
        "type": "sprite",
        "value": "none"
      },
      "value": "none"
    },
    {
      "variableName": "Offset X",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -100,
        "max": 100,
        "value": "0",
        "step": "1"
      },
      "value": "0"
    },
    {
      "variableName": "Offset Y",
      "spriteName": "$glitch_target",
      "input": {
        "type": "slider",
        "min": -100,
        "max": 100,
        "value": "0",
        "step": "1"
      },
      "value": "0"
    }
  ],
  "info": [
    {
      "text": "Stick one sprite to another.",
      "image": "provision/glitches/stick to sprite/stick to sprite.gif"
    }
  ],
  "codeImage": "provision/glitches/stick to sprite/stick to sprite.png",
  "code": null,
  "zip": null,
  "sb3": null,
  "image": "provision/parts/stick to sprite/stick to sprite.svg"
};