module.exports = {
  "id": "climb",
  "title": "climb",
  "color": "pink",
  "icon": "ladder",
  "category": "powers",
  "description": "Make a physics sprite behave like a platformer character",
  "exposedVariables": [
    {
      "variableName": "climb speed",
      "spriteName": "$glitch_target",
      "value": 5,
      "input": {
        "type": "slider",
        "max": 10,
        "min": "0",
        "step": ".1"
      }
    },
    {
      "variableName": "climb up key",
      "spriteName": "$glitch_target",
      "value": "up arrow",
      "input": {
        "type": "key"
      }
    },
    {
      "variableName": "climb down key",
      "spriteName": "$glitch_target",
      "value": "down arrow",
      "input": {
        "type": "key"
      }
    }
  ],
  "info": [
    {
      "text": "Cimb up and down.  Set other sprites as 'CLIMBABLE'",
      "image": "provision/glitches/climb/climb.gif"
    }
  ],
  "codeImage": "provision/glitches/climb/climb.png",
  "code": null,
  "zip": null,
  "sb3": null
};