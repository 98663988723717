const PanelElement = require('../elements/panel.js')
const Button = require('../elements/button.js')
const html = require('choo/html')
module.exports = function CodeEditorPanel (state, emit) {
  let color = 'purple'
  let children = []
  let {selectedGlitchId} = state
  if (state.selectedGlitchApplicationId) {
    const glitchApplication = state.glitchApplications.find(
      g => g.id === state.selectedGlitchApplicationId
    )
    selectedGlitchId = glitchApplication.glitchId
  }
  if (selectedGlitchId) {
    const glitch = state.availableGlitches.find(
      g => g.id === selectedGlitchId
    );
    ({color} = glitch)
  }
  if (state.glitchEditorContentLoaded) {
    const closeEditor = Button({
      text: 'close editor',
      noIconBackground: true,
      onMouseDown: () => emit('close-glitch-editor')
    })
    const saveAndApply = Button({
      text: 'save changes',
      noIconBackground: true,
      onMouseDown: () => {
        emit('save-and-apply-changes')
        emit('close-glitch-editor')
      }
    })
    let remixGlitch = ''
    if (state.selectedGlitchApplicationId || state.selectedGlitchId) {
      remixGlitch = Button({
        text: 'new remix',
        noIconBackground: true,
        onMouseDown: () => emit('remix-glitch')
      })
    }
    const exportGlitch = Button({
      text: 'export',
      noIconBackground: true,
      onMouseDown: () => emit('glitch-editor-export')
    })

    children = html`
  <div class="glitch-drop-areas-wrapper">
    ${closeEditor}
    ${saveAndApply}
    ${remixGlitch}
    ${exportGlitch}
  </div>`
  }
  return PanelElement({
    color,
    size: 'small',
    noPadding: false,
    children
  })
}
