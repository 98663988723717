module.exports = 
{
  "id": "dungeon rescue",
  "title": "dungeon rescue",
  "thumbnail": "/provision/projects/dungeon rescue/dungeon rescue.png",
  "category": "games",
  "toolbar": {
    "glitches": [],
    "parts": []
  },
  "project_file": "dungeon rescue.gbp"
};