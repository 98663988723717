module.exports = {
  "id": "rotation style",
  "color": "pink",
  "title": "rotation style",
  "icon": "rotation_style",
  "category": "movement",
  "info": [
    {
      "text": "Set a sprite's rotation style",
      "image": "provision/glitches/rotation_style/rotation_style.gif"
    }
  ],
  "exposedVariables": [
    {
      "spriteName": "$glitch_target",
      "variableName": "rotation style",
      "input": {
        "type": "option",
        "options": [
          {
            "value": "all around",
            "label": "all around"
          },
          {
            "value": "left-right",
            "label": "left-right"
          },
          {
            "value": "don't rotate",
            "label": "don't rotate"
          }
        ],
        "min": -999,
        "max": 999
      },
      "value": "all around"
    }
  ],
  "codeImage": "provision/glitches/rotation_style/rotation_style.png",
  "code": null,
  "zip": null,
  "sb3": null
};