module.exports = {
  "id": "fish chomp",
  "title": "fish chomp",
  "thumbnail": "/provision/projects/fish chomp/fish chomp.png",
  "category": "games",
  "project_file": "fish chomp.gbp",
  "exposedVariables": [
    {
      "spriteName": "chompy",
      "variableName": "left key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "chompy",
      "variableName": "right key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "chompy",
      "variableName": "up key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    },
    {
      "spriteName": "chompy",
      "variableName": "down key",
      "showOnStage": true,
      "input": {
        "type": "key"
      }
    }
  ],
  "persistentVariables": []
};