const {log} = console

function parseSb3 (file, vm) {
  log('parseSb3', file)
  return new Promise((resolve, reject) => {
    vm.parser(file, false, (error, data) => {
      if (error) {
        reject(error, ' on file ', file)
      } else {
        resolve({
          code: data[0],
          zip: data[1],
          sb3: file
        })
      }
    })
  })
}

function fetchArrayBuffer (path) {
  return fetch(path)
    .then(r => {
      if (r.ok) {
        return r.arrayBuffer()
      }
      throw new Error('Error fetching file', path)
    })
}

function fetchJson (path) {
  return fetch(path)
    .then(r => {
      if (r.ok) {
        return r.text().then(text => {
          try {
            return JSON.parse(text)
          } catch (err) {
            log(`${path}, is not JSON. instead received: ${text}`)
            return null
          }
        })
      }
      throw new Error('Error fetching file')
    })
}

function projectPath (projectId) {
  return `/provision/projects/${projectId}`
}

module.exports = {
  fetchArrayBuffer,
  fetchJson,
  projectPath,
  parseSb3
}
