// button.js
const Component = require('choo/component')
const html = require('choo/html')
class GlitchEditor extends Component {
  constructor (id, state) {
    super(id)
    this.local = state.components[id]
  }

  createElement (color) {
    this.color = color
    return html`
      <iframe 
        class="glitch-editor-iframe"
        sandbox="allow-scripts allow-same-origin"
        style="position:absolute; 
               width:100%; 
               height:100%; 
               top: -1px;
               visibility:hidden;" 
        scrollling="no" 
        src="/scratch/glitch-editor.html">
      </iframe>
    `
  }

  // Implement conditional rendering
  update () {
    return false
  }
}

module.exports = GlitchEditor
